import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { apiError, addBuilder } from "../../../store/actions"
import { classnames } from "../../../helpers/helper"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import {
  Alert,
  Col,
  Container,
  Row,
  CardBody,
  Card,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
class BuilderCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: '',
      fileName: '',
      fname: '',
      address: '',
      builderName:null,
      errorMessage: '',
      latitude: null,
      longitude: null,
      isGeocoding: false,

    }
    this.onFileChange = this.onFileChange.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }
  handleChange = address => {
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    });
  };

  handleSelect = selected => {
    this.setState({ isGeocoding: true, address: selected });
      geocodeByAddress(selected)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        this.setState({
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        });
      })
      .catch(error => {
        this.setState({ isGeocoding: false });
        console.log('error', error); // eslint-disable-line no-console
      });
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
      latitude: null,
      longitude: null,
    });
  };

  handleError = (status, clearSuggestions) => {
    //console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };
  onFileChange(e) {
    //console.log(e);
    
    let files = e.target.files;
    let fileNames = files[0];
    let fname = fileNames['name'];
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
    this.setState({selectedImage: event.target.result}),
    this.setState({fname: fname})
    //this.setState({fname: projectGalleryTitle})
  }
   
  }
  handleValidSubmit(value,history) {

    this.props.addBuilder(value, this.props.history)
    //console.log(value);

  }

  componentDidMount() {
    this.props.apiError("")
  }

  render() {
    const {
      address,
      errorMessage,
      latitude,
      longitude,
      isGeocoding,
      builderName
    } = this.state;

    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Add Builder | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={this.props.t("Builder")}
              breadcrumbItem={this.props.t("Add Builder")}
            />
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    builderName:(this.state && this.state.builderName) || "",
                    agentId: parseInt(localStorage.getItem('userId')),
                    builderLogoImageContent: this.state.selectedImage,
                    builderLogoImage: this.state.fname,
                    builderLogo:this.state.fname,
                    address:this.state.address,
                    longitude:this.state.longitude,
                    latitude:this.state.latitude,



                  }}
                  validationSchema={Yup.object().shape({
                    builderName: Yup.string().required("This is Required")
                  })}

                  onSubmit={this.handleValidSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                      <Col md="3">
                      <FormGroup className="mb-3">
                            <Label htmlFor="builderName">
                              {this.props.t("Builder Name")}
                            </Label>
                      <Field
                              name="builderName"
                              onChange={handleChange}                            
                              type="text"
                              value={values.builderName}
                              className={
                                "form-control" +
                                (errors.builderName && touched.builderName
                                  ? " is-invalid"
                                  : "")
                              }
                              id="builderName"
                              
                            /> 
                             <ErrorMessage
                              name="builderName"
                              component="div"
                              className="invalid-feedback"
                            />
                        </FormGroup>
                        </Col>
                       



                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="builderLogo">
                              {this.props.t("Upload Logo")}
                            </Label>
                            <input id="builderLogo" name="builderLogo" type="file" 
                            onChange={ this.onFileChange } className="form-control"
                           
                            />   
                           


                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup className="mb-3">
                            <Label htmlFor="addressLine1">
                              {this.props.t("Location")}
                            </Label>
                            <div>
                      <PlacesAutocomplete
                        onChange={this.handleChange}
                        value={address}
                        onSelect={this.handleSelect}
                        onError={this.handleError}
                        shouldFetchSuggestions={address.length > 2}
                      >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="Demo__search-bar-container">
                <div className="Demo__search-input-container">
                  <input
                    {...getInputProps({
                      placeholder: '',
                      className: 'form-control',
                    })}
                  />
                  {this.state.address.length > 0 && (
                    <button
                      className="Demo__clear-button"
                      onClick={this.handleCloseClick}
                    >
                      x
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className="Demo__autocomplete-container">
                    {suggestions.map(suggestion => {
                      const className = classnames('Demo__suggestion-item', {
                        'Demo__suggestion-item--active': suggestion.active,
                      });

                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                    
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
        {errorMessage.length > 0 && (
          <div className="Demo__error-message">{this.state.errorMessage}</div>
        )}
      </div>
                          </FormGroup>
                        </Col>
                       

                      </Row>

                      <Row>
                       
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md float-end mt20" name="addAddress" id="addAddress"> {this.props.t("Save")}</button></div>
                      </Row>
                   
                    </Form>
                    )}
                      </Formik>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }


}
BuilderCreate.propTypes = {
  t: PropTypes.any,
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  addBuilder: PropTypes.func,
}

const mapStateToProps = state => {
  const { error } = ''
  return { error }
}



export default connect(mapStateToProps, { addBuilder, apiError })(withRouter(withTranslation()(BuilderCreate)))