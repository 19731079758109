import { ADD_LEAD_NOTE_FAIL, ADD_LEAD_NOTE_SUCCESS, CREATE_LEAD, CREATE_LEAD_FAIL, CREATE_LEAD_SUCCESS, GET_LEADS_ERROR, GET_LEADS_SUCCESS, GET_LEAD_DETAIL_FAIL, GET_LEAD_DETAIL_SUCCESS, GET_LEAD_FOLLOW_FAIL, GET_LEAD_FOLLOW_SUCCESS, GET_LEAD_NOTE_FAIL, GET_LEAD_NOTE_SUCCESS, POST_LEAD_FOLLOW_FAIL, POST_LEAD_FOLLOW_SUCCESS, POST_LEAD_TRANSFER_FAIL, POST_LEAD_TRANSFER_SUCCESS } from "./actionTypes"

const initialState = {

  error: "",
  loading: false,
  leads: [],
  leadData: {},
  leadNotes: [],
  leadFollows: []
}

const leads = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_LEAD:
      state = {
        ...state,
        loading: true,
      }
      break
    case CREATE_LEAD_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case CREATE_LEAD_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_LEADS_SUCCESS:
      return {
        ...state,
        leads: action.payload,
      }
    case GET_LEADS_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_LEAD_DETAIL_SUCCESS:
      return {
        ...state,
        leadData: action.payload,
      }

    case GET_LEAD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_LEAD_NOTE_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_LEAD_NOTE_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_LEAD_NOTE_SUCCESS:
      return {
        ...state,
        leadNotes: action.payload,
      }

    case GET_LEAD_NOTE_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case POST_LEAD_FOLLOW_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case POST_LEAD_FOLLOW_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_LEAD_FOLLOW_SUCCESS:
      return {
        ...state,
        leadFollows: action.payload,
      }

    case GET_LEAD_FOLLOW_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case POST_LEAD_TRANSFER_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case POST_LEAD_TRANSFER_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default leads
