import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import BarChart from "./barchart"
import { isEmpty, map } from "lodash";
import { getBrokerAgentDetail  } from "../../store/agent/actions"
//Lightbox

import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import classnames from "classnames"
import { AvField, AvForm } from "availity-reactstrap-validation"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import InputMask from "react-input-mask"
class ViewAgent extends Component {
  constructor(props) {
    super(props)
    this.state = {
        col9: false,
        col15: false,
        col10: false,
        col11: false,
        col12: true,
        selectedGroup: null,
        activeTab: "1",
        verticalActiveTab: "1",
        volume: 0,

        reports: [
            { title: "Lead To Conversion", iconClass: "bx-copy-alt", description: "40%" },
            { title: "Total Signed Clients", iconClass: "bx-copy-alt", description: "76" },
            { title: "Current Leads ", iconClass: "bx-copy-alt", description: "55" },
            
          ]
      
    }

    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col12 = this.t_col12.bind(this)
    this.t_col15 = this.t_col15.bind(this)
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
  
  }
  handleOnChange = (value) => {
    this.setState({
      volume: value
    })
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }
  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false
    })
  }
  t_col12() {
    this.setState({
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false
    })
  }
  t_col15() {
    this.setState({
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false
    })
  }
  componentDidMount() {
    const { match: { params }, onGetBrokerAgentDetail} = this.props;
    
    if (params && params.agentId) {
      onGetBrokerAgentDetail(params.agentId);
      
    }
  }
  render() {
    const { agentData} = this.props;
      return (
      <React.Fragment>
        <div className="page-content">

          <MetaTags>
            <title>View Agent | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Agent")} breadcrumbItem={this.props.t("Agent Detail")} />

            <Row>
                <Col sm="4">
                    <Card>
                        <CardBody>
                            <h5 className="card-title">Agent Detail</h5>
                            {!isEmpty(agentData) && (
                            <div className="table-responsive">
  <table className="table mb-0 table">
    <tbody>
    <tr>
        <th scope="row">Login ID</th>
        <td> {agentData.primaryEmail} </td>
      </tr>
      <tr>
        <th scope="row">Agent Code</th>
        <td> {agentData.agentCode} </td>
      </tr>
      <tr>
        <th scope="row">First Name</th>
        <td> {agentData.firstName} </td>
      </tr>
      <tr>
        <th scope="row">Last Name</th>
        <td>  {agentData.lastName} </td>
      </tr>
      
      <tr>
        <th scope="row"> Phone</th>
        <td> {agentData.brokerAgentContact.cContact.phone} </td>
      </tr>
      <tr>
        <th scope="row"> Mobile</th>
        <td> {agentData.brokerAgentContact.cContact.mobile} </td>
      </tr>
      <tr>
        <th scope="row"> Email</th>
        <td> {agentData.brokerAgentContact.cContact.email} </td>
      </tr>
      <tr>
        <th scope="row"> Address</th>
        <td> {agentData.brokerAgentAddress.fullAddress} </td>
      </tr>
     
    </tbody>
  </table>
                          </div>
                            )}
                        </CardBody>
                    </Card>

                </Col>

                <Col sm="8">
                <Card>
                  <CardBody>
                  <Row>
            {this.state.reports.map((report, key) => (
            <Col md="4" key={"_col_" + key}>
               <Card className="mini-stats-wid agentbx">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                              
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                />
                              </span>
                            </div>
                            
                          </div>
                          
                        </CardBody>
                        
                      </Card>
                
              </Col>
            ))}
            </Row>
                  <Nav tabs className="leadTab">
                  <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Referred leads")}</span>
                          <span className="d-block d-sm-none"></span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "3",
                          })}
                          onClick={() => {
                            this.toggle("3")
                          }}
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Project performance history")}</span>
                          <span className="d-block d-sm-none"></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Analysis")}</span>
                          <span className="d-block d-sm-none"></span>
                        </NavLink>
                      </NavItem>

                     

                      
            
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                    
                    <TabPane tabId="1">
                     <div className="mb-5 h4 card-title">Referred leads</div>
                     <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Transfer date</th>
                  <th className="align-middle">Last contact date</th>
                  <th className="align-middle">Status</th>
                  

                 
                  
                </tr>
              </thead>
              <tbody>
               <tr>
                   <td>Test Lead</td>
                   <td>Jan 10, 2022</td>
                   <td>Jan 10, 2022</td>
                   <td></td>

               </tr>
              </tbody>
            </table>
          </div>
                        
                     </TabPane>
                     <TabPane tabId="3">
                     <div className="mb-5 h4 card-title">Project performance history</div>
                     <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                
                  <th className="align-middle">Project Name</th>
                  <th className="align-middle"># Client</th>
                  <th className="align-middle"># SRR</th>
                  
                  

                 
                  
                </tr>
              </thead>
              <tbody>
               <tr>
                   <td>Liberty Market Tower</td>
                   <td>5</td>
                   <td>3</td>
                   

               </tr>
              </tbody>
            </table>
          </div>
                     </TabPane>

                     <TabPane tabId="4">
                     <div className="mb-5 h4 card-title">Month by Month Comparison for Leads and Sales</div>
                     <BarChart />
                     </TabPane>

                     
                    </TabContent>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
                                  
                      
                      
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ViewAgent.propTypes = {
  t:PropTypes.any,
  match: PropTypes.object,
  onGetBrokerAgentDetail: PropTypes.object,
  agentData: PropTypes.object,
}
const mapStateToProps = ({ Agent }) => (
  {
    agentData: Agent.agentData
  })
const mapDispatchToProps = dispatch => ({
  onGetBrokerAgentDetail: agentId => dispatch(getBrokerAgentDetail(agentId)),

});




export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ViewAgent)))