import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
import axios from "axios"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createLeadDataFail, createLeadData } from "../../store/actions"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Lightbox

import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"


class CreateLead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sources: [],
      leadStatus: [],
      leadTypes: [],
      groupDrops: [],
      maritalStat: [],
      prefMediums: [],
      likelihoods: [],
    }

    this.handleSubmitLead = this.handleSubmitLead.bind(this);

  }
  handleSubmitLead(value) {
    //console.log(value);
    this.props.createLeadData(value, this.props.history)
  }

  componentDidMount() {
    console.log(localStorage.getItem("authUser"));
    const obj = JSON.parse(localStorage.getItem("authUser"));
    //console.log(obj.resultData.bearerToken);
    const token = obj.resultData.bearerToken;

    //console.log(token);
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net/api/LeadSource/all', { headers: { Authorization: str } })
      .then(res => {
        const sources = res.data;
        this.setState({ sources });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/LeadStatus/all', { headers: { Authorization: str } })
        .then(res => {

          const leadStatus = res.data;
          this.setState({ leadStatus });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/LeadType/all', { headers: { Authorization: str } })
        .then(res => {
          const leadTypes = res.data;
          this.setState({ leadTypes });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Lead:Group',

        }, headers: { Authorization: str }
      })
        .then(res => {
          const groupDrops = res.data;
          this.setState({ groupDrops });

        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Lead:Marital Status',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const maritalStat = res.data;
          this.setState({ maritalStat });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Lead:Win Liklyhood',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const likelihoods = res.data;
          this.setState({ likelihoods });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Lead:Preferred Medium',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const prefMediums = res.data;
          this.setState({ prefMediums });
        })
  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">

          <MetaTags>
            <title>Create Lead | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Lead")} breadcrumbItem={this.props.t("Add New Lead")} />
            <ToastContainer autoClose={2000} />
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <CardText className="mb-0">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          firstName: (this.state && this.state.firstName) || "",
                          lastName: (this.state && this.state.lastName) || "",
                          email: (this.state && this.state.email) || "",
                          phone: "",
                          mobile: "",

                          dateSubmited: new Date(),
                          //lastContact: new Date()


                        }}
                        validationSchema={Yup.object().shape({
                          firstName: Yup.string().required(
                            "This Field in required"
                          ),
                          lastName: Yup.string().required(
                            "This Field in required"
                          ),
                          email: Yup.string().email('Invalid email').required('This Field in required'),
                          mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').required('This is required'),
                          phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid'),
                
                          leadSourceId: Yup.string().required('This field is required')
                        })}

                        onSubmit={this.handleSubmitLead}
                      >
                        {({ errors, touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                            

                            <Row>
                            <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="maritalStatus">
                                    {this.props.t("Marital status")}
                                  </Label>
                                  <Field as="select" name="maritalStatus" className="form-control">
                                    <option value="">Select</option>
                                    {
                                      this.state.maritalStat
                                        .map(marital =>
                                          <option key={marital.id} value={marital.id}>{marital.fieldValueName}</option>
                                        )
                                    }
                                  </Field>
                                </FormGroup>
                              </Col>

                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="firstName">
                                    {this.props.t("First Name")}
                                  </Label>
                                  <input
                                    name="firstName"
                                    type="text"
                                    //alue={values.firstName}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.firstName && touched.firstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="firstName"
                                  />
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastName">
                                    {this.props.t("Last Name")}
                                  </Label>
                                  <input
                                    name="lastName"
                                    type="text"
                                    //value={values.lastName}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.lastName && touched.lastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="lastName"
                                  />
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="email">
                                    {this.props.t("Email")}
                                  </Label>
                                  <input
                                    name="email"
                                    type="text"
                                    //value={values.email}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="mobile">
                                    {this.props.t("Mobile")}
                                  </Label>
                                  <input
                                    name="mobile"
                                    type="text"
                                    //value={values.mobile}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.mobile && touched.mobile
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="mobile"
                                  />
                                  <ErrorMessage
                                    name="mobile"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phone">
                                    {this.props.t("Phone")}
                                  </Label>
                                  <input
                                    name="phone"
                                    type="text"
                                    //value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phone && touched.phone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phone"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              
                             

                            

                            </Row>
                            <Row>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="dateSubmited">
                                    {this.props.t("Date submitted")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.dateSubmited && touched.dateSubmited
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.dateSubmited}
                                    onChange={(value) => setFieldValue('dateSubmited', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="leadSourceId">
                                    {this.props.t("Source of lead")}
                                  </Label>
                                  <Field as="select" name="leadSourceId" className={
                                    "form-control" +
                                    (errors.leadSourceId && touched.leadSourceId
                                      ? " is-invalid"
                                      : "")
                                  }>
                                    <option value="">Select</option>
                                    {
                                      this.state.sources
                                        .map(source =>
                                          <option key={source.id} value={source.id}>{source.leadSourceTitle}</option>
                                        )
                                    }
                                  </Field>
                                  <ErrorMessage
                                    name="leadSourceId"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="preferedMedium">
                                    {this.props.t("Preferred medium")}
                                  </Label>
                                  <Field as="select" name="preferedMedium" className="form-control">
                                    <option value="">Select</option>
                                    {
                                      this.state.prefMediums
                                        .map(prefMedium =>
                                          <option key={prefMedium.id} value={prefMedium.id}>{prefMedium.fieldValueName}</option>
                                        )
                                    }
                                  </Field>
                                </FormGroup>
                              </Col>     
                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="leadType">
                                    {this.props.t("Type")}
                                  </Label>
                                  <Field as="select" name="leadType" className="form-control">
                                    <option value="">Select</option>
                                    {
                                      this.state.leadTypes
                                        .map(leadType =>
                                          <option key={leadType.id} value={leadType.id}>{leadType.leadTypeTitle}</option>
                                        )
                                    }
                                  </Field>

                                </FormGroup>
                              </Col>

                              <Col md="2">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="leadStatus">
                                    {this.props.t("Status")}
                                  </Label>
                                  <Field as="select" name="leadStatus" className="form-control">
                                    <option value="">Select</option>
                                    {
                                      this.state.leadStatus
                                        .map(leadStat =>
                                          <option key={leadStat.id} value={leadStat.id}>{leadStat.leadStatusTitle}</option>
                                        )
                                    }
                                  </Field>
                                </FormGroup>
                              </Col>
                            </Row>      
                            <div><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button> <Link to="/lead/dashboard"><button type="button" className="btn btn-danger w-md">{this.props.t("Close")}</button></Link></div>

                          </Form>
                        )}
                      </Formik>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CreateLead.propTypes = {

  t: PropTypes.any,
  createLeadDataFail: PropTypes.any,
  history: PropTypes.object,
  error: PropTypes.any,
  createLeadData: PropTypes.func,
}





export default connect(null, { createLeadData, createLeadDataFail })(withRouter(withTranslation()(CreateLead)))