import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../../components/Common/DeleteModal";
import { addProjectLink, getProjectLink, deleteProjectLink } from "../../../store/projects/actions"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


class ProjectLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      
    }

    this.onClickDelete = this.onClickDelete.bind(this);
    this.handleProjectLinkSubmit = this.handleProjectLinkSubmit.bind(this)
  }
  
  handleProjectLinkSubmit(value){

    this.props.addProjectLink(value, this.props.history);

  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (link) => {
    this.setState({ link: link });
    this.setState({ deleteModal: true });
  };

  handleDeleteLink = () => {
    const { onDeleteLink } = this.props;
    const { link } = this.state;
    //console.log(document);
    if (link.id !== undefined) {
      onDeleteLink(link);
      this.setState({ deleteModal: false });
    }
  };
  componentDidMount() {
    const { match: { params }, onGetProjectLink }= this.props;
    if (params && params.id &&  params.projectId) {
        onGetProjectLink(params.id)
    }else{
      this.props.history.push('/my-project')
    }
  }
  render() {
    const { links } = this.props;
    const { deleteModal } = this.state;
      return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteLink}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Links")} />
            <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link" to={"/pre-construction/create-project/feature/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                    <Link className="nav-link" to={"/pre-construction/create-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                       <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/create-project/link/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Links")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> 
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                     
                      <NavItem>
                      <Link  className="nav-link" to={"/pre-construction/create-project/marketing/"+ this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
            <Card>
                  <CardBody>
                  

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                    <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectId: parseInt(this.props.match.params.id),
                    projectLinkTitle: (this.state && this.state.projectLinkTitle) || "",
                    projectLinkUrl: (this.state && this.state.projectLinkTitle) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    projectLinkTitle: Yup.string().required("This is Required"),
                    projectLinkUrl: Yup.string().required("This is Required")
                  })}

                  onSubmit={this.handleProjectLinkSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectLinkTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field
                              name="projectLinkTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.projectLinkTitle && touched.projectLinkTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectLinkTitle"
                            />
                             <ErrorMessage
                              name="projectLinkTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                       
                        

                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectLinkUrl">
                              {this.props.t("Link")}
                            </Label>
                            <Field
                              name="projectLinkUrl"
                              onChange={handleChange}                             
                              type="text"
                              className={
                                "form-control" +
                                (errors.projectLinkUrl && touched.projectLinkUrl
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectLinkUrl"
                            />
                             <ErrorMessage
                              name="projectLinkUrl"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end mt-27">Add Link</button></div>
                        </Col>
                    </Row>

                
             
             </CardBody>
                </Card>
                
            </Form>
                  )}
			              </Formik>

     
                      </TabContent>
                      </CardBody>
                      </Card>
                      <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Link History</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								
								
								<th>Title</th>
                
                <th></th> 
							  </tr>
							</thead>
							<tbody>
              {map(links, (link, linkkey) => (
               <tr key={"_link_" + linkkey}>
                
                <td><a href={link.projectLinkUrl}>{link.projectLinkTitle}</a></td>
                
                <td><Link to="#" onClick={() => this.onClickDelete(link)} className="btn-delete waves-effect waves-light btn-sm">Delete</Link></td>
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>         
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectLink.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  addProjectLink:PropTypes.func,
  links:PropTypes.array,
  onGetProjectLink:PropTypes.func,
  match:PropTypes.object,
  onDeleteLink:PropTypes.func

 
}

const mapStateToProps = ({ Project }) => (
{
  links: Project.links
})

const mapDispatchToProps = dispatch => ({
    addProjectLink: (data) => dispatch(addProjectLink(data)),
    onGetProjectLink:(projectId) => dispatch(getProjectLink(projectId)),
    onDeleteLink: (id) => dispatch(deleteProjectLink(id))

})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectLink)))