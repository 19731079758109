import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { getBuilders } from "../../../store/builder/actions";
import {
    Col,
    Container,
    Row,
    CardBody,
    Card
  } from "reactstrap"

  class Builder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }
    componentDidMount() {
      const { onGetBuilders } = this.props;
      onGetBuilders(localStorage.getItem('userId'));
    }
    render() {
      const { builders } = this.props;
      //console.log(builders);
        return (
          <React.Fragment>
            <div className="page-content">
          <MetaTags>
            <title>Builder | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={this.props.t("Builder")}
              breadcrumbItem={this.props.t("Builder")}
            />
            <Row>
                <Col sm="12"><Link  to="/pre-Construction/builder/create" className="btn btn-primary waves-effect waves-light btn-sm float-end"><i className="mdi mdi-plus ml-1"></i> Add New</Link></Col>
            </Row>
            <Row>
                <Col md="12">
                
            <Card>

                        <CardBody>
                        
            <div className="table-rep-plugin">
                                                <div
                                                    className="table-responsive mb-0"
                                                    data-pattern="priority-columns"
                                                >
                                                    <Table
                                                    id="tech-companies-1"
                                                    className="table table-striped"
                                                    >
                                                    <Thead>
                                                        <Tr>
                                                        <Th>{this.props.t("Logo")}</Th>
                                                        <Th>{this.props.t("Builder Name")}</Th>
                                                       
                                                        <Th>{this.props.t("Address")}</Th>
                                                        <Th>{this.props.t("Detail")}</Th>
                                                        <Th>{this.props.t("Project")}</Th>
                                                    
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                    {map(builders, (builder, builderkey) => (
                                                        <Tr key={"_tr_" + builderkey}>
                                                        <Th>
                                                            
                                                        <img
                                                            src={"http://realtyspace-001-site2.itempurl.com/"+ builder.builderLogoUrl}
                                                            width="75"
                                                            alt=""
                                                            
                                                            />
                                                        </Th>
                                                        <Td>{builder.builderName} </Td>
                                                        <Td> {builder.bAddress.fullAddress} </Td>
                                                       
                                                        <Td>   
                                                            <Link to="/pre-Construction/builder/builder-detail" className="btn-primary waves-effect waves-light btn-sm"> <i className="mdi mdi-magnify ml-1"></i>  Builder Details</Link>
                                                            
                                                            </Td>

                                                            <Td>  
                                                            <Link to="/pre-construction/project" className="btn-primary waves-effect waves-light btn-sm"><i className="mdi mdi-office-building ml-1"></i> Builder Projects</Link>
                                                              
                                                          
                                                            
                                                            
                                                            </Td>

                                                        </Tr>
                                                    ))} 
                                                        
                                                    </Tbody>
                                                    </Table>
                                                </div>
                                                </div>
                                                </CardBody>
                                                </Card>
                                        </Col>
                                        </Row>
            </Container>
        </div>
          </React.Fragment>
        )
    }

    
  }
  Builder.propTypes = {
    t: PropTypes.any,
    builders:PropTypes.array,
    onGetBuilders:PropTypes.func
  }
  const mapStateToProps = ({ Builder }) => (
    console.log(Builder),
    {
      builders: Builder.builders,

      
    })
  
  const mapDispatchToProps = dispatch => ({
    onGetBuilders: agentId => dispatch(getBuilders(agentId)),
   
  });
  export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(Builder)))