import {
    ADD_BUILDER,
    ADD_BUILDER_SUCCESS,
    ADD_BUILDER_ERROR,
    GET_BUILDER,
    GET_BUILDER_DETAIL_SUCCESS,
    GET_BUILDER_DETAIL_FAIL,
    GET_BUILDER_DETAIL,
    GET_BUILDER_FAIL,
    ADD_BUILDER_DEPART,
    ADD_BUILDER_DEPART_SUCCESS,
    ADD_BUILDER_DEPART_ERROR,
    GET_BUILDER_SUCCESS
  } from "./actionTypes"
  
  export const addBuilder = (builder, history) => {
    return {
      type: ADD_BUILDER,
      payload: { builder, history },
    }
  }
  
  export const addSuccess = builder => {
    return {
      type: ADD_BUILDER_SUCCESS,
      payload: builder,
    }
  }

  
  export const builderapiError = error => {
    return {
      type: ADD_BUILDER_ERROR,
      payload: error,
    }
  }


  export const getBuilders = (agentId) => ({
    type: GET_BUILDER,
    agentId
  })
  
  export const getBuildersSuccess = builders => ({
    type: GET_BUILDER_SUCCESS,
    payload: builders,
  })
  
  export const getBuildersFail = error => ({
    type: GET_BUILDER_FAIL,
    payload: error,
  })
  
  export const getBuilderDetail = builderId => ({
    type: GET_BUILDER_DETAIL,
    builderId,
  })
  
  export const getBuilderDetailSuccess = builderData => ({
    type: GET_BUILDER_DETAIL_SUCCESS,
    payload: builderData,
  })
  
  export const getBuilderDetailFail = error => ({
    type: GET_BUILDER_DETAIL_FAIL,
    payload: error,
  })


  export const addBuilderDepart = (depart, history) => {
    return {
      type: ADD_BUILDER_DEPART,
      payload: { depart, history },
    }
  }
  
  export const addDepartSuccess = depart => {
    return {
      type: ADD_BUILDER_DEPART_SUCCESS,
      payload: depart,
    }
  }

  
  export const apiDepartError = error => {
    return {
      type: ADD_BUILDER_DEPART_ERROR,
      payload: error,
    }
  }
