import React, { Component } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"


import profileImg from "../../assets/images/profile-img.png"

class WelcomeComp extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
       
          
          
            <Row className="mb20">
              
              <Col sm="12">
                <div className="pt-2">
                  
                  <div className="">
                    
                    <Link
                      to=""
                      className="btn btn-primary btn-md mr-2"
                    >
                      Import Leads  {" "}<i className="mdi mdi-file-upload ms-1"/>
                    </Link>
                    <Link
                      to="/lead/lead-create"
                      className="btn btn-add btn-md"
                    >
                      Add Lead    {" "}<i className="mdi mdi-plus-box-outline ms-1"/>
                    </Link>

                    
                  </div>
                 
                </div>
              </Col>
            </Row>
         
      </React.Fragment>
    )
  }
}

export default WelcomeComp
