/* Leads */
export const GET_LEADS = "GET_LEADS"
export const GET_LEADS_SUCCESS = "GET_LEADS_SUCCESS"
export const GET_LEADS_ERROR = "GET_LEADS_ERROR"

export const CREATE_LEAD = "CREATE_LEAD"
export const CREATE_LEAD_SUCCESS = "CREATE_LEAD_SUCCESS"
export const CREATE_LEAD_FAIL = "CREATE_LEAD_FAIL"


export const GET_LEAD_DETAIL = "GET_LEAD_DETAIL"
export const GET_LEAD_DETAIL_SUCCESS = "GET_LEAD_DETAIL_SUCCESS"
export const GET_LEAD_DETAIL_FAIL = "GET_LEAD_DETAIL_FAIL"

export const ADD_LEAD_NOTE = "ADD_LEAD_NOTE"
export const ADD_LEAD_NOTE_SUCCESS = "ADD_LEAD_NOTE_SUCCESS"
export const ADD_LEAD_NOTE_FAIL = "ADD_LEAD_NOTE_FAIL"


export const GET_LEAD_NOTE = "GET_LEAD_NOTE"
export const GET_LEAD_NOTE_SUCCESS = "GET_LEAD_NOTE_SUCCESS"
export const GET_LEAD_NOTE_FAIL = "GET_LEAD_NOTE_FAIL"


export const POST_LEAD_FOLLOW = "POST_LEAD_FOLLOW"
export const POST_LEAD_FOLLOW_SUCCESS = "POST_LEAD_FOLLOW_SUCCESS"
export const POST_LEAD_FOLLOW_FAIL = "POST_LEAD_FOLLOW_FAIL"

export const GET_LEAD_FOLLOW = "GET_LEAD_FOLLOW"
export const GET_LEAD_FOLLOW_SUCCESS = "GET_LEAD_FOLLOW_SUCCESS"
export const GET_LEAD_FOLLOW_FAIL = "GET_LEAD_FOLLOW_FAIL"


export const POST_LEAD_TRANSFER = "POST_LEAD_TRANSFER"
export const POST_LEAD_TRANSFER_SUCCESS = "POST_LEAD_TRANSFER_SUCCESS"
export const POST_LEAD_TRANSFER_FAIL = "POST_LEAD_TRANSFER_FAIL"

