import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { isEmpty, map } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addBrokerResource, getBrokerResource } from "../../store/resource/actions"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
    Row,
    Col,
    CardBody,
    Card,
    CardText,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Input,
    Button,
    Select
} from "reactstrap"

class OurResource extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: '',
      selectedImage: '',
      fname: ''
    }
    this.onFileChange = this.onFileChange.bind(this);
    this.handleResourceSubmit = this.handleResourceSubmit.bind(this)
  }
  onFileChange(e) {
    let files = e.target.files;
    let fileNames = files[0];
    let fname = fileNames['name'];
    
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    
    fileReader.onload = (event) => {
      
      this.setState({
        selectedImage: event.target.result,
       }),
       this.setState({
        fname: fname,
       })
       

    }
  }
  handleResourceSubmit(value){
    this.props.addBrokerResource(value, this.props.history);
  }
  componentDidMount() {
    const { onGetBrokerResource } = this.props;
    onGetBrokerResource(localStorage.getItem('userId'));
}
  render() {
    const { resources } = this.props;
    return (
      <React.Fragment>
          <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Resource | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Resource")}
              breadcrumbItem={this.props.t("Resource")}
            />
           
           <Row>
                          <Col sm="12">
                          <Formik
                  enableReinitialize={true}
                  initialValues={{
                    resourceTitle: (this.state && this.state.resourceTitle) || "",
                    resourceType: '',
                    agentId: localStorage.getItem('userId'),
                    resourceContent: this.state.selectedImage,
                    resourceLink: (this.state && this.state.fname) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    resourceTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit={this.handleResourceSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="resourceTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field
                              name="resourceTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.resourceTitle && touched.resourceTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="resourceTitle"
                            />
                             <ErrorMessage
                              name="resourceTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="resourceType">
                              {this.props.t("Type")}
                            </Label>
                            <Field as="select" name="resourceType" className="form-control" onChange={handleChange}>
                                <option value="0">Select</option>
                                <option value="1">File</option>
                                <option value="2">Video</option>
                                
                            </Field>
                          </FormGroup>
                        </Col>
                       <Col sm="2">
                       <FormGroup className="mb-3">
                            <Label htmlFor="uploadFile">
                              {this.props.t("Upload File")}
                            </Label>
                           <input type="file" name="file" className="form-control" />
                          </FormGroup>

                       </Col>
                       <Col sm="4">
                       <FormGroup className="mb-3">
                            <Label htmlFor="resourceLink">
                              {this.props.t("Link")}
                            </Label>
                            <Field
                              name="resourceLink"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.resourceLink && touched.resourceLink
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="resourceLink"
                            />
                          </FormGroup>

                       </Col>
                       

                       
                        <Col sm="2">
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end mt-27">ADD</button></div>
                        </Col>
                    </Row>

                
             
             </CardBody>
                </Card>
                
            </Form>
                  )}
			              </Formik>
                            </Col>
                            </Row>
                            <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Resource History</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								
								<th>Type</th>
								<th>Title</th>
                <th>Link</th>
                <th>Created By</th>
                <th>Date Created</th>
                <th></th> 
							  </tr>
							</thead>
							<tbody>
              {map(resources, (resource, reskey) => (
               <tr key={"_res_" + reskey}>
                
                <td>{resource.resourceType}</td>
                <td>{resource.resourceTitle}</td>
                
                <td><a target="_blank" rel="noreferrer" href={"http://realtyspace-001-site2.itempurl.com/" + resource.resourceUrl}>View</a></td>
                <td></td>
                <td></td>
                <td></td>
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row> 
           
           
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
OurResource.propTypes = {
  t: PropTypes.any,
  match:PropTypes.object,
  history: PropTypes.object,
  addBrokerResource: PropTypes.func,
  onGetBrokerResource:PropTypes.func,
  resources:PropTypes.array
}
const mapStateToProps = ({ Resource }) => (
  {
    resources: Resource.resources

  })

  const mapDispatchToProps = dispatch => ({
   
    addBrokerResource: (data) => dispatch(addBrokerResource(data)),
    onGetBrokerResource:(BrokerId) => dispatch(getBrokerResource(BrokerId))
   
  });
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OurResource))
