import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
import { ADD_PROJECT, ADD_PROJECT_CONTACT, ADD_PROJECT_FEATURE, ADD_PROJECT_GALLERY, ADD_PROJECT_LINK, ADD_PROJECT_MARKET, ADD_PROJECT_MEDIA, DELETE_PROJECT, DELETE_PROJECT_CONTACT, DELETE_PROJECT_GALLERY, DELETE_PROJECT_LINK, DELETE_PROJECT_MARKET, DELETE_PROJECT_MEDIA, GET_ALL_PROJECT, GET_ALL_PROJECT_UUID, GET_PROJECT_CONTACT, GET_PROJECT_FEATURE, GET_PROJECT_GALLERY, GET_PROJECT_LINK, GET_PROJECT_MARKET, GET_PROJECT_MEDIA, UPDATE_PROJECT, UPDATE_PROJECT_CONTACT, UPDATE_PROJECT_FEATURE, UPDATE_PROJECT_MARKET } from "./actionTypes"
import { projectapiError, addProjectSuccess, addProjectFeatureSuccess, addProjectFeatureFail, addProjectContactSuccess, addProjectContactFail, getProjectContactSuccess, getProjectContactFail, addProjectMediaSuccess, addProjectMediaFail, getProjectMediaSuccess, getProjectMediaFail, addProjectMarketSuccess, addProjectMarketFail, getProjectMarketSuccess, getProjectMarketFail, getAllProjectAction, getAllProjectActionFail, getAllProjectActionSuccess, getProjectUUIDActionSuccess, getProjectUUIDActionFail, getProjectFeatureActionSuccess, getProjectFeatureActionFail, addProjectError, deleteProjectSuccess, deleteProjectFail, projectUpdateSuccess, projectUpdateError, updateProjectContactSuccess, updateProjectContactFail, updateProjectFeatureSuccess, updateProjectFeatureFail, deleteProjectContact, deleteProjectContactSuccess, deleteProjectContactFail, deleteProjectDocumentSuccess, deleteProjectDocumentFail, updateProjectMarketSuccess, updateProjectMarketFail, deleteProjectMarketSuccess, deleteProjectMarketFail, addProjectLinkSuccess, getProjectLinkSuccess, addProjectLinkFail, getProjectLinkFail, deleteProjectLinkSuccess, deleteProjectLinkFail, addProjectGallerySuccess, getProjectGallarySuccess, addProjectGalleryFail, getProjectGallaryFail, deleteProjectGalleryFail, deleteProjectGallerySuccess } from "./actions"
import { deleteProject, deleteProjectContactAPI, deleteProjectDocumentAPI, deleteProjectGalleryApi, deleteProjectLinkApi, deleteProjectMarketApi, getProject, getProjectByUID, getProjectContactApi, getProjectDocumentApi, getProjectFeatApi, getProjectGalleryApi, getProjectLinkApi, getProjectMarketApi, postProject, postProjectContactApi, postProjectDocumentApi, postProjectFeatApi, postProjectGalleryApi, postProjectLinkApi, postProjectMarketApi, updateProject, updateProjectContactAPI, updateProjectFeatApi, updateProjectMarketApi } from "../../helpers/backend_helper"


function* createProject({ payload: { project, history } }) {

  try {
    toast.loading("Please wait...")
    const response = yield call(postProject, {
      projectName: project.projectName,
      projectBuilder: parseInt(project.projectBuilder),
      projectType: parseInt(project.projectType),
      complitionMonth: project.complitionMonth,
      complitionYear: parseInt(project.complitionYear),
      minPriceRange: parseInt(project.minPriceRange),
      maxPriceRange: parseInt(project.maxPriceRange),
      costPerSqFt: parseInt(project.costPerSqFt),
      noOfUnits: project.noOfUnits,
      noOfStories: project.noOfStories,
      minBed: parseInt(project.minBed),
      maxBed: parseInt(project.maxBed),
      minArea: parseInt(project.minArea),
      maxArea: parseInt(project.maxArea),
      city: '',
      agentWebSite: project.agentWebSite,
      brokerId: parseInt(localStorage.getItem("userId")),
      commision: parseInt(project.commision),
      projectStatus: parseInt(project.projectStatus),
      estimatedOccupancy: project.estimatedOccupancy,
      fullAddress:project.address,
      vipLaunchDate: project.vipLaunchDate,
      publicLaunchDate: project.publicLaunchDate,
      nearestIntersection:project.nearestIntersection
    })

    yield put(addProjectSuccess(response))
    if (response.success == true) {
      const data = JSON.stringify(response.project);
      const pr = JSON.parse(data);
      toast.dismiss();
      toast.success("Project Added");
      history.push("/pre-construction/create-project/feature/" + pr.id + "/" + pr.projectId)
    } else {
      toast.dismiss();
      toast.warn(response.validationErrors[0]);
    }
  } catch (error) {
    yield put(addProjectError(error))
  }
}

function* fetchAllProject({ agentId }) {
  try {
    const response = yield call(getProject, agentId)
    yield put(getAllProjectActionSuccess(response))
  } catch (error) {
    yield put(getAllProjectActionFail(error))
  }
}

function* createProjectFeature({ payload: { feature, history } }) {

  try {
    toast.loading("Please wait...")


    const response = yield call(postProjectFeatApi, {
      projectId: parseInt(feature.projectId),
      projectSummary: feature.projectSummary,
      projectOverview: feature.projectOverview,
      projectHighlights: feature.projectHighlights,
      projectPriceFee: feature.projectPriceFee,
      projectDepositStructure: feature.projectDepositStructure,
      projectCurrentIncentive: feature.projectCurrentIncentive
    })

    yield put(addProjectFeatureSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Project Feature Added");
      history.push("/dashboard");
    } else {
      toast.dismiss();
      toast.warn(response.validationErrors[0]);
    }
  } catch (error) {
    yield put(addProjectFeatureFail(error))
  }
}


function* createProjectContact({ payload: { contact, history } }) {

  try {
    toast.loading("Please wait...")


    const response = yield call(postProjectContactApi, {
      projectId: parseInt(contact.projectId),
      pContactDepartment: contact.pContactDepartment,
      pContactName: contact.pContactName,
      pContactIsActive: true,
      phone: contact.contactPhone,
      email: contact.emailContact,
      mobile: contact.contactMobile,
      fax: contact.contactFax,
    })

    yield put(addProjectContactSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Project Contact Added");
      const response = yield call(getProjectContactApi, parseInt(parseInt(contact.projectId)))
      yield put(getProjectContactSuccess(response))
    } else {
      toast.dismiss();
      toast.warn(response.validationErrors[0]);
    }
  } catch (error) {
    yield put(addProjectContactFail(error))
  }
}

function* fetchProjectContact({ projectId }) {
  try {
    const response = yield call(getProjectContactApi, projectId)
    yield put(getProjectContactSuccess(response))
  } catch (error) {
    yield put(getProjectContactFail(error))
  }
}


function* submitProjectDocument({ payload: { document, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postProjectDocumentApi, {

      projectDocumentTitle: document.projectDocumentTitle,
      projectDocumentLink: document.projectDocumentLink,
      projectDocumentContent: document.projectDocumentContent,
      projectDocumentTypeId: parseInt(document.projectDocumentTypeId),
      agentId: parseInt(document.agentId),
      projectId: parseInt(document.projectId)

    })
    yield put(addProjectMediaSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Document Added");
      const response = yield call(getProjectDocumentApi, parseInt(parseInt(document.projectId)))
      yield put(getProjectMediaSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addProjectMediaFail(error))
  }
}

function* fetchProjectDocument({ projectId }) {
  try {
    const response = yield call(getProjectDocumentApi, projectId)
    yield put(getProjectMediaSuccess(response))
  } catch (error) {
    yield put(getProjectMediaFail(error))
  }
}


function* submitProjectMarket({ payload: { market, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postProjectMarketApi, {

      platform: market.platform,
      allocatedBudget: parseInt(market.allocatedBudget),
      alreadySpent: parseInt(market.alreadySpent),
      projectId: parseInt(market.projectId)

    })
    yield put(addProjectMarketSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Project Marketing Added");
      const response = yield call(getProjectMarketApi, parseInt(parseInt(market.projectId)))
      yield put(getProjectMarketSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addProjectMarketFail(error))
  }
}
function* fetchProjectMarket({ projectId }) {
  try {
    const response = yield call(getProjectMarketApi, projectId)
    yield put(getProjectMarketSuccess(response))
  } catch (error) {
    yield put(getProjectMarketFail(error))
  }
}

function* fetchProjectTab1({ uuid }) {
  try {
    //console.log(uuid);
    const response = yield call(getProjectByUID, uuid)
    yield put(getProjectUUIDActionSuccess(response))
  } catch (error) {
    yield put(getProjectUUIDActionFail(error))
  }
}

function* fetchFeatureTab({ projectId }) {
  try {
    //console.log(uuid);
    const response = yield call(getProjectFeatApi, projectId)
    yield put(getProjectFeatureActionSuccess(response))
  } catch (error) {
    yield put(getProjectFeatureActionFail(error))
  }
}


function* onDeleteProject({ payload: project }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(deleteProject, project)
    yield put(deleteProjectSuccess(response))
    const res = yield call(getProject, localStorage.getItem('userId'))
    yield put(getAllProjectActionSuccess(res))
  } catch (error) {
    yield put(deleteProjectFail(error))
  }
}

function* onUpdateProject({ payload:  project  }) {

  try {
    toast.loading("Please wait...")
    const response = yield call(updateProject, project)
    yield put(projectUpdateSuccess(response))
    if(response.success==true){
    toast.dismiss();
    toast.success("Project Description Updated");
    }else{
      toast.dismiss();
      toast.warning(response.validationErrors[0]);
    }
  } catch (error) {
    yield put(projectUpdateError(error))
  }
}

function* onUpdateProjectFeature({ payload:  projectFeature  }) {
  //console.log(project);
  try {
    toast.loading("Please wait...")
    //console.log(project);
    const response = yield call(updateProjectFeatApi, projectFeature)
    yield put(updateProjectFeatureSuccess(response))
    toast.dismiss();
    toast.success("Project Description Updated");
  } catch (error) {
    yield put(updateProjectFeatureFail(error))
  }
}

function* onProjectContactUpdate({ payload: contact }) {
  //console.log(contact);
  try {
    const response = yield call(updateProjectContactAPI, contact)
    yield put(updateProjectContactSuccess(response))
  } catch (error) {
    yield put(updateProjectContactFail(error))
  }
}

function* onDeleteContact({ payload: contact }) {
  try {
    //console.log(contact)
    toast.loading("Please wait...")
    const response = yield call(deleteProjectContactAPI, contact)
    yield put(deleteProjectContactSuccess(response))
    const res = yield call(getProjectContactApi, paseInt(contact.projectId))
    yield put(getProjectContactSuccess(res))
  } catch (error) {
    yield put(deleteProjectContactFail(error))
  }
}



function* onDeleteDocument({ payload: document }) {
  try {
    //console.log(contact)
    toast.loading("Please wait...")
    const response = yield call(deleteProjectDocumentAPI, document.id)
    yield put(deleteProjectDocumentSuccess(response))
    const res = yield call(getProjectDocumentApi, document.projectId)
    yield put(getProjectMediaSuccess(res))
    toast.dismiss();
  } catch (error) {
    yield put(deleteProjectDocumentFail(error))
  }
}

function* onProjectMarketUpdate({ payload: market }) {
  //console.log(market);
  try {
    //toast.loading("Please wait...")
    const response = yield call(updateProjectMarketApi, market)
    if (response.success == true) {
      console.log(response);
      const res = yield call(getProjectMarketApi, parseInt(parseInt(market.projectId)))
      yield put(getProjectMarketSuccess(res))
    }
    //yield put(updateProjectMarketSuccess(response))
    
  } catch (error) {
    yield put(updateProjectMarketFail(error))
  }
}

function* onDeleteMarket({ payload: market }) {
  try {
    //console.log(contact)
    toast.loading("Please wait...")
    const response = yield call(deleteProjectMarketApi, market.id)
    yield put(deleteProjectMarketSuccess(response))
    const res = yield call(getProjectMarketApi, market.projectId)
    yield put(getProjectMarketSuccess(res))
    toast.dismiss();
  } catch (error) {
    yield put(deleteProjectMarketFail(error))
  }
}

function* submitProjectLink({ payload: { link, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postProjectLinkApi,link)
    yield put(addProjectLinkSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Link Added");
      const response = yield call(getProjectLinkApi, link.projectId)
      yield put(getProjectLinkSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addProjectLinkFail(error))
  }
}

function* fetchProjectLink({ projectId }) {
  try {
    //console.log(uuid);
    const response = yield call(getProjectLinkApi, projectId)
    yield put(getProjectLinkSuccess(response))
  } catch (error) {
    yield put(getProjectLinkFail(error))
  }
}

function* onDeleteLink({ payload: link }) {
  try {
    //console.log(contact)
    toast.loading("Please wait...")
    const response = yield call(deleteProjectLinkApi, link.id)
    yield put(deleteProjectLinkSuccess(response))
    const res = yield call(getProjectLinkApi, link.projectId)
    yield put(getProjectLinkSuccess(res))
    toast.dismiss();
  } catch (error) {
    yield put(deleteProjectLinkFail(error))
  }
}

function* submitProjectGallery({ payload: { gallery, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postProjectGalleryApi,gallery)
    yield put(addProjectGallerySuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Image Added");
      const response = yield call(getProjectGalleryApi, gallery.projectId)
      yield put(getProjectGallarySuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addProjectGalleryFail(error))
  }
}

function* fetchProjectGallery({ projectId }) {
  try {
    //console.log(uuid);
    const response = yield call(getProjectGalleryApi, projectId)
    yield put(getProjectGallarySuccess(response))
  } catch (error) {
    yield put(getProjectGallaryFail(error))
  }
}

function* onDeleteGallery({ payload: image }) {
  try {
    //console.log(contact)
    toast.loading("Please wait...")
    const response = yield call(deleteProjectGalleryApi, image.id)
    yield put(deleteProjectGallerySuccess(response))
    const res = yield call(getProjectGalleryApi, image.projectId)
    yield put(getProjectGallarySuccess(res))
    toast.dismiss();
  } catch (error) {
    yield put(deleteProjectGalleryFail(error))
  }
}



function* projectSaga() {
  yield takeEvery(ADD_PROJECT, createProject)
  yield takeEvery(GET_ALL_PROJECT, fetchAllProject)
  yield takeEvery(ADD_PROJECT_FEATURE, createProjectFeature)
  yield takeEvery(ADD_PROJECT_CONTACT, createProjectContact)
  yield takeEvery(GET_PROJECT_CONTACT, fetchProjectContact)
  yield takeEvery(UPDATE_PROJECT_CONTACT, onProjectContactUpdate)
  yield takeEvery(DELETE_PROJECT_CONTACT, onDeleteContact)
  yield takeEvery(ADD_PROJECT_MEDIA, submitProjectDocument)
  yield takeEvery(GET_PROJECT_MEDIA, fetchProjectDocument)
  yield takeEvery(DELETE_PROJECT_MEDIA, onDeleteDocument)
  yield takeEvery(ADD_PROJECT_MARKET, submitProjectMarket)
  yield takeEvery(GET_PROJECT_MARKET, fetchProjectMarket)
  yield takeEvery(UPDATE_PROJECT_MARKET, onProjectMarketUpdate)
  yield takeEvery(DELETE_PROJECT_MARKET, onDeleteMarket)
  yield takeEvery(GET_ALL_PROJECT_UUID, fetchProjectTab1)
  yield takeEvery(GET_PROJECT_FEATURE, fetchFeatureTab)
  yield takeEvery(DELETE_PROJECT, onDeleteProject)
  yield takeEvery(UPDATE_PROJECT, onUpdateProject)
  yield takeEvery(UPDATE_PROJECT_FEATURE, onUpdateProjectFeature)
  yield takeEvery(ADD_PROJECT_LINK, submitProjectLink)
  yield takeEvery(GET_PROJECT_LINK, fetchProjectLink)
  yield takeEvery(DELETE_PROJECT_LINK, onDeleteLink)
  yield takeEvery(ADD_PROJECT_GALLERY, submitProjectGallery)
  yield takeEvery(GET_PROJECT_GALLERY, fetchProjectGallery)
  yield takeEvery(DELETE_PROJECT_GALLERY, onDeleteGallery)
}


export default projectSaga
