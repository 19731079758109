import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link  } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map } from "lodash"
import "react-image-lightbox/style.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { addProjectFeature, getProjectFeatureAction, updateProjectFeature } from "../../../store/projects/actions"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  
  TabContent,
  FormGroup,
  Label,
}from "reactstrap"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


class ProjectsFeature extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      
    }
    this.handleProjectFeature = this.handleProjectFeature.bind(this)
  }
  handleProjectFeature(value){
    this.props.addProjectFeature(value,this.props.history);
    
  }
  
  componentDidMount() {
    const { match: { params }, onGetProjectFeature }= this.props;
    if (params && params.id &&  params.projectId) {
      onGetProjectFeature(params.id);
    }else{
      this.props.history.push('/my-project')
    }
  }
  render() {
    const { projectFeature, onUpdateFeature } = this.props;
      return (
        
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Features")} />
                <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/" +  this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link active" to={"/pre-construction/create-project/feature/" +  this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                    <Link className="nav-link" to={"/pre-construction/create-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/link/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Links")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> 
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                     
                      <NavItem>
                      <Link  className="nav-link" to={"/pre-construction/create-project/marketing/"+ this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
                <Card>
                  <CardBody>
                 

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                    {!isEmpty(projectFeature) ? (
                      <Formik
                      enableReinitialize={true}
                      initialValues={{
                        id:projectFeature.id,
                        projectFeatureId:projectFeature.projectFeatureId,
                        projectId: projectFeature.projectId,
                        projectSummary: projectFeature.projectSummary,
                        projectOverview: projectFeature.projectOverview,
                        projectHighlights: projectFeature.projectHighlights,
                        projectPriceFee:projectFeature.projectPriceFee,
                        
                        projectDepositStructure:projectFeature.projectDepositStructure,
                        projectCurrentIncentive:projectFeature.projectCurrentIncentive

                      }}
                      validationSchema={Yup.object().shape({
                      
                        
                      })}

                      onSubmit={values => {
                        
                        const updateFeature = {
                        id:values.id,
                        projectFeatureId:values.projectFeatureId,
                        projectId: values.projectId,
                        projectSummary: values.projectSummary,
                        projectOverview: values.projectOverview,
                        projectHighlights: values.projectHighlights,
                        projectPriceFee:values.projectPriceFee,
                        projectDepositStructure:values.projectDepositStructure,
                        projectCurrentIncentive:values.projectCurrentIncentive
                        };
  
                        onUpdateFeature(updateFeature);
                      }
                      }
                    >
                   {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
          <Form
            className="needs-validation"
          >
            
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <h4>
                    <strong>{this.props.t("Project Summary")}</strong>
                  </h4>
                  <CKEditor
                      editor={ClassicEditor}
                      data ={projectFeature.projectSummary}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('projectSummary', data);
                        
                      }}
                     
                    />
                </FormGroup>
              </Col>
              
            </Row>
            <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                           
                            <h4>
                              <strong> {this.props.t("Project Overview")}</strong>
                            </h4>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectOverview}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectOverview', data);
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                           
                            <h4>
                              <strong> {this.props.t("Project Highlights")}</strong>
                            </h4>
                            <CKEditor
                                editor={ClassicEditor}
                                data ={projectFeature.projectHighlights}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectHighlights', data);
                                }}
                  
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>          
           <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <h4>
                    <strong>{this.props.t("Price & Fee")}</strong>
                  </h4>
                  <CKEditor
                      editor={ClassicEditor}
                      data ={projectFeature.projectPriceFee}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('projectPriceFee', editor.getData());
                      }}
                     
                    />
                </FormGroup>
              </Col>
              
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <h4>
                    <strong>{this.props.t("Deposit Structure")}</strong>
                  </h4>
                  <CKEditor
                      editor={ClassicEditor}
                      data ={projectFeature.projectDepositStructure}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('projectDepositStructure', editor.getData());
                      }}
                     
                    />
                </FormGroup>
              </Col>
              
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                <h4>
                <strong>{this.props.t("Current Incentive")}</strong>
                    </h4>
                  <CKEditor
                      editor={ClassicEditor}
                      data ={projectFeature.projectCurrentIncentive}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue('projectCurrentIncentive', editor.getData());
                      }}
                     
                    />
                </FormGroup>
              </Col>
              
            </Row> 
         
            
            <div><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Save")}</button></div>
            
            </Form>
        )}
      </Formik>
                    ):(
                    <Formik
                                enableReinitialize={true}
                                initialValues={{
                                  projectId: this.props.match.params.id,
                                  projectSummary: '',
                                  projectPriceFee:'',
                                  projectDepositStructure:'',
                                  projectCurrentIncentive:''

                                }}
                                validationSchema={Yup.object().shape({
                                
                                  
                                })}

                                onSubmit={this.handleProjectFeature }
                              >
                             {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                           
                            <h4>
                              <strong> {this.props.t("Project Summary")}</strong>
                            </h4>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectSummary', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                           
                            <h4>
                              <strong> {this.props.t("Project Overview")}</strong>
                            </h4>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectOverview', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                           
                            <h4>
                              <strong> {this.props.t("Project Highlights")}</strong>
                            </h4>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectHighlights', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>

                     <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <h4>
                              <strong>{this.props.t("Price & Fee")}</strong>
                            </h4>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectPriceFee', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            
                            <h4>
                              <strong>{this.props.t("Deposit Structure")}</strong>
                            </h4>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectDepositStructure', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                           
                            <h4>
                              <strong>{this.props.t("Current Incentive")}</strong>
                            </h4>
                            <CKEditor
                                editor={ClassicEditor}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  setFieldValue('projectCurrentIncentive', editor.getData());
                                }}
                               
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row> 
                   
                      
                      <div><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Save")}</button></div>
                      
                      </Form>
                  )}
                    </Formik>
                    )}
                    </TabContent>
                      
                     
                  </CardBody>
                </Card>
              
            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}


ProjectsFeature.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  addProjectFeature:PropTypes.func,
  match: PropTypes.object,
  projectFeature:PropTypes.object,
  onGetProjectFeature:PropTypes.func,
  onUpdateFeature:PropTypes.func
}
const mapStateToProps = ({ Project }) => (
  {
    projectFeature:Project.projectFeature

  })
const mapDispatchToProps = dispatch => ({
  addProjectFeature: (data) => dispatch(addProjectFeature(data)),
  onGetProjectFeature:(projectId) => dispatch(getProjectFeatureAction(projectId)),
  onUpdateFeature:(data) => dispatch(updateProjectFeature(data)),
});


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsFeature)))