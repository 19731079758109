import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map, size } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../../components/Common/DeleteModal";
import { addProjectContact, getProjectContact, updateProjectContact, deleteProjectContact} from "../../../store/projects/actions"
import MaskedInput from "react-text-mask";

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Nav,
  NavItem,
  TabContent,
  FormGroup,
  Label,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


class ProjectsContact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contact: "",
      
    }
    this.handleProjectContact = this.handleProjectContact.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this);
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
  }
  
  handleProjectContact(value){
    this.props.addProjectContact(value,this.props.history);
    
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (contact) => {
    this.setState({ contact: contact });
    this.setState({ deleteModal: true });
  };

  handleDeleteContact = () => {
    const { onDeleteContact } = this.props;
    const { contact } = this.state;
    //console.log(project);
    if (contact.id !== undefined) {
      onDeleteContact(contact.id);
      this.setState({ deleteModal: false });
    }
  };
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  componentDidMount(prevProps) {
    const { match: { params }, onGetProjectContact , contacts}= this.props;
    if (params && params.id &&  params.projectId) {
      onGetProjectContact(params.id);
      this.setState({ contacts });
    
    }else{
      this.props.history.push('/my-project')
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { contacts } = this.props;
    if (!isEmpty(contacts) && size(prevProps.contacts) !== size(contacts)) {
      this.setState({ contacts: {}, isEdit: false });
    }
  }
  toggleRightCanvas() {
    this.setState({ contact: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const contact = arg;

    this.setState({
      contact: {
    
        id:contact.id,
        projectId: contact.projectId,
        projectContactId:contact.projectContactId,
        pContactDepartment: contact.pContactDepartment,
        pContactName: contact.pContactName,
        phone: contact.projectContact.phone,
        email: contact.projectContact.email,
        mobile: contact.projectContact.mobile,
        fax: contact.projectContact.fax,
      },
      isEdit: true,
    });
    this.toggle();
  };

  render() {
    const phoneNumberMask = [
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
    const { contacts, onUpdateProjectContact } = this.props;
    const { isEdit, deleteModal } = this.state;
    const contact = this.state.contact;
      return (
      <React.Fragment>
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteContact}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
         <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Contact")} />
            <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link" to={"/pre-construction/create-project/feature/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/create-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                    <Link className="nav-link" to={"/pre-construction/create-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/link/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Links")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> 
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                   
                      <NavItem>
                      <Link  className="nav-link" to={"/pre-construction/create-project/marketing/"+ this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
            <Card>
                  <CardBody>
                 

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                    <Formik
                                enableReinitialize={true}
                                initialValues={{
                                  projectId: this.props.match.params.id,
                                  pContactDepartment: '',
                                  pContactName:'',
                                  contactPhone: '',
                                  emailContact: '',
                                  contactMobile: '',
                                  contactFax: '',
                                 

                                }}
                                validationSchema={Yup.object().shape({
                                  pContactDepartment: Yup.string().required("This is required"),
                                  pContactName: Yup.string().required("This is required"),
                                  contactPhone: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                                  contactMobile: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                                  contactFax: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                                  emailContact: Yup.string().email('Enter Valid Email').required('This is required')
                                })}

                                onSubmit={this.handleProjectContact }
                              >
                             {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="pContactDepartment">
                              {this.props.t("Department")}
                            </Label>
                            <Field
                                name="pContactDepartment"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactDepartment && touched.pContactDepartment
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                            <ErrorMessage
                                name="pContactDepartment"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="pContactName">
                              {this.props.t("Contact Name")}
                            </Label>
                            <Field
                                name="pContactName"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactName && touched.pContactName
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                            <ErrorMessage
                                name="pContactName"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactPhone">
                              {this.props.t("Phone")}
                            </Label>
                            {/* <Field
                                        name="contactPhone"
                                        render={({ field }) => (
                                          <MaskedInput
                                            {...field}
                                            mask={phoneNumberMask}
                                            id="contactPhone"
                                            placeholder=""
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                              "form-control" +
                                              (errors.contactPhone && touched.contactPhone
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                        )}
                                      /> */}
                             <Field
                                name="contactPhone"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactPhone && touched.contactPhone
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                            <ErrorMessage
                                name="contactPhone"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>

                        <Col md="1">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactPhoneext">
                              {this.props.t("Ext")}
                            </Label>
                            <Field
                                name="contactPhoneext"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactPhoneext && touched.contactPhoneext
                                    ? " is-invalid"
                                    : "")
                                }
                            />                            
                            <ErrorMessage
                                name="contactPhoneext"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>
                        
                      </Row>


                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="emailContact">
                              {this.props.t("Email")}
                            </Label>
                            <Field
                                name="emailContact"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.emailContact && touched.emailContact
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                            <ErrorMessage
                                name="emailContact"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactMobile">
                              {this.props.t("Mobile")}
                            </Label>
                            <Field
                                        name="contactMobile"
                                        render={({ field }) => (
                                          <MaskedInput
                                            {...field}
                                            mask={phoneNumberMask}
                                            id="contactMobile"
                                            placeholder=""
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                              "form-control" +
                                              (errors.contactMobile && touched.contactMobile
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                        )}
                                      />
                          
                            <ErrorMessage
                                name="contactMobile"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>

                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="contactFax">
                              {this.props.t("Fax")}
                            </Label>
                            <Field
                                        name="contactFax"
                                        render={({ field }) => (
                                          <MaskedInput
                                            {...field}
                                            mask={phoneNumberMask}
                                            id="contactFax"
                                            placeholder=""
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                              "form-control" +
                                              (errors.contactFax && touched.contactFax
                                                ? " is-invalid"
                                                : "")
                                            }
                                          />
                                        )}
                                      />
                          
                            <ErrorMessage
                                name="contactFax"
                                component="div"
                                className="invalid-feedback"
                              />
                          </FormGroup>
                        </Col>
                        <div className="clearFix"><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Save")}</button></div>
                      </Row>

                      </Form>
                  )}
                </Formik>
                  
                      
                     
                      
                      <Row className="mt20">
                          <Col lg="12">
                          <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              <Th>{this.props.t("Department")}</Th>
                              <Th data-priority="1">{this.props.t("Contact Name")}</Th>
                              <Th data-priority="3">{this.props.t("Phone")}</Th>
                              <Th data-priority="1">{this.props.t("Email")}</Th>
                              <Th data-priority="3">{this.props.t("Mobile")}</Th>
                              <Th data-priority="3">{this.props.t("Fax")}</Th>
                              <Th data-priority="4"></Th> 
                              
                            </Tr>
                          </Thead>
                          <Tbody>
                          {map(contacts, (contact, conkey) => (
                            <Tr>
                              <Th>
                               
                                <span className="co-name">{contact.pContactDepartment}</span>
                              </Th>
                              <Td>{contact.pContactName}</Td>
                              
                              <Td>{contact.projectContact.phone}</Td>
                              <Td>{contact.projectContact.email}</Td>
                              <Td>{contact.projectContact.mobile}</Td>
                              <Td>{contact.projectContact.fax}</Td>
                              <Td><Link to="#" onClick={() => this.toggleRightCanvas(contact)} className="btn-primary waves-effect waves-light btn-sm">Edit</Link> <Link to="#" onClick={() => this.onClickDelete(contact)} className="btn-delete waves-effect waves-light btn-sm">Delete</Link></Td>
                             
                            </Tr>
                             ))}
                          </Tbody> 
                        </Table>
                      </div>
                    </div>
                          </Col>

                      </Row>


                    
                            

                        </TabContent>
                        </CardBody>
                        </Card>

            <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update Contact
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id:contact.id,
                    projectId: contact.projectId,
                    projectContactId:contact.projectContactId,
                    pContactDepartment: contact.pContactDepartment,
                    pContactName: contact.pContactName,
                    phone: contact.phone,
                    email: contact.email,
                    mobile: contact.mobile,
                    fax: contact.fax
                  }}
                  validationSchema={Yup.object().shape({
                    pContactDepartment: Yup.string().required("This is required"),
                      pContactName: Yup.string().required("This is required"),
                      phone: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Phone number is not valid').min(10, "Phone number is not valid").max(10, "Phone number is not valid"),
                      mobile: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').min(10, "Enter number is not valid").max(10, "Enter number is not valid"),
                      fax: Yup.string().matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, 'Enter number is not valid').min(10, "Enter number is not valid").max(10, "Enter number is not valid"),
                      email: Yup.string().email('Enter Valid Email').required('This is required')
                  })}

                  onSubmit={values => {
                    console.log(isEdit);
                    if (isEdit) {
                      const updateContact = {
                        id:values.id,
                        projectId: values.projectId,
                        projectContactId:values.projectContactId,
                        pContactDepartment: values.pContactDepartment,
                        pContactName: values.pContactName,
                        phone: values.phone,
                        email: values.email,
                        mobile: values.mobile,
                        fax: values.fax,
                        pContactIsActive: true
                      };

                      onUpdateProjectContact(updateContact);
                    } else {
                      
                    }

                    //this.setState({ selectedOrder: null });
                    this.toggle();
                  }}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                      <Row>
                      <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="pContactDepartment">
                                {this.props.t("Department")}
                              </Label>
                              <Field
                                name="pContactDepartment"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactDepartment && touched.pContactDepartment
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="pContactDepartment"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="pContactName">
                                {this.props.t("Contact Name")}
                              </Label>
                              <Field
                                name="pContactName"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.pContactName && touched.pContactName
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="pContactName"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="phone">
                                {this.props.t("Phone")}
                              </Label>
                              <Field
                                name="phone"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactPhone && touched.contactPhone
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="phone"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="email">
                                {this.props.t("Email")}
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.emailContact && touched.emailContact
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="mobile">
                                {this.props.t("Mobile")}
                              </Label>
                              <Field
                                name="mobile"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactMobile && touched.contactMobile
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="mobile"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3">
                              <Label htmlFor="fax">
                                {this.props.t("Fax")}
                              </Label>
                              <Field
                                name="fax"
                                type="text"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.contactFax && touched.contactFax
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="fax"
                                component="div"
                                className="invalid-feedback"
                              />
                            </FormGroup>
                          </Col>




                      </Row>










                      <div><button type="submit" className="btn btn-primary w-md">{this.props.t("Update")}</button></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsContact.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  addProjectContact:PropTypes.func,
  contacts:PropTypes.array,
  match:PropTypes.object,
  onGetProjectContact: PropTypes.func,
  onDeleteContact: PropTypes.func,
  onUpdateProjectContact:PropTypes.func
}

const mapStateToProps = ({ Project }) => (
  {
    contacts: Project.contacts

  })

const mapDispatchToProps = dispatch => ({
  addProjectContact: (data) => dispatch(addProjectContact(data)),
  onGetProjectContact:(BrokerProjectId) => dispatch(getProjectContact(BrokerProjectId)),
  onUpdateProjectContact: contact => dispatch(updateProjectContact(contact)),
  onDeleteContact: (id) => dispatch(deleteProjectContact(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsContact)))