import {
  GET_PROJECT_TYPE,
  GET_PROJECT_TYPE_SUCCESS,
  GET_PROJECT_TYPE_FAIL,
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  GET_ALL_PROJECT,
  GET_ALL_PROJECT_SUCCESS,
  GET_ALL_PROJECT_FAIL,
  ADD_PROJECT_FEATURE,
  ADD_PROJECT_FEATURE_SUCCESS,
  ADD_PROJECT_FEATURE_ERROR,
  ADD_PROJECT_CONTACT,
  ADD_PROJECT_CONTACT_SUCCESS,
  ADD_PROJECT_CONTACT_ERROR,
  GET_PROJECT_CONTACT,
  GET_PROJECT_CONTACT_SUCCESS,
  GET_PROJECT_CONTACT_FAIL,
  ADD_PROJECT_MEDIA,
  ADD_PROJECT_MEDIA_SUCCESS,
  ADD_PROJECT_MEDIA_ERROR,
  GET_PROJECT_MEDIA,
  GET_PROJECT_MEDIA_SUCCESS,
  ADD_PROJECT_MARKET,
  ADD_PROJECT_MARKET_SUCCESS,
  ADD_PROJECT_MARKET_ERROR,
  GET_PROJECT_MARKET,
  GET_PROJECT_MARKET_SUCCESS,
  GET_PROJECT_MARKET_ERROR,
  GET_ALL_PROJECT_UUID,
  GET_ALL_PROJECT_UUID_SUCCESS,
  GET_ALL_PROJECT_UUID_FAIL,
  GET_PROJECT_FEATURE,
  GET_PROJECT_FEATURE_SUCCESS,
  GET_PROJECT_FEATURE_ERROR,
  ADD_PROJECT_ERROR,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  UPDATE_PROJECT_CONTACT,
  UPDATE_PROJECT_CONTACT_SUCCESS,
  UPDATE_PROJECT_CONTACT_FAIL,
  UPDATE_PROJECT_FEATURE,
  UPDATE_PROJECT_FEATURE_SUCCESS,
  UPDATE_PROJECT_FEATURE_ERROR,
  DELETE_PROJECT_CONTACT,
  DELETE_PROJECT_CONTACT_SUCCESS,
  DELETE_PROJECT_CONTACT_FAIL,
  DELETE_PROJECT_MEDIA,
  DELETE_PROJECT_MEDIA_SUCCESS,
  DELETE_PROJECT_MEDIA_FAIL,
  UPDATE_PROJECT_MARKET,
  UPDATE_PROJECT_MARKET_SUCCESS,
  UPDATE_PROJECT_MARKET_ERROR,
  DELETE_PROJECT_MARKET,
  DELETE_PROJECT_MARKET_SUCCESS,
  DELETE_PROJECT_MARKET_FAIL,
  DELETE_PROJECT_LINK,
  DELETE_PROJECT_LINK_SUCCESS,
  DELETE_PROJECT_LINK_FAIL,
  GET_PROJECT_LINK,
  GET_PROJECT_LINK_SUCCESS,
  GET_PROJECT_LINK_ERROR,
  ADD_PROJECT_LINK,
  ADD_PROJECT_LINK_SUCCESS,
  ADD_PROJECT_LINK_ERROR,
  ADD_PROJECT_GALLERY,
  ADD_PROJECT_GALLERY_SUCCESS,
  ADD_PROJECT_GALLERY_ERROR,
  GET_PROJECT_GALLERY,
  GET_PROJECT_GALLERY_SUCCESS,
  GET_PROJECT_GALLERY_ERROR,
  DELETE_PROJECT_GALLERY,
  DELETE_PROJECT_GALLERY_FAIL,
  DELETE_PROJECT_GALLERY_SUCCESS
} from "./actionTypes"

export const addProject = (project, history) => {
  console.log(project)
  return {
    type: ADD_PROJECT,
    payload: { project, history },
  }
}

export const addProjectSuccess = project => {
  return {
    type: ADD_PROJECT_SUCCESS,
    payload: project,
  }
}

export const addProjectError = error => {
  return {
    type: ADD_PROJECT_ERROR,
    payload: error,
  }
}


export const deleteProject = id => ({
  type: DELETE_PROJECT,
  payload: id,
})

export const deleteProjectSuccess = project => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: project,
})

export const deleteProjectFail = error => ({
  type: DELETE_PROJECT_FAIL,
  payload: error,
})


export const projectUpdate = project => {
  return {
    type: UPDATE_PROJECT,
    payload: project,
  }
}

export const projectUpdateSuccess = project => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
    payload: project,
  }
}

export const projectUpdateError = error => {
  return {
    type: UPDATE_PROJECT_FAIL,
    payload: error,
  }
}



export const getProjectType = projectType => {
  return {
    type: GET_PROJECT_TYPE,
    payload: projectType,
  }
}

export const getProjectTypeSuccess = projectType => {
  return {
    type: GET_PROJECT_TYPE_SUCCESS,
    payload: projectType,
  }
}


export const projectapiError = error => {
  return {
    type: GET_PROJECT_TYPE_FAIL,
    payload: error,
  }
}

export const getAllProjectAction = (agentId) => (
  {
    type: GET_ALL_PROJECT,
    agentId
  })

export const getAllProjectActionSuccess = projects => ({
  type: GET_ALL_PROJECT_SUCCESS,
  payload: projects,
})

export const getAllProjectActionFail = error => ({
  type: GET_ALL_PROJECT_FAIL,
  payload: error,
})


export const addProjectFeature = (feature, history) => {
  return {
    type: ADD_PROJECT_FEATURE,
    payload: { feature, history },
  }
}

export const addProjectFeatureSuccess = feature => {
  return {
    type: ADD_PROJECT_FEATURE_SUCCESS,
    payload: feature,
  }
}
export const addProjectFeatureFail = error => {
  return {
    type: ADD_PROJECT_FEATURE_ERROR,
    payload: error,
  }
}


export const addProjectContact = (contact, history) => {
  return {
    type: ADD_PROJECT_CONTACT,
    payload: { contact, history },
  }
}

export const addProjectContactSuccess = contact => {
  return {
    type: ADD_PROJECT_CONTACT_SUCCESS,
    payload: contact,
  }
}
export const addProjectContactFail = error => {
  return {
    type: ADD_PROJECT_CONTACT_ERROR,
    payload: error,
  }
}

export const getProjectContact = (projectId) => (
  {
    type: GET_PROJECT_CONTACT,
    projectId
  })

export const getProjectContactSuccess = contacts => ({
  type: GET_PROJECT_CONTACT_SUCCESS,
  payload: contacts,
})

export const getProjectContactFail = error => ({
  type: GET_PROJECT_CONTACT_FAIL,
  payload: error,
})



export const addProjectMedia = (document, history) => {
  return {
    type: ADD_PROJECT_MEDIA,
    payload: { document, history },
  }
}

export const addProjectMediaSuccess = documents => {
  return {
    type: ADD_PROJECT_MEDIA_SUCCESS,
    payload: documents,
  }
}
export const addProjectMediaFail = error => {
  return {
    type: ADD_PROJECT_MEDIA_ERROR,
    payload: error,
  }
}


export const getProjectMedia = (projectId) => (
  {
    type: GET_PROJECT_MEDIA,
    projectId
  })

export const getProjectMediaSuccess = documents => ({
  type: GET_PROJECT_MEDIA_SUCCESS,
  payload: documents,
})

export const getProjectMediaFail = error => ({
  type: GET_PROJECT_CONTACT_FAIL,
  payload: error,
})


export const addProjectMarket = (market, history) => {
  return {
    type: ADD_PROJECT_MARKET,
    payload: { market, history },
  }
}

export const addProjectMarketSuccess = markets => {
  return {
    type: ADD_PROJECT_MARKET_SUCCESS,
    payload: markets,
  }
}
export const addProjectMarketFail = error => {
  return {
    type: ADD_PROJECT_MARKET_ERROR,
    payload: error,
  }
}


export const getProjectMarket = (projectId) => (
  {
    type: GET_PROJECT_MARKET,
    projectId
  })

export const getProjectMarketSuccess = markets => ({
  type: GET_PROJECT_MARKET_SUCCESS,
  payload: markets,
})

export const getProjectMarketFail = error => ({
  type: GET_PROJECT_MARKET_ERROR,
  payload: error,
})


export const getProjectUUIDAction = (uuid) => ({
  type: GET_ALL_PROJECT_UUID,
  uuid
})

export const getProjectUUIDActionSuccess = projectData => ({
  type: GET_ALL_PROJECT_UUID_SUCCESS,
  payload: projectData,
})

export const getProjectUUIDActionFail = error => ({
  type: GET_ALL_PROJECT_UUID_FAIL,
  payload: error,
})


export const getProjectFeatureAction = (projectId) => ({
  type: GET_PROJECT_FEATURE,
  projectId
})

export const getProjectFeatureActionSuccess = projectFeature => ({
  type: GET_PROJECT_FEATURE_SUCCESS,
  payload: projectFeature,
})

export const getProjectFeatureActionFail = error => ({
  type: GET_PROJECT_FEATURE_ERROR,
  payload: error,
})


export const updateProjectContact = contact => ({
  type: UPDATE_PROJECT_CONTACT,
  payload: contact,
})

export const updateProjectContactSuccess = contact => ({
  type: UPDATE_PROJECT_CONTACT_SUCCESS,
  payload: contact,
})

export const updateProjectContactFail = error => ({
  type: UPDATE_PROJECT_CONTACT_FAIL,
  payload: error,
})


export const updateProjectFeature = projectFeature => ({
  type: UPDATE_PROJECT_FEATURE,
  payload: projectFeature,
})

export const updateProjectFeatureSuccess = projectFeature => ({
  type: UPDATE_PROJECT_FEATURE_SUCCESS,
  payload: projectFeature,
})

export const updateProjectFeatureFail = error => ({
  type: UPDATE_PROJECT_FEATURE_ERROR,
  payload: error,
})

export const deleteProjectContact = id => ({
  type: DELETE_PROJECT_CONTACT,
  payload: id,
})

export const deleteProjectContactSuccess = contact => ({
  type: DELETE_PROJECT_CONTACT_SUCCESS,
  payload: contact,
})

export const deleteProjectContactFail = error => ({
  type: DELETE_PROJECT_CONTACT_FAIL,
  payload: error,
})


export const deleteProjectDocument = id => ({
  type: DELETE_PROJECT_MEDIA,
  payload: id,
})

export const deleteProjectDocumentSuccess = document => ({
  type: DELETE_PROJECT_MEDIA_SUCCESS,
  payload: document,
})

export const deleteProjectDocumentFail = error => ({
  type: DELETE_PROJECT_MEDIA_FAIL,
  payload: error,
})



export const updateProjectMarket = market => ({
  type: UPDATE_PROJECT_MARKET,
  payload: market,
})

export const updateProjectMarketSuccess = markets => ({
  type: UPDATE_PROJECT_MARKET_SUCCESS,
  payload: markets,
})

export const updateProjectMarketFail = error => ({
  type: UPDATE_PROJECT_MARKET_ERROR,
  payload: error,
})


export const deleteProjectMarket = id => ({
  type: DELETE_PROJECT_MARKET,
  payload: id,
})

export const deleteProjectMarketSuccess = market => ({
  type: DELETE_PROJECT_MARKET_SUCCESS,
  payload: market,
})

export const addProjectLink = (link, history) => {
  return {
    type: ADD_PROJECT_LINK,
    payload: { link, history },
  }
}

export const addProjectLinkSuccess = links => {
  return {
    type: ADD_PROJECT_LINK_SUCCESS,
    payload: links,
  }
}
export const addProjectLinkFail = error => {
  return {
    type: ADD_PROJECT_LINK_ERROR,
    payload: error,
  }
}

export const getProjectLink = (projectId) => (
  {
    type: GET_PROJECT_LINK,
    projectId
  })

export const getProjectLinkSuccess = links => ({
  type: GET_PROJECT_LINK_SUCCESS,
  payload: links,
})

export const getProjectLinkFail = error => ({
  type: GET_PROJECT_LINK_ERROR,
  payload: error,
})


export const deleteProjectMarketFail = error => ({
  type: DELETE_PROJECT_MARKET_FAIL,
  payload: error,
})


export const deleteProjectLink = id => ({
  type: DELETE_PROJECT_LINK,
  payload: id,
})

export const deleteProjectLinkSuccess = link => ({
  type: DELETE_PROJECT_LINK_SUCCESS,
  payload: link,
})

export const deleteProjectLinkFail = error => ({
  type: DELETE_PROJECT_LINK_FAIL,
  payload: error,
})

export const addProjectGallery = (gallery, history) => {
  return {
    type: ADD_PROJECT_GALLERY,
    payload: { gallery, history },
  }
}

export const addProjectGallerySuccess = images => {
  return {
    type: ADD_PROJECT_GALLERY_SUCCESS,
    payload: images,
  }
}
export const addProjectGalleryFail = error => {
  return {
    type: ADD_PROJECT_GALLERY_ERROR,
    payload: error,
  }
}

export const getProjectGallary = (projectId) => (
  {
    type: GET_PROJECT_GALLERY,
    projectId
  })

export const getProjectGallarySuccess = images => ({
  type: GET_PROJECT_GALLERY_SUCCESS,
  payload: images,
})

export const getProjectGallaryFail = error => ({
  type: GET_PROJECT_GALLERY_ERROR,
  payload: error,
})

export const deleteProjectGallery = id => ({
  type: DELETE_PROJECT_GALLERY,
  payload: id,
})

export const deleteProjectGallerySuccess = image => ({
  type: DELETE_PROJECT_GALLERY_SUCCESS,
  payload: image,
})

export const deleteProjectGalleryFail = error => ({
  type: DELETE_PROJECT_GALLERY_FAIL,
  payload: error,
})