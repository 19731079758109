import { ADD_BROKER_AGENT_ERROR, ADD_BROKER_AGENT_SUCCESS, GET_BROKER_AGENT_DETAIL_FAIL, GET_BROKER_AGENT_DETAIL_SUCCESS, GET_BROKER_AGENT_FAIL, GET_BROKER_AGENT_SUCCESS } from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  agents: [],
  agentData: {},

}

const Agent = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BROKER_AGENT_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_BROKER_AGENT_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break

    case GET_BROKER_AGENT_SUCCESS:
      return {
        ...state,
        agents: action.payload,
      }
    case GET_BROKER_AGENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_BROKER_AGENT_DETAIL_SUCCESS:
      return {
        ...state,
        agentData: action.payload,
      }

    case GET_BROKER_AGENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      state = { ...state }
      break
  }
  return state
}

export default Agent
