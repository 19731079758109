import React, { Component } from "react"
import PropTypes from 'prop-types'

import { FormGroup, Label, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginUser } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import realtyspacelogo from "../../assets/images/realtyspace.png"
import lightlogo from "../../assets/images/logo-light.svg"

class Login extends Component {
    constructor(props) {
    super(props)
    this.state = {}

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(value, history) {
    this.props.loginUser(value, this.props.history)
  }

  componentDidMount() {
    this.props.apiError("")
  }

  

  render() {
    return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to Realty Space.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="text-center">
                          <span className="">
                            <img
                              src={realtyspacelogo}
                              alt=""
                              className=""
                              height=""
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        userName: (this.state && this.state.userName) || "",
                        userPassword:"",
                        

                      }}
                      validationSchema={Yup.object().shape({
                        userName: Yup.string().required("This is required"),
                        userPassword: Yup.string().required("This is required")
                      })}

                      onSubmit={this.handleValidSubmit }
                    >
                   {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
          <Form
            className="needs-validation"
          >

                        <div className="mb-3">
                        <FormGroup className="mb-3">
                        <Label htmlFor="userName">
                        Email
                        </Label>
                            <Field
                                name="userName"
                                type="text"
                                onChange={handleChange}
                                placeholder="Enter Email"
                                className={
                                  "form-control" +
                                  (errors.userName && touched.userName
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                          <ErrorMessage
                              name="userName"
                              component="div"
                              className="invalid-feedback"
                            />
                </FormGroup>
                        </div>

                        <div className="mb-3">
                        <FormGroup className="mb-3">
                        <Label htmlFor="userName">
                        Password
                        </Label>
                            <Field
                                name="userPassword"
                                type="password"
                                placeholder="Enter Password"
                                onChange={handleChange}
                                className={
                                  "form-control" +
                                  (errors.userPassword && touched.userPassword
                                    ? " is-invalid"
                                    : "")
                                }
                            />
                  <ErrorMessage
                      name="userPassword"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
                        </div>

                     

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>

                       
                      </Form>
                   )}
                  
                  </Formik>
                    </div>
                  </CardBody>
                </Card>
                
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)