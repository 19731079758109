import { combineReducers } from "redux"
import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
import Builder from "./builder/reducer"
import Project from "./projects/reducer"
import leads from "./leads/reducer"
import Agent from "./agent/reducer"
import Event from "./event/reducer"
import Messages from "./message/reducer"
import Resource from "./resource/reducer"


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Builder,
  Project,
  leads,
  Agent,
  Event,
  Messages,
  Resource
})

export default rootReducer
