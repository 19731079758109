import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map } from "lodash"
//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../../components/Common/DeleteModal";
import { addProjectGallery, getProjectGallary, deleteProjectGallery, getProjectUUIDAction } from "../../../store/projects/actions"

import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


class ProjectsGallery extends Component {
  constructor(props) {
    super(props)
    //this.imageInputRef = React.useRef();
    this.state = {
      fileName: '',
      selectedImage: '',
      fname: '',
      projectGalleryTitle:'',
      files: []
      
    }
    this.onFileChange = this.onFileChange.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.handleProjectGallerySubmit = this.handleProjectGallerySubmit.bind(this)
  
    
  }
  
  onFileChange(e) {
    //console.log(e);
    
    let files = e.target.files;
    let fileNames = files[0];
    let fname = fileNames['name'];
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    fileReader.onload = (event) => {
    this.setState({selectedImage: event.target.result}),
    this.setState({fname: fname})
    //this.setState({fname: projectGalleryTitle})
  }
   
  }
  handleProjectGallerySubmit(value){

    this.props.addProjectGallery(value, this.props.history);

  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (image) => {
    this.setState({ image: image });
    this.setState({ deleteModal: true });
  };

  handleDeleteGallery = () => {
    const { onDeleteGallery } = this.props;
    const { image } = this.state;
    //console.log(document);
    if (image.id !== undefined) {
      onDeleteGallery(image);
      this.setState({ deleteModal: false });
    }
  };
  componentDidMount() {
    const { match: { params }, onGetProjectGallery,ongetProjectUUIDAction }= this.props;
    if (params && params.id &&  params.projectId) {
      onGetProjectGallery(params.id);
      ongetProjectUUIDAction(params.projectId);
    }else{
      this.props.history.push('/my-project')
    }
  }
  getFiles(files){
  this.setState({ files: files })
 }
  render() {
    const { images, projectData } = this.props;
    const { deleteModal } = this.state;
    //console.log(this.state.files);
      return (
      <React.Fragment>
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteGallery}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Gallery")} />
            <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link" to={"/pre-construction/create-project/feature/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                    <Link className="nav-link " to={"/pre-construction/create-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/link/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Links")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> 
                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/create-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                      {/* <NavItem>
                      <Link to="/pre-construction/create-project/payment"
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Payment Plan")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> */}
                      <NavItem>
                      <Link  className="nav-link " to={"/pre-construction/create-project/marketing/"+ this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
            <Card>
                  <CardBody>
                  
                  <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectGalleryTitle:(this.state && this.state.projectGalleryTitle) || "",
                    projectGalleryTypeId: parseInt(0),
                    builderId:parseInt(projectData.projectBuilder),
                    projectId: parseInt(this.props.match.params.id),
                    agentId: parseInt(localStorage.getItem('userId')),
                    projectGalleryContent: this.state.selectedImage,
                    projectGalleryLink: this.state.fname,
                    projectGalleryTypeId: 1,


                  }}
                  validationSchema={Yup.object().shape({
                    projectGalleryTitle: Yup.string().required("This is Required")
                  })}

                  onSubmit={this.handleProjectGallerySubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectGalleryTitle">
                              {this.props.t("Title *")}
                            </Label>
                           
                           <Field
                              name="projectGalleryTitle"
                              onChange={handleChange}                            
                              type="text"
                              value={values.projectGalleryTitle}
                              className={
                                "form-control" +
                                (errors.projectGalleryTitle && touched.projectGalleryTitle
                                  ? " is-invalid"
                                  : "")
                              }
                              id="projectGalleryTitle"
                              
                            /> 
                             <ErrorMessage
                              name="projectGalleryTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                       
                        <Col sm="5">
                        <div className="form-group mb-3">
                          <Label htmlFor="file">File upload *</Label>
                          <input id="file" name="file" type="file" 
                            onChange={ this.onFileChange } className="form-control"
                           
                            />
                          {/* <FileBase64
                            multiple={ false }
                            onDone={ this.getFiles.bind(this) } />  */}
                        </div>
                       
                        </Col>

                        
                        <Col sm="2">
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end mt-27">Upload Image</button></div>
                        </Col>
                    </Row>

                
             
             </CardBody>
                </Card>
                
            </Form>
                  )}
			              </Formik>
                      </CardBody>
                    
                      </Card>

                      <Card>
                  <CardBody>
                    <CardTitle className="h4">Gallery</CardTitle>
                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table
                          id="tech-companies-1"
                          className="table table-striped table-bordered"
                        >
                          <Thead>
                            <Tr>
                              
                              <Th data-priority="1">{this.props.t("Title")}</Th>
                              <Th data-priority="3">{this.props.t("Image")}</Th>
                              
                              <Th data-priority="4"></Th> 
                              
                            </Tr>
                          </Thead>
                          <Tbody>
                          {map(images, (image, imgkey) => (
                            <Tr>
                              <Th>
                               
                                <span className="co-name">{image.projectGalleryTitle}</span>
                              </Th>
                              <Td> <img
                          src={"http://realtyspace-001-site2.itempurl.com/" + image.projectGalleryUrl}
                          
                          alt=""
                          width="120"
                        /></Td>
                              
                             
                              <Td><Link to="#" onClick={() => this.onClickDelete(image)} className="btn-delete waves-effect waves-light btn-sm">Delete</Link></Td>
                             
                            </Tr>
                             ))}
                          </Tbody> 
                        </Table>
                      </div>
                    </div>
                  
                    
                  </CardBody>
                </Card>
                          
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsGallery.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  match:PropTypes.object,
  addProjectGallery:PropTypes.func,
  images:PropTypes.array,
  onDeleteGallery:PropTypes.func,
  onGetProjectGallery:PropTypes.func,
  ongetProjectUUIDAction: PropTypes.func,
  projectData: PropTypes.object,
}

const mapStateToProps = ({ Project }) => ({
  images: Project.images,
  projectData: Project.projectData
})

const mapDispatchToProps = dispatch => ({
  addProjectGallery: (data) => dispatch(addProjectGallery(data)),
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  onGetProjectGallery:(projectId) => dispatch(getProjectGallary(projectId)),
  onDeleteGallery: (id) => dispatch(deleteProjectGallery(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsGallery)))