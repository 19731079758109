export const ADD_BROKER_AGENT = "ADD_BROKER_AGENT"
export const ADD_BROKER_AGENT_SUCCESS = "ADD_BROKER_AGENT_SUCCESS"
export const ADD_BROKER_AGENT_ERROR = "ADD_BROKER_AGENT_ERROR"


export const GET_BROKER_AGENT = "GET_BROKER_AGENT"
export const GET_BROKER_AGENT_SUCCESS = "GET_BROKER_AGENT_SUCCESS"
export const GET_BROKER_AGENT_FAIL = "GET_BROKER_AGENT_FAIL"

export const GET_BROKER_AGENT_DETAIL = "GET_BROKER_AGENT_DETAIL"
export const GET_BROKER_AGENT_DETAIL_SUCCESS = "GET_BROKER_AGENT_DETAIL_SUCCESS"
export const GET_BROKER_AGENT_DETAIL_FAIL = "GET_BROKER_AGENT_DETAIL_FAIL"


