import React, { Component, useState }from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import axios from "axios"
import { isEmpty, map } from "lodash";
import { addProject, getProjectUUIDAction, projectUpdate } from "../../../store/projects/actions"
import RsPlacesAutoComplete from "../../../components/Common/Location";
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


class ProjectsCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projectTypes: [],
      builders: [],
      projectStatus: [],
      value:'',
      setValue:''
    }

    this.handleProjectSubmit = this.handleProjectSubmit.bind(this)
  }
  handleProjectSubmit(value){
    //console.log(this.props.history)
    this.props.addProject(value, this.props.history);
    
  }
  handleChange = setValue => {
    this.setState({ setValue });
  };
  
  
  componentDidMount() {
  
    const { match: { params }, ongetProjectUUIDAction } = this.props;

    if (params && params.projectId && params.id) {
      //console.log(params.id);
      ongetProjectUUIDAction(params.projectId);
      //onProjectUpdate(params.id)
    }

    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;
    const str = 'Bearer '+token;
    axios.get('https://rscoreapi.azurewebsites.net/api/ProjectType/all',{ headers: { Authorization: str } })
    .then(res => {
      const projectTypes = res.data;
      this.setState({ projectTypes });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/BuilderExt/allforagent',{ params: {
      agentId: parseInt(localStorage.getItem('userId'))
    }, headers: { Authorization: str } })
    .then(res => {
      const builders = res.data;
      this.setState({ builders });
    }),
    axios.get('https://rscoreapi.azurewebsites.net/api/ProjectStatus/all',{ headers: { Authorization: str } })
    .then(res => {
      const projectStatus = res.data;
      this.setState({ projectStatus });
    })
  }
  
  render() {
    const { projectData, onUpdateProject } = this.props;
    const complition = [
      /[1-9]/,
      /\d/,
      /\d/,
      /\d/
    ];
    const nofUnit = [
      /[0-9]/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ];
   
    const dateinput = [
      /[0-9]/,
      /\d/,
      /\d/,
      /\d/,
       "-",
       /\d/,
       /\d/,
       "-",
       /\d/,
       /\d/,
       
       
     ];

      return (
      
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Create New Project")} />
            {!isEmpty(projectData) ? (
            <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/create-project/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link " to={"/pre-construction/create-project/feature/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/contact/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/media/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/link/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Links")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> 
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/gallery/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                     
                      <NavItem>
                      <Link  className="nav-link" to={"/pre-construction/create-project/marketing/" + this.props.match.params.id + "/" + this.props.match.params.projectId}
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
             ) : (
              <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link active" to={"/pre-construction/create-project" }
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link " to={"/pre-construction/create-project/feature/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/link/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Links")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> 
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    
                      <NavItem>
                      <Link  className="nav-link" to={"/pre-construction/create-project/marketing/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
              )}
                <Card>
                  <CardBody>
                  

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                   
                   {!isEmpty(projectData) ? (
                    <Formik
                    enableReinitialize={true}
                    initialValues={{
                      id: projectData.id,
                      projectId: projectData.projectId,
                      projectName: projectData.projectName,
                      projectType: projectData.projectType,
                      projectBuilder: projectData.projectBuilder,
                      complitionMonth: projectData.complitionMonth,
                      complitionYear : projectData.complitionYear,
                      noOfUnits: projectData.noOfUnits,
                      noOfStories:projectData.noOfStories,
                      minBed: projectData.minBed,
                      maxBed: projectData.maxBed,
                      minArea: projectData.minArea,
                      maxArea: projectData.maxArea,
                      costPerSqFt: projectData.costPerSqFt,
                      minPriceRange: projectData.minPriceRange,
                      maxPriceRange: projectData.maxPriceRange,
                      city: projectData.city,
                      agentWebSite: projectData.agentWebSite,
                      commision: projectData.commision,
                      projectStatus: projectData.projectStatus,
                      siteAddressId: projectData.siteAddressId,
                      officeContactId: projectData.officeContactId,
                      brokerId: projectData.brokerId,
                      isActive: projectData.isActive,
                      addressLine1: projectData.addressLine1,
                      addressLine2: projectData.addressLine2,
                      nearestIntersection:projectData.nearestIntersection,
                      province: 0,
                      postal: '',
                      country: 0,
                      longitude: 0,
                      latitude: 0,
                      webSite: '',
                      estimatedOccupancy: projectData.estimatedOccupancy,
                      vipLaunchDate: projectData.vipLaunchDate,
                      publicLaunchDate: projectData.publicLaunchDate,

                    }}
                    validationSchema={Yup.object().shape({
                      projectName: Yup.string().required("This is required"),
                      projectType: Yup.string().required("This is required"),
                      projectStatus: Yup.string().required("This is required"),
                      projectBuilder: Yup.string().required("This is required")

                    })}

                    onSubmit={values => {
                     
                      const updateProject = {
                        id: values.id,
                        projectId: values.projectId,
                        projectName: values.projectName,
                        projectType: parseInt(values.projectType),
                        projectBuilder: parseInt(values.projectBuilder),
                        complitionMonth: values.complitionMonth,
                        complitionYear: parseInt(values.complitionYear),
                        noOfUnits: values.noOfUnits,
                        noOfStories: values.noOfStories,
                        minBed: parseInt(values.minBed),
                        maxBed: parseInt(values.maxBed),
                        minArea: parseInt(values.minArea),
                        maxArea: parseInt(values.maxArea),
                        costPerSqFt: parseInt(values.costPerSqFt),
                        minPriceRange: parseInt(values.minPriceRange),
                        maxPriceRange: parseInt(values.maxPriceRange),
                        city: '',
                        agentWebSite: values.agentWebSite,
                        commision: parseInt(values.commision),
                        projectStatus: parseInt(values.projectStatus),
                        siteAddressId: 0,
                        officeContactId: 0,
                        brokerId: parseInt(values.brokerId),
                        isActive: true,
                        nearestIntersection:values.nearestIntersection,
                        addressLine1: '',
                        addressLine2: '',
                        province: 0,
                        postal: '',
                        country: 0,
                        longitude: 0,
                        latitude: 0,
                        webSite: '',
                        estimatedOccupancy: values.estimatedOccupancy,
                        vipLaunchDate: values.vipLaunchDate,
                        publicLaunchDate: values.publicLaunchDate,
                      };

                      onUpdateProject(updateProject);
                    }
                    }

                  >
                    {({ errors, touched, values, handleChange, handleBlur, setFieldValue }) => (
                      <Form
                        className="needs-validation"
                      >
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                              <CardText className="mb-0">

                                <Row>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="projectName">
                                        {this.props.t("Project Name *")}
                                      </Label>
                                      <Field
                                        name="projectName"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.projectName && touched.projectName
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="projectName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="pType">
                                        {this.props.t("Type *")}
                                      </Label>
                                      <Field as="select" name="projectType"
                                        className={
                                          "form-control" +
                                          (errors.projectType && touched.projectType
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={handleChange}>
                                        <option value="">Select</option>
                                        {
                                          this.state.projectTypes
                                            .map(projectType =>
                                              <option key={projectType.id} value={projectType.id}>{projectType.projectTypeTitle}</option>
                                            )
                                        }
                                      </Field>
                                      <ErrorMessage
                                      name="projectType"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="projectStatus">
                                        {this.props.t("Project Status *")}
                                      </Label>
                                      <Field as="select" name="projectStatus"
                                         className={
                                          "form-control" +
                                          (errors.projectStatus && touched.projectStatus
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={handleChange}>
                                        <option value="">Select</option>
                                        {
                                          this.state.projectStatus
                                            .map(projectStat =>
                                              <option key={projectStat.id} value={projectStat.id}>{projectStat.projectStatusTitle}</option>
                                            )
                                        }
                                      </Field>
                                      <ErrorMessage
                                      name="projectStatus"
                                      component="div"
                                      className="invalid-feedback"
                                    />  
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="builderName">
                                        {this.props.t("Builder Name *")}
                                      </Label>
                                      <Field as="select" name="projectBuilder"
                                       className={
                                        "form-control" +
                                        (errors.projectBuilder && touched.projectBuilder
                                          ? " is-invalid"
                                          : "")
                                      }
                                        onChange={handleChange}>
                                        <option value="">Select</option>
                                        {
                                          this.state.builders
                                            .map(builder =>
                                              <option key={builder.id} value={builder.id}>{builder.builderName}</option>
                                            )
                                        }
                                      </Field>
                                      <ErrorMessage
                                      name="projectBuilder"
                                      component="div"
                                      className="invalid-feedback"
                                    />     
                                    </FormGroup>
                                  </Col>
                                 
                                  <Col md="1">
             
             <FormGroup className="mb-3">
                 <Label htmlFor="complitionMonth">
                   {this.props.t("Compl. Month")}
                 </Label>
                 <Field as="select" name="complitionMonth" 
                 className={
                   "form-control" +
                   (errors.complitionMonth && touched.complitionMonth
                     ? " is-invalid"
                     : "")
                 } 
                 onChange={handleChange}>
                       <option value="">Select</option>
                       <option value="Jan">Jan</option>
                       <option value="Feb">Feb</option>
                       <option value="Mar">Mar</option>
                       <option value="Apr">Apr</option>
                       <option value="May">May</option>
                       <option value="Jun">Jun</option>
                       <option value="Jul">Jul</option>
                       <option value="Aug">Aug</option>
                       <option value="Sep">Sep</option>
                       <option value="Oct">Oct</option>
                       <option value="Nov">Nov</option>
                       <option value="Dec">Dec</option>
                     </Field>
                 
                 <ErrorMessage
                     name="complition"
                     component="div"
                     className="invalid-feedback"
                   />      
               </FormGroup>
             </Col>
             <Col md="1">
            
             <FormGroup className="mb-3">
                 <Label htmlFor="complitionYear">
                   {this.props.t("Compl. Year")}
                 </Label>
                 <Field
                   name="complitionYear"
                   render={({ field }) => (
                     <MaskedInput
                       {...field}
                       mask={complition}
                       id="complitionYear"
                       placeholder="xxxx"
                       type="text"
                       onChange={handleChange}
                       onBlur={handleBlur}
                       className={
                         "form-control" +
                         (errors.complitionYear && touched.complitionYear
                           ? " is-invalid"
                           : "")
                       }
                     />
                   )}
                 />
                  <ErrorMessage
                     name="complitionYear"
                     component="div"
                     className="invalid-feedback"
                   />   
               </FormGroup>
            </Col>
 

 
  <Col md="1">
            
       
                 <FormGroup className="mb-3">
                                     <Label htmlFor="noOfUnits">
                                       {this.props.t("# of Units")}
                                     </Label>
                                     <Field
                                       name="noOfUnits"
                                       className="form-control"
                                       onChange={handleChange}
                                       type="text"
                                     />
                                   
                  <ErrorMessage
                     name="noOfUnits"
                     component="div"
                     className="invalid-feedback"
                   />   
               </FormGroup>
             </Col>
             <Col md="1">
            
       
            <FormGroup className="mb-3">
                                <Label htmlFor="noOfStories">
                                  {this.props.t("# Stories")}
                                </Label>
                                <Field
                                  name="noOfStories"
                                  className="form-control"
                                  onChange={handleChange}
                                  type="text"
                                />
                              
             <ErrorMessage
                name="noOfStories"
                component="div"
                className="invalid-feedback"
              />   
          </FormGroup>
        </Col>


                                </Row>
                                <Row>
              
              <Col md="4">
                <FormGroup className="mb-3">
                  <Label htmlFor="streetName">
                    {this.props.t("Project Location")}
                  </Label>
                  
                   
                  <Field name="streetName" className="form-control" component={RsPlacesAutoComplete} />
                  
                </FormGroup>
              </Col>
              <Col md="2">
              <FormGroup className="mb-3">
                                <Label htmlFor="nearestIntersection">
                                  {this.props.t("Nearest Intersection")}
                                </Label>
                                <Field
                                  name="nearestIntersection"
                                  className="form-control"
                                  onChange={handleChange}
                                  type="text"
                                />
                              
             <ErrorMessage
                name="nearestIntersection"
                component="div"
                className="invalid-feedback"
              />   
                </FormGroup>
              </Col>        
              <Col md="2">
                <FormGroup className="mb-3">
                  <Label htmlFor="projectEstOccupancy">
                    {this.props.t("Est. Occupancy")}
                  </Label>
                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.estimatedOccupancy && touched.estimatedOccupancy
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.estimatedOccupancy}
                                    onChange={(value) => setFieldValue('estimatedOccupancy', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                  
                  <ErrorMessage
                      name="estimatedOccupancy"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
              </Col>

              <Col md="2">
                <FormGroup className="mb-3">
                  <Label htmlFor="vipLaunchDate">
                    {this.props.t("VIP Launch Date")}
                  </Label>
                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.vipLaunchDate && touched.vipLaunchDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.vipLaunchDate}
                                    onChange={(value) => setFieldValue('vipLaunchDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                  
                  <ErrorMessage
                      name="vipLaunchDate"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
              </Col>

              <Col md="2">
                <FormGroup className="mb-3">
                  <Label htmlFor="projectPublicLaunch">
                    {this.props.t("Public Launch Date")}
                  </Label>
                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.publicLaunchDate && touched.publicLaunchDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.publicLaunchDate}
                                    onChange={(value) => setFieldValue('publicLaunchDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
              
                  <ErrorMessage
                      name="publicLaunchDate"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
              </Col>
</Row>              
                                <Row>

                                  <Col md="1">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="minBed">
                                        {this.props.t("Min. BR")}
                                      </Label>
                                      <Field
                                        name="minBed"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="1">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="maxBed">
                                        {this.props.t("Max. BR")}
                                      </Label>
                                      <Field
                                        name="maxBed"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="1">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="minArea">
                                        {this.props.t("Min. Sqft")}
                                      </Label>
                                      <Field
                                        name="minArea"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>

                                  </Col>
                                  <Col md="1">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="maxArea">
                                        {this.props.t("Max. Sqft")}
                                      </Label>
                                      <Field
                                        name="maxArea"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="costPerSqFt">
                                        {this.props.t("From Cost/Sq. ft")}
                                      </Label>
                                      <Field
                                        name="costPerSqFt"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="minPriceRange">
                                        {this.props.t("Min. Price")}
                                      </Label>
                                      <Field
                                        name="minPriceRange"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="maxPriceRange">
                                        {this.props.t("Max. Price")}
                                      </Label>
                                      <Field
                                        name="maxPriceRange"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>



                                </Row>

                               
                              
                                

                                <Row>
                                  <Col md="4">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="agentWebSite">
                                        {this.props.t("Project Website")}
                                      </Label>
                                      <Field
                                        name="agentWebSite"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="2">
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="commision">
                                        {this.props.t("Agent Commision %/unit")}
                                      </Label>
                                      <Field
                                        name="commision"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>

                                




                                </Row>

                                <div><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Save")}</button></div>

                              </CardText>
                            </Col>
                          </Row>
                        </TabPane>
                      </Form>
                    )}
                  </Formik>
                    ) : (
                      <Formik
                      enableReinitialize={true}
                      initialValues={{
                        projectName: (this.state && this.state.projectName) || "",
                        //complition: "",
                        //noOf:"",
                        projectType:"",
                        projectStatus:"",
                        projectBuilder:"",

                      }}
                      validationSchema={Yup.object().shape({
                        projectName: Yup.string().required("This is required"),
                       // complition: Yup.string().required("This is required"),
                      //noOf: Yup.string().required("This is required"),
                        projectType: Yup.string().required("This is required"),
                        projectStatus: Yup.string().required("This is required"),
                        projectBuilder: Yup.string().required("This is required")
                        
                        
                      })}

                      onSubmit={this.handleProjectSubmit }
                    >
                   {({ errors, touched, values, handleChange,handleBlur, setFieldValue }) => (
          <Form
            className="needs-validation"
          >
            <TabPane tabId="1">
            <Row>
              <Col lg="12">
                <h4 className="form-title"><strong>Project Overview</strong></h4>
              </Col>
            </Row>
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                  
            <Row>
              <Col md="2">
                <FormGroup className="mb-3">
                  <Label htmlFor="projectName">
                    {this.props.t("Project Name *")}
                  </Label>
                  <Field
                      name="projectName"
                      type="text"
                      onChange={handleChange}
                      className={
                        "form-control" +
                        (errors.projectName && touched.projectName
                          ? " is-invalid"
                          : "")
                      }
                  />
                  <ErrorMessage
                      name="projectName"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup className="mb-3">
                  <Label htmlFor="projectType">
                    {this.props.t("Type *")}
                  </Label>
                  <Field as="select" name="projectType" 
                   className={
                    "form-control" +
                    (errors.projectType && touched.projectType
                      ? " is-invalid"
                      : "")
                  } 
                  onChange={handleChange}>
                        <option value="">Select</option>
                        {
                        this.state.projectTypes
                          .map(projectType => 
                          <option key={projectType.id} value={projectType.id}>{projectType.projectTypeTitle}</option>
                          )
                      }    
                      </Field>
                      <ErrorMessage
                      name="projectType"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
                
              </Col>
              <Col md="2">
              <FormGroup className="mb-3">
                  <Label htmlFor="projectStatus">
                    {this.props.t("Project Status *")}
                  </Label>
                  <Field as="select" name="projectStatus" 
                   className={
                    "form-control" +
                    (errors.projectStatus && touched.projectStatus
                      ? " is-invalid"
                      : "")
                  } 
                  onChange={handleChange}>
                    <option value="">Select</option>
                        {
                        this.state.projectStatus
                          .map(projectStat => 
                          <option key={projectStat.id} value={projectStat.id}>{projectStat.projectStatusTitle}</option>
                          )
                      }   
                      </Field>
                      <ErrorMessage
                      name="projectStatus"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup className="mb-3">
                  <Label htmlFor="builderName">
                    {this.props.t("Builder Name *")}
                  </Label>
                  <Field as="select" name="projectBuilder" 
                  className={
                    "form-control" +
                    (errors.projectBuilder && touched.projectBuilder
                      ? " is-invalid"
                      : "")
                  } 
                  onChange={handleChange}>
                        <option value="">Select</option>
                        {
                        this.state.builders
                          .map(builder => 
                          <option key={builder.id} value={builder.id}>{builder.builderName}</option>
                          )
                      }   
                      </Field>
                      <ErrorMessage
                      name="projectBuilder"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
              </Col>
              <Col md="1">
             
              <FormGroup className="mb-3">
                  <Label htmlFor="complitionMonth">
                    {this.props.t("Compl. Month")}
                  </Label>
                  <Field as="select" name="complitionMonth" 
                  className={
                    "form-control" +
                    (errors.complitionMonth && touched.complitionMonth
                      ? " is-invalid"
                      : "")
                  } 
                  onChange={handleChange}>
                        <option value="">Select</option>
                        <option value="Jan">Jan</option>
                        <option value="Feb">Feb</option>
                        <option value="Mar">Mar</option>
                        <option value="Apr">Apr</option>
                        <option value="May">May</option>
                        <option value="Jun">Jun</option>
                        <option value="Jul">Jul</option>
                        <option value="Aug">Aug</option>
                        <option value="Sep">Sep</option>
                        <option value="Oct">Oct</option>
                        <option value="Nov">Nov</option>
                        <option value="Dec">Dec</option>
                      </Field>
                  
                  <ErrorMessage
                      name="complition"
                      component="div"
                      className="invalid-feedback"
                    />      
                </FormGroup>
              </Col>
              <Col md="1">
             
              <FormGroup className="mb-3">
                  <Label htmlFor="complitionYear">
                    {this.props.t("Compl. Year")}
                  </Label>
                  <Field
                    name="complitionYear"
                    render={({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={complition}
                        id="complitionYear"
                        placeholder="xxxx"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                          "form-control" +
                          (errors.complitionYear && touched.complitionYear
                            ? " is-invalid"
                            : "")
                        }
                      />
                    )}
                  />
                   <ErrorMessage
                      name="complitionYear"
                      component="div"
                      className="invalid-feedback"
                    />   
                </FormGroup>
             </Col>
  

  
   <Col md="1">
             
        
                  <FormGroup className="mb-3">
                                      <Label htmlFor="noOfUnits">
                                        {this.props.t("# of Units")}
                                      </Label>
                                      <Field
                                        name="noOfUnits"
                                        className="form-control"
                                        onChange={handleChange}
                                        type="text"
                                      />
                                    
                   <ErrorMessage
                      name="noOfUnits"
                      component="div"
                      className="invalid-feedback"
                    />   
                </FormGroup>
              </Col>
              <Col md="1">
             
        
             <FormGroup className="mb-3">
                                 <Label htmlFor="noOfStories">
                                   {this.props.t("# Stories")}
                                 </Label>
                                 <Field
                                   name="noOfStories"
                                   className="form-control"
                                   onChange={handleChange}
                                   type="text"
                                 />
                               
              <ErrorMessage
                 name="noOfStories"
                 component="div"
                 className="invalid-feedback"
               />   
           </FormGroup>
         </Col>

            </Row>
            <Row>
              
              <Col md="4">
                <FormGroup className="mb-3">
                  <Label htmlFor="streetName">
                    {this.props.t("Project Location")}
                  </Label>
                  
                   
                  <Field name="streetName" className="form-control" component={RsPlacesAutoComplete} />
                  
                </FormGroup>
              </Col>
              <Col md="2">
              <FormGroup className="mb-3">
                                <Label htmlFor="nearestIntersection">
                                  {this.props.t("Nearest Intersection")}
                                </Label>
                                <Field
                                  name="nearestIntersection"
                                  className="form-control"
                                  onChange={handleChange}
                                  type="text"
                                />
                              
             <ErrorMessage
                name="nearestIntersection"
                component="div"
                className="invalid-feedback"
              />   
                </FormGroup>
              </Col>                
              <Col md="2">
                <FormGroup className="mb-3">
                  <Label htmlFor="projectEstOccupancy">
                    {this.props.t("Est. Occupancy")}
                  </Label>
                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.estimatedOccupancy && touched.estimatedOccupancy
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    onChange={(value) => setFieldValue('estimatedOccupancy', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                  
                  <ErrorMessage
                      name="estimatedOccupancy"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
              </Col>

              <Col md="2">
                <FormGroup className="mb-3">
                  <Label htmlFor="vipLaunchDate">
                    {this.props.t("VIP Launch Date")}
                  </Label>
                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.vipLaunchDate && touched.vipLaunchDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    onChange={(value) => setFieldValue('vipLaunchDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                  
                  <ErrorMessage
                      name="vipLaunchDate"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
              </Col>

              <Col md="2">
                <FormGroup className="mb-3">
                  <Label htmlFor="projectPublicLaunch">
                    {this.props.t("Public Launch Date")}
                  </Label>
                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.publicLaunchDate && touched.publicLaunchDate
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    onChange={(value) => setFieldValue('publicLaunchDate', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
              
                  <ErrorMessage
                      name="publicLaunchDate"
                      component="div"
                      className="invalid-feedback"
                    />
                </FormGroup>
              </Col>
</Row>              
       <Row>
             
              <Col md="1">
                <FormGroup className="mb-3">
                  <Label htmlFor="minBed">
                    {this.props.t("Min. BR")}
                  </Label>
                  <Field
                      name="minBed"
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="1">
                <FormGroup className="mb-3">
                  <Label htmlFor="maxBed">
                    {this.props.t("Max. BR")}
                  </Label>
                  <Field
                      name="maxBed"
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="1">
              <FormGroup className="mb-3">
                  <Label htmlFor="minArea">
                    {this.props.t("Min. Sqft")}
                  </Label>
                  <Field
                      name="minArea"
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                  />
                </FormGroup>
               
              </Col>
              <Col md="1">
              <FormGroup className="mb-3">
                  <Label htmlFor="maxArea">
                    {this.props.t("Max. Sqft")}
                  </Label>
                  <Field
                      name="maxArea"
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                  />
                </FormGroup>
              </Col>
  
  <Col md="2">
              <FormGroup className="mb-3">
                  <Label htmlFor="costPerSqFt">
                    {this.props.t("From Cost/Sq. ft")}
                  </Label>
                  <Field
                      name="costPerSqFt"
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                  />
                  
                </FormGroup>
              </Col>
  
  <Col md="2">
              <FormGroup className="mb-3">
                  <Label htmlFor="minPriceRange">
                    {this.props.t("Min. Price")}
                  </Label>
                  <Field
                      name="minPriceRange"
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                  />
                </FormGroup>
              </Col>
  <Col md="2">
              <FormGroup className="mb-3">
                  <Label htmlFor="maxPriceRange">
                    {this.props.t("Max. Price")}
                  </Label>
                  <Field
                      name="maxPriceRange"
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                  />
                </FormGroup>
              </Col>

              
              
            </Row>
            
            
        
      

            <Row>
              <Col md="4">
              <FormGroup className="mb-3">
                  <Label htmlFor="agentWebSite">
                    {this.props.t("Project Website")}
                  </Label>
                  <Field
                      name="agentWebSite"
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                  />
                </FormGroup>
              </Col>
   <Col md="2">
              <FormGroup className="mb-3">
                  <Label htmlFor="commision">
                    {this.props.t("Agent Commision %/unit")}
                  </Label>
                  <Field
                      name="commision"
                      className="form-control"
                      onChange={handleChange}
                      type="text"
                  />
                </FormGroup>
              </Col>

              
  


              
            </Row>

            <div><button type="submit" className="btn btn-primary w-md float-end">{this.props.t("Save & Continue..")}</button></div>
          
                  </CardText>
                </Col>
              </Row>
            </TabPane>
            </Form>
        )}
                     </Formik>
                    )}
                    </TabContent>
                      
                     
                  </CardBody>
                </Card>
              
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsCreate.propTypes = {
  t:PropTypes.any,
  history: PropTypes.object,
  apiError: PropTypes.any,
  addProject:PropTypes.func,
  address:PropTypes.any,
  ongetProjectUUIDAction: PropTypes.func,
  match: PropTypes.object,
  projectData: PropTypes.object,
  onUpdateProject: PropTypes.func
 
}
const mapStateToProps = ({ Project }) => (
  {
    projectData: Project.projectData

  })
const mapDispatchToProps = dispatch => ({
  ongetProjectUUIDAction: (id) => dispatch(getProjectUUIDAction(id)),
  addProject: (data,history) => dispatch(addProject(data,history)),
  onUpdateProject: (data) => dispatch(projectUpdate(data)),
 
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ProjectsCreate)))