import {
  GET_PROJECT_TYPE_SUCCESS,
  GET_PROJECT_TYPE_FAIL,
  ADD_PROJECT,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_ERROR,
  GET_ALL_PROJECT_SUCCESS,
  GET_ALL_PROJECT_FAIL,
  GET_PROJECT_CONTACT_SUCCESS,
  GET_PROJECT_CONTACT_FAIL,
  GET_PROJECT_MEDIA_SUCCESS,
  GET_PROJECT_MARKET_SUCCESS,
  GET_PROJECT_MARKET_ERROR,
  GET_ALL_PROJECT_UUID_SUCCESS,
  GET_ALL_PROJECT_UUID_FAIL,
  GET_PROJECT_FEATURE_SUCCESS,
  GET_PROJECT_FEATURE_ERROR,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  UPDATE_PROJECT_CONTACT_SUCCESS,
  UPDATE_PROJECT_CONTACT_FAIL,
  UPDATE_PROJECT_FEATURE_SUCCESS,
  UPDATE_PROJECT_FEATURE_ERROR,
  DELETE_PROJECT_CONTACT_SUCCESS,
  DELETE_PROJECT_CONTACT_FAIL,
  UPDATE_PROJECT_MARKET_SUCCESS,
  UPDATE_PROJECT_MARKET_ERROR,
  DELETE_PROJECT_MARKET_SUCCESS,
  DELETE_PROJECT_MARKET_FAIL,
  DELETE_PROJECT_LINK_SUCCESS,
  DELETE_PROJECT_LINK_FAIL,
  GET_PROJECT_LINK_SUCCESS,
  GET_PROJECT_LINK_ERROR,
  GET_PROJECT_GALLERY_SUCCESS,
  GET_PROJECT_GALLERY_ERROR,
  DELETE_PROJECT_GALLERY_SUCCESS,
  DELETE_PROJECT_GALLERY_FAIL,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  projectType: [],
  projects: [],
  contacts: [],
  documents: [],
  markets: [],
  projectData: {},
  projectFeature: {},
  links: [],
  images:[]

}

const Project = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROJECT:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADD_PROJECT_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case ADD_PROJECT_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case GET_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        projectType: action.payload,
      }

    case GET_PROJECT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ALL_PROJECT_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case GET_ALL_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: action.payload,
      }

    case GET_PROJECT_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_MEDIA_SUCCESS:
      return {
        ...state,
        documents: action.payload,
      }

    case GET_PROJECT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_MARKET_SUCCESS:
      return {
        ...state,
        markets: action.payload,
      }

    case GET_PROJECT_MARKET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_ALL_PROJECT_UUID_SUCCESS:
      return {
        ...state,
        projectData: action.payload,
      }

    case GET_ALL_PROJECT_UUID_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PROJECT_FEATURE_SUCCESS:
      return {
        ...state,
        projectFeature: action.payload,
      }

    case GET_PROJECT_FEATURE_ERROR:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        project: action.payload,
      }

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_PROJECT_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_PROJECT_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case UPDATE_PROJECT_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map(contact =>
          contact.id.toString() === action.payload.id.toString()
            ? { contact, ...action.payload }
            : contact
        ),
      }

    case UPDATE_PROJECT_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PROJECT_CONTACT_SUCCESS:
      return {
        ...state,
        contact: action.payload,
      }

    case DELETE_PROJECT_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_PROJECT_FEATURE_SUCCESS:

      state = {
        ...state,
        loading: false,
      }
      break
    case UPDATE_PROJECT_FEATURE_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case UPDATE_PROJECT_MARKET_SUCCESS:
      return {
        ...state,
        markets: state.markets.map(market =>
          market.id.toString() === action.payload.id.toString()
            ? { market, ...action.payload }
            : market
        ),
      }

    case UPDATE_PROJECT_MARKET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PROJECT_MARKET_SUCCESS:
      return {
        ...state,
        market: action.payload,
      }

    case DELETE_PROJECT_MARKET_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_LINK_SUCCESS:
      return {
        ...state,
        links: action.payload,
      }

    case GET_PROJECT_LINK_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PROJECT_LINK_SUCCESS:
      return {
        ...state,
        link: action.payload,
      }
    case DELETE_PROJECT_LINK_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROJECT_GALLERY_SUCCESS:
      return {
        ...state,
        images: action.payload,
      }

    case GET_PROJECT_GALLERY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PROJECT_GALLERY_SUCCESS:
      return {
        ...state,
        image: action.payload,
      }
    case DELETE_PROJECT_GALLERY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      state = { ...state }
      break
  }


  return state
}

export default Project
