import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}




// Login Method
const postLoginAPI = data => post(url.POST_LOGIN_API_URL, data)


export const postLeadData = data => post(url.POST_LEAD_API_URL, data)
export const getLeadDetail = leadId => get(`${url.GET_LEAD_DETAIL_API_URL}${leadId}`)


export const postLeadNote = data => post(url.POST_LEAD_NOTE_API, data)

export const getNoteLeadApi = BrokerLeadId => get(`${url.GET_LEAD_NOTE_API}/`, { params: { BrokerLeadId } })

export const postLeadFollow = data => post(url.POST_LEAD_FOLLOW_API, data)

export const getFollowLeadApi = BrokerLeadId => get(`${url.GET_LEAD_FOLLOW_API}/`, { params: { BrokerLeadId } })
export const postTransferLeadApi = data => post(url.POST_LEAD_TRANSFER_API_URL, data)





export const postBuilder = data => post(url.POST_BUILDER, data)
export const getBuilderdata = agentId => get(`${url.GET_BUILDER_API_URL}/`, { params: { agentId } })
export const getBuilderDetail = id => get(`${url.GET_BUILDER_DETAIL}${id}`)

export const postProject = data => post(url.POST_PROJECT, data)
export const getProjectByUID = id => get(`${url.GET_PROJECT_UUID_URL}${id}`)
//export const getProjectByUID = id => get(`${url.GET_PROJECT_UUID_URL}/${id}`, { params: { id } })
export const getProject =  brokerId => get(`${url.GET_PROJECT_API_URL}/`, { params: { brokerId } })
export const deleteProject = id =>   del(`${url.DELETE_PROJECT_API_URL}/${id}`)
export const updateProject = data => put(url.UPDATE_PROJECT_API_URL, data)


export const postProjectFeatApi = data => post(url.POST_PROJECT_FEATURE_API_URL, data)
export const getProjectFeatApi = BrokerProjectId => get(`${url.GET_PROJECT_FEATURE_API_URL}/`, { params: { BrokerProjectId } })
export const updateProjectFeatApi = data => put(url.UPDATE_PROJECT_FEATURE_API_URL, data)

export const postProjectContactApi = data => post(url.POST_PROJECT_CONTACT_API_URL, data)
export const getProjectContactApi = BrokerProjectId => get(`${url.GET_PROJECT_CONTACT_API_URL}/`, { params: { BrokerProjectId } })
export const updateProjectContactAPI = contact => put(url.UPDATE_PROJECT_CONTACT_API_URL, contact)
export const deleteProjectContactAPI = id =>   del(`${url.DELETE_PROJECT_CONTACT_API_URL}/${id}`)

export const postProjectDocumentApi = data => post(url.POST_PROJECT_DOCUMENT_API_URL, data)
export const getProjectDocumentApi = BrokerProjectId => get(`${url.GET_PROJECT_DOCUMENT_API_URL}/`, { params: { BrokerProjectId } })
export const deleteProjectDocumentAPI = id =>   del(`${url.DELETE_PROJECT_DOCUMENT_API_URL}/${id}`)


export const postProjectMarketApi            = data => post(url.POST_PROJECT_MARKET_API_URL, data)
export const getProjectMarketApi      = BrokerProjectId => get(`${url.GET_PROJECT_MARKET_API_URL}/`, { params: { BrokerProjectId } })
export const updateProjectMarketApi   = market => put(url.UPDATE_PROJECT_MARKET_API_URL, market)
export const deleteProjectMarketApi   = id =>   del(`${url.DELETE_PROJECT_MARKET_API_URL}/${id}`)

export const postProjectLinkApi           = data => post(url.POST_PROJECT_LINK_API_URL, data)
export const getProjectLinkApi            = BrokerProjectId => get(`${url.GET_PROJECT_LINK_API_URL}/`, { params: { BrokerProjectId } })
export const deleteProjectLinkApi         = id =>   del(`${url.DELETE_PROJECT_LINK_API_URL}/${id}`)


export const postProjectGalleryApi           = data => post(url.POST_PROJECT_GALLERY_API_URL, data)
export const getProjectGalleryApi            = BrokerProjectId => get(`${url.GET_PROJECT_GALLERY_API_URL}/`, { params: { BrokerProjectId } })
export const deleteProjectGalleryApi         = id =>   del(`${url.DELETE_PROJECT_GALLERY_API_URL}/${id}`)


export const postBrokerAgentData = data => post(url.POST_BROKER_AGENT_API_URL, data)
export const getBrokerAgentData = agentId => get(`${url.GET_BROKER_AGENT_API_URL}${agentId}`)

export const postBrokerEventData = data => post(url.POST_BROKER_EVENT_API_URL, data)
export const getBrokerEventData =  brokerId => get(`${url.GET_BROKER_EVENT_API_URL}/`, { params: { brokerId } })

export const postBrokerMessageData = data => post(url.POST_BROKER_MESSAGE_API_URL, data)
export const getBrokerMessageData =  BrokerId => get(`${url.GET_BROKER_MESSAGE_API_URL}/`, { params: { BrokerId } })

export const postBrokerResourseData = data => post(url.POST_BROKER_RESOURSE_API_URL, data)
export const getBBrokerResourseData = BrokerId => get(`${url.GET_BROKER_RESOURSE_API_URL}/`, { params: { BrokerId } })

export {
  getLoggedInUser,
  isUserAuthenticated,
  postLoginAPI
}
