import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { isEmpty, map } from "lodash"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DeleteModal from "../../../components/Common/DeleteModal";
import { addProjectMedia, getProjectMedia, deleteProjectDocument } from "../../../store/projects/actions"
import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


class ProjectsMedia extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: '',
      selectedImage: '',
      fname: ''
      
    }
    this.onFileChange = this.onFileChange.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.handleProjectDocumentSubmit = this.handleProjectDocumentSubmit.bind(this)
  }
  onFileChange(e) {
    let files = e.target.files;
    let fileNames = files[0];
    let fname = fileNames['name'];
    
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    
    fileReader.onload = (event) => {
      
      this.setState({
        selectedImage: event.target.result,
       }),
       this.setState({
        fname: fname,
       })
       

    }
  }
  handleProjectDocumentSubmit(value){

    this.props.addProjectMedia(value, this.props.history);

  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (document) => {
    this.setState({ document: document });
    this.setState({ deleteModal: true });
  };

  handleDeleteMedia = () => {
    const { onDeleteMedia } = this.props;
    const { document } = this.state;
    //console.log(document);
    if (document.id !== undefined) {
      onDeleteMedia(document);
      this.setState({ deleteModal: false });
    }
  };
  componentDidMount() {
    const { match: { params }, onGetProjectMedia }= this.props;
    if (params && params.id &&  params.projectId) {
      onGetProjectMedia(params.id)
    }else{
      this.props.history.push('/my-project')
    }
  }
  render() {
    const { documents } = this.props;
    const { deleteModal } = this.state;
      return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteMedia}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <ToastContainer autoClose={2000} />
        <div className="page-content">

          <MetaTags>
            <title>Create New Project | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Projects")} breadcrumbItem={this.props.t("Project Media")} />
            <Nav tabs className="projectTab">
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          <span className="d-none d-sm-block">{this.props.t("Description")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link className="nav-link" to={"/pre-construction/create-project/feature/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                        >
                          
                          <span className="d-none d-sm-block">{this.props.t("Features")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/contact/" + this.props.match.params.id + "/"+  this.props.match.params.projectId }
                         
                         >
                          
                          <span className="d-none d-sm-block">{this.props.t("Contact")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                    <Link className="nav-link active" to={"/pre-construction/create-project/media/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Document")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                      </NavItem>
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/link/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Links")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> 
                      <NavItem>
                      <Link className="nav-link" to={"/pre-construction/create-project/gallery/" + this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                           
                           <span className="d-none d-sm-block">{this.props.t("Gallery")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                      {/* <NavItem>
                      <Link to="/pre-construction/create-project/payment"
                         
                         >
                         
                          <span className="d-none d-sm-block">{this.props.t("Payment Plan")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem> */}
                      <NavItem>
                      <Link  className="nav-link" to={"/pre-construction/create-project/marketing/"+ this.props.match.params.id + "/"+  this.props.match.params.projectId}
                         
                         >
                  
                          <span className="d-none d-sm-block">{this.props.t("Marketing")}</span>
                          <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                        </Link>
                        
                      </NavItem>
                    </Nav>
            <Card>
                  <CardBody>
                  

                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                    <Formik
                  enableReinitialize={true}
                  initialValues={{
                    projectDocumentTitle: (this.state && this.state.projectDocumentTitle) || "",
                    projectDocumentTypeId: '',
                    projectId: this.props.match.params.id,
                    agentId: localStorage.getItem('userId'),
                    projectDocumentContent: this.state.selectedImage,
                    projectDocumentLink: (this.state && this.state.fname) || "",

                  }}
                  validationSchema={Yup.object().shape({
                    projectDocumentTitle: Yup.string().max(50, "Title must not exceed 50").required("This is Required")
                  })}

                  onSubmit={this.handleProjectDocumentSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
              <Card> 
                    <CardBody>
                    <h5 className="card-title"></h5>
              
                   <Row>
                        <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectDocumentTitle">
                              {this.props.t("Title")}
                            </Label>
                            <Field
                              name="projectDocumentTitle"
                              onChange={handleChange}                            
                              type="text"
                              
                              className={
                                "form-control" +
                                (errors.projectDocumentTitle && touched.projectDocumentTitle
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectDocumentTitle"
                            />
                             <ErrorMessage
                              name="projectDocumentTitle"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectDocumentTypeId">
                              {this.props.t("Type")}
                            </Label>
                            <Field as="select" name="projectDocumentTypeId" className="form-control" onChange={handleChange}>
                                <option value="0">Select</option>
                                <option value="1">Fyler</option>
                                <option value="2">Broucher</option>
                                <option value="3">ETC</option>
                            </Field>
                          </FormGroup>
                        </Col>
                        <Col sm="5">
                        <div className="form-group mb-3">
                          <Label htmlFor="file">File upload</Label>
                          <input id="file" name="file" type="file" onChange={this.onFileChange} className="form-control" />
                          
                        </div>
                       
                        </Col>

                        {/* <Col sm="3">
                        <FormGroup className="mb-3">
                            <Label htmlFor="projectDocumentLink">
                              {this.props.t("Link")}
                            </Label>
                            <Field
                              name="projectDocumentLink"
                              onChange={handleChange}                             
                              type="text"
                              className={
                                "form-control" +
                                (errors.projectDocumentLink && touched.projectDocumentLink
                                  ? " is-invalid"
                                  : "")
                              }
                             
                              id="projectDocumentLink"
                            />
                          </FormGroup>
                        </Col> */}
                        <Col sm="2">
                        <div className="clearfix"><button type="submit" className="btn btn-primary w-md float-end mt-27">Upload Document</button></div>
                        </Col>
                    </Row>

                
             
             </CardBody>
                </Card>
                
            </Form>
                  )}
			              </Formik>

     
                      </TabContent>
                      </CardBody>
                      </Card>
                      <Row>
				<Col sm="12">
					 <Card> 
                    <CardBody>
                    <h5 className="card-title">Document History</h5>
					<div className="table-responsive">
						  <table className="table mb-0 table">
							<thead>
							  <tr>
								
								
								<th>Type</th>
								<th>Title</th>
                <th>Link</th>
                <th></th> 
							  </tr>
							</thead>
							<tbody>
              {map(documents, (document, dockey) => (
               <tr key={"_doc_" + dockey}>
                
                <td>{document.projectDocumentTypeId}</td>
                <td>{document.projectDocumentTitle}</td>
                
                <td><a target="_blank" rel="noreferrer" href={"http://realtyspace-001-site2.itempurl.com/" + document.projectDocumentUrl}>View</a></td>
                <td><Link to="#" onClick={() => this.onClickDelete(document)} className="btn-delete waves-effect waves-light btn-sm">Delete</Link></td>
               </tr>
              ))}
							</tbody>
						  </table>
						</div>
					</CardBody>
					</Card>
				</Col>
			</Row>         
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsMedia.propTypes = {
  t:PropTypes.any,
  history:PropTypes.object,
  addProjectMedia:PropTypes.func,
  documents:PropTypes.array,
  onGetProjectMedia:PropTypes.func,
  match:PropTypes.object,
  onDeleteMedia:PropTypes.func

 
}

const mapStateToProps = ({ Project }) => (
{
  documents: Project.documents
})

const mapDispatchToProps = dispatch => ({
  addProjectMedia: (data) => dispatch(addProjectMedia(data)),
  onGetProjectMedia:(projectId) => dispatch(getProjectMedia(projectId)),
  onDeleteMedia: (id) => dispatch(deleteProjectDocument(id))

})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(ProjectsMedia)))