import { call, put, takeEvery } from "redux-saga/effects"
import { toast } from "react-toastify";
// Crypto Redux States
import {
  ADD_LEAD_NOTE,
  CREATE_LEAD,  GET_LEAD_DETAIL, GET_LEAD_FOLLOW, GET_LEAD_NOTE, POST_LEAD_FOLLOW, POST_LEAD_TRANSFER
} from "./actionTypes"

import { postLeadData, getLeadDetail, postLeadNote, getNoteLeadApi, postLeadFollow, getFollowLeadApi, postTransferLeadApi } from '../../helpers/backend_helper'

import {  addLeadFollowTabFail, addLeadFollowTabSuccess, addLeadNoteTabFail, addLeadNoteTabSuccess, createLeadDataFail, createLeadDataSuccess, getFollowLeadTabFail, getFollowLeadTabSuccess, getLeadDetailFail, getLeadDetailSuccess, getNoteLeadTabFail, getNoteLeadTabSuccess, postLeadTransferDataFail, postLeadTransferDataSuccess} from "./actions"


function* submitNewLead({ payload: { lead, history } }) {

  try {
    toast.loading("Please wait...")
    const response = yield call(postLeadData, {
      agentId: 0,
      firstName: lead.firstName,
      lastName: lead.lastName,
      leadStatusId: parseInt(lead.leadStatus),
      leadTypeId: parseInt(lead.leadType),
      leadSourceId: parseInt(lead.leadSourceId),
      email: lead.email,
      phone: lead.phone,
      mobile: lead.mobile,
      preferedMedium: parseInt(lead.preferedMedium),
      referedBy: 0,
      group:  0,
      brokerId: parseInt(localStorage.getItem("userId")),
      maritalStatus: parseInt(lead.maritalStatus),
      lastContact: new Date(),
      winLiklyhood: lead.winLiklyhood,
      approxDealValue: 0,
      notes: ''
    })
    yield put(createLeadDataSuccess(response))
    if(response.success==true){
    history.push("/lead/dashboard")
    }else{
      toast.dismiss();
      toast.warn(response.validationErrors[0]);
    }
  }
  catch (error) {

    yield put(createLeadDataFail(error))
  }
}
function* fetchLeadDetail({ leadId }) {
  try {
    const response = yield call(getLeadDetail, leadId)
    yield put(getLeadDetailSuccess(response))
  } catch (error) {
    yield put(getLeadDetailFail(error))
  }
}


function* addNoteLead({ payload: { notes, history } }) {
  try {
    toast.loading("Please wait...")
    console.log(notes);
    const response = yield call(postLeadNote, {
      refId: parseInt(notes.refId),
      remarks: notes.addNote,
      addedBy: parseInt(notes.addedBy),
      addedDate: new Date()

    })
    yield put(addLeadNoteTabSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Notes Added");
      const response = yield call(getNoteLeadApi,notes.refId)
      yield put(getNoteLeadTabSuccess(response))
     
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addLeadNoteTabFail('Some Error'))
  }
}

function* fetchNoteLead({ BrokerLeadId }) {
  try {
    const response = yield call(getNoteLeadApi,BrokerLeadId)
    yield put(getNoteLeadTabSuccess(response))
  } catch (error) {
    yield put(getNoteLeadTabFail(error))
  }
}


function* submitFollowLead({ payload: { follows, history } }) {
  try {
    //console.log(follows);
    toast.loading("Please wait...")
    const response = yield call(postLeadFollow, {
      message: follows.addNote,
      addedBy: parseInt(localStorage.getItem("userId")),
      refId: parseInt(follows.refId),
      nextFollowupDate: follows.nextFollowupDate,
      addedDate: new Date()

    })
    
    yield put(addLeadFollowTabSuccess(response))
    if (response.success == true) {
      toast.dismiss();
      toast.success("Next Follow-up Added");
      const response = yield call(getFollowLeadApi,follows.refId)
    yield put(getFollowLeadTabSuccess(response))
    } else {
      toast.warn("Some Error. Please try after some time");
    }
  }
  catch (error) {

    yield put(addLeadFollowTabFail())
  }
}

function* fetchFollowLead({ BrokerLeadId }) {
  try {
    const response = yield call(getFollowLeadApi,BrokerLeadId)
    yield put(getFollowLeadTabSuccess(response))
  } catch (error) {
    yield put(getFollowLeadTabFail(error))
  }
}

function* submitTransferLead({ payload: { lead, history } }) {
  try {
    toast.loading("Please wait...")
    const response = yield call(postTransferLeadApi, {
      agentId: parseInt(lead.agentId),
      brokerId: parseInt(lead.brokerId),
      id:parseInt(lead.id),
      leadId:lead.leadId,
      isTransfered: true
    })
    yield put(postLeadTransferDataSuccess(response))
  }
  catch (error) {

    yield put(postLeadTransferDataFail('Some Error'))
  }
}

function* leadSaga() {
  yield takeEvery(CREATE_LEAD, submitNewLead)
  yield takeEvery(GET_LEAD_DETAIL, fetchLeadDetail)
  yield takeEvery(ADD_LEAD_NOTE, addNoteLead)
  yield takeEvery(GET_LEAD_NOTE, fetchNoteLead)
  yield takeEvery(POST_LEAD_FOLLOW, submitFollowLead)
  yield takeEvery(GET_LEAD_FOLLOW, fetchFollowLead)
  yield takeEvery(POST_LEAD_TRANSFER, submitTransferLead)
 
}


export default leadSaga
