import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import * as moment from 'moment';
import { withTranslation } from "react-i18next"
import { getAllProjectAction, deleteProject } from "../../../store/projects/actions"
import DeleteModal from "../../../components/Common/DeleteModal";
import {
  Col,
  Container,
  Row,
  Table,
   Card,
  CardBody,
  Label,
  Input,
    FormGroup,
} from "reactstrap"
import {
 
  Form,
 
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map } from "lodash"

//Import Image
import images from "assets/images"





class ProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      project:'',
	  reports: [
        { title: "Active projects", iconClass: "bx-copy-alt", description: "14" },
        {
          title: "Email sent",
          iconClass: "bx-archive-in",
          description: "6",
        },
        
      ],
      modal: false,
    }

    this.onClickDelete = this.onClickDelete.bind(this);

  }

  
  

  componentDidMount() { 
    const { onGetAllProject } = this.props;
    
    onGetAllProject(localStorage.getItem('userId'));
  }
  toggleDeleteModal = () => {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  };
  onClickDelete = (project) => {
    this.setState({ project: project });
    this.setState({ deleteModal: true });
  };
 
  handleDeleteProject = () => {
    const { onDeleteProject } = this.props;
    const { project } = this.state;
    //console.log(project);
    if (project.id !== undefined) {
      onDeleteProject(project.id);
      this.setState({ deleteModal: false });
    }
  };

  render() {
    const { projects } = this.props;
    const {  deleteModal } = this.state;
    return (
      <React.Fragment>
         <DeleteModal
          show={deleteModal}
          onDeleteClick={this.handleDeleteProject}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <MetaTags>
            <title>Projects | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={this.props.t("Projects")}
              breadcrumbItem={this.props.t("Projects")}
            />
           <Row className="mb20">
		   	
	
             <Col lg="12">
               <div className="clearfix">
               <Link to="/pre-construction/create-project" className="btn btn-add float-end"><i className="bx bx-plus"></i> {this.props.t("Add New Project")}</Link>
             </div>
             </Col>
           </Row>
		   <Row>
              
              <Col xl="12">
              
                <Row>
            {this.state.reports.map((report, key) => (
            <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                
              </Col>
            ))}
            </Row>
           
           
            </Col>
            </Row>
            
            <Row>
              <Col lg="12">
                <div className="">
                <Form className="row gy-2 gx-3 float-end">
         
         <div className="col-sm-auto">
           <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
           <Input type="text" className="form-control" id="autoSizingInput" placeholder="Completion Year" />
         </div>

         <div className="col-sm-auto">
           <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
           <Input type="text" className="form-control" id="autoSizingInput" placeholder="Name of City" />
         </div>

         <div className="col-sm-auto">
           <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
           <Input type="text" className="form-control" id="autoSizingInput" placeholder="Starting Price From" />
         </div>
        
         <div className="col-sm-auto">
                        <label className="visually-hidden" htmlFor="autoSizingSelect">Status</label>
                        <select defaultValue="0" className="form-select">
                          <option value="0">Status...</option>
                          <option value="1">Open</option>
                          <option value="2"> Closed</option>
                          <option value="3"> Upcoming</option>
                        </select>
                      </div>
         
         <div className="col-sm-auto">
           <button type="submit" className="btn btn-primary w-md">Search</button>
         </div>
       </Form>
       <div className="clearfix"></div>
                  <div className="table-responsive">
                    <Table className="project-list-table table-nowrap align-middle table-borderless">
                      <thead>
                        <tr>
                          
                          <th scope="col">{this.props.t("Project Name")}</th>
                          
                          <th scope="col">{this.props.t("#SRR")}</th>
						              <th scope="col">{this.props.t("#Clients")}</th>
                         
                          <th scope="col">{this.props.t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                      {map(projects, (project, prokey) => (
                          <tr key={"_tr_" + prokey}>
                            
                            <td>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={"/pre-construction/project-detail/" + project.projectId }
                                  className="text-dark"
                                >
                                    {project.projectName}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">
                              {project.city}
                              </p>
                            </td>

                           
                            <td>
                              50
                           
                             
                            </td>
                            <td>60</td>
 							             
                            <td>
                          <Link to={"/pre-construction/create-project/" + project.id +"/" + project.projectId } className="btn-primary waves-effect waves-light btn-sm">Detail</Link> <Link to="#" onClick={() => this.onClickDelete(project)} className="btn-delete waves-effect waves-light btn-sm">Delete</Link>
                 
                            </td>
                          </tr>
  ))}
                          
                      
                      </tbody>
                    </Table>
                    
                  </div>
                </div>
              </Col>
            </Row>

    
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ProjectsList.propTypes = {
  
  className: PropTypes.any,
  t: PropTypes.any,
  projects:PropTypes.array,
  onGetAllProject:PropTypes.func,
  onDeleteProject: PropTypes.func
}

const mapStateToProps = ({ Project }) => ({
  projects:Project.projects
})

const mapDispatchToProps = dispatch => ({
  onGetAllProject:(agentId) => dispatch(getAllProjectAction(agentId)),
  onDeleteProject: (id) => dispatch(deleteProject(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ProjectsList)))