
export const POST_LOGIN_API_URL = "https://rscoreapi.azurewebsites.net/api/user/login"

//Lead
export const POST_LEAD_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerLead"
export const GET_LEAD_DETAIL_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerLead/id/"

export const POST_LEAD_NOTE_API         = "https://rscoreapi.azurewebsites.net/api/BrokerLeadActivities/BrokerLeadAddNote"
export const GET_LEAD_NOTE_API          = "https://rscoreapi.azurewebsites.net/api/BrokerLeadActivities/allbrokerleadaddnotelogactivities"
export const POST_LEAD_FOLLOW_API       = "https://rscoreapi.azurewebsites.net/api/BrokerLeadActivities/BrokerLeadNextFollowup"
export const GET_LEAD_FOLLOW_API        = "https://rscoreapi.azurewebsites.net/api/BrokerLeadActivities/allbrokerleadnextfollowuplogactivities"
export const POST_LEAD_TRANSFER_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerLead/TransferBrokerLead"

//Builder
export const POST_BUILDER = "https://rscoreapi.azurewebsites.net/api/BuilderExt"
export const GET_BUILDER_API_URL = "https://rscoreapi.azurewebsites.net/api/BuilderExt/allforagent"
export const GET_BUILDER_DETAIL = "https://rscoreapi.azurewebsites.net/api/BuilderExt/id/"

//Project
export const POST_PROJECT = "https://rscoreapi.azurewebsites.net/api/BrokerProjectExt"
export const GET_PROJECT_UUID_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProject/id/"
export const GET_PROJECT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectExt/allforagent"
export const POST_PROJECT_FEATURE_API_URL ="https://rscoreapi.azurewebsites.net/api/BrokerProjectFeature"
export const GET_PROJECT_FEATURE_API_URL ="https://rscoreapi.azurewebsites.net/api/BrokerProjectFeature/all"

export const POST_PROJECT_CONTACT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectContact"
export const GET_PROJECT_CONTACT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectContactExt/all"
export const UPDATE_PROJECT_CONTACT_API_URL ="https://rscoreapi.azurewebsites.net/api/BrokerProjectContact"
export const DELETE_PROJECT_CONTACT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectContact/"


export const POST_PROJECT_DOCUMENT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectDocument"
export const GET_PROJECT_DOCUMENT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectDocument/allBrokerProject"
export const DELETE_PROJECT_DOCUMENT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectDocument/"

export const POST_PROJECT_MARKET_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectMarketing"
export const GET_PROJECT_MARKET_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectMarketing/all"
export const UPDATE_PROJECT_MARKET_API_URL ="https://rscoreapi.azurewebsites.net/api/BrokerProjectMarketing"
export const DELETE_PROJECT_MARKET_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectMarketing/"


export const DELETE_PROJECT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProject/"
export const UPDATE_PROJECT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProject"

export const UPDATE_PROJECT_FEATURE_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectFeature"


export const POST_PROJECT_LINK_API_URL      = "https://rscoreapi.azurewebsites.net/api/BrokerProjectLink"
export const GET_PROJECT_LINK_API_URL       = "https://rscoreapi.azurewebsites.net/api/BrokerProjectLink/all"
export const DELETE_PROJECT_LINK_API_URL    = "https://rscoreapi.azurewebsites.net/api/BrokerProjectLink"

export const POST_PROJECT_GALLERY_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectGallery"
export const GET_PROJECT_GALLERY_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectGallery/allBrokerProject"
export const DELETE_PROJECT_GALLERY_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerProjectGallery/"

//Broker Agent

export const POST_BROKER_AGENT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerAgentExt"
export const GET_BROKER_AGENT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerAgentExt/id/"


//Event

export const POST_BROKER_EVENT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerEvent"
export const GET_BROKER_EVENT_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerEvent/all"


export const POST_BROKER_MESSAGE_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerMessage"
export const GET_BROKER_MESSAGE_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerMessage/allBroker"

export const POST_BROKER_RESOURSE_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerResource"
export const GET_BROKER_RESOURSE_API_URL = "https://rscoreapi.azurewebsites.net/api/BrokerResource/allBroker"
