import {
  ADD_LEAD_NOTE,
  ADD_LEAD_NOTE_FAIL,
  ADD_LEAD_NOTE_SUCCESS,
  CREATE_LEAD,
  CREATE_LEAD_FAIL,
  CREATE_LEAD_SUCCESS,
  GET_LEADS,
  GET_LEADS_ERROR,
  GET_LEADS_SUCCESS,
  GET_LEAD_DETAIL,
  GET_LEAD_DETAIL_FAIL,
  GET_LEAD_DETAIL_SUCCESS,
  GET_LEAD_FOLLOW,
  GET_LEAD_FOLLOW_FAIL,
  GET_LEAD_FOLLOW_SUCCESS,
  GET_LEAD_NOTE,
  GET_LEAD_NOTE_FAIL,
  GET_LEAD_NOTE_SUCCESS,
  POST_LEAD_FOLLOW,
  POST_LEAD_FOLLOW_FAIL,
  POST_LEAD_FOLLOW_SUCCESS,
  POST_LEAD_TRANSFER,
  POST_LEAD_TRANSFER_FAIL,
  POST_LEAD_TRANSFER_SUCCESS,
 
} from "./actionTypes"

export const getLeads = brokerId => ({
  type: GET_LEADS,
  brokerId
})

export const getLeadsSuccess = leads => ({
  type: GET_LEADS_SUCCESS,
  payload: leads,
})

export const getLeadsFail = error => ({
  type: GET_LEADS_ERROR,
  payload: error,
})

export const createLeadData = (lead, history) => {
  return {
    type: CREATE_LEAD,
    payload: { lead, history },
  }
}


export const createLeadDataSuccess = lead => ({
  type: CREATE_LEAD_SUCCESS,
  payload: lead,
})

export const createLeadDataFail = error => ({
  type: CREATE_LEAD_FAIL,
  payload: error,
})

export const getLeadDetail = leadId => ({
  type: GET_LEAD_DETAIL,
  leadId,
})

export const getLeadDetailSuccess = leads => ({
  type: GET_LEAD_DETAIL_SUCCESS,
  payload: leads,
})

export const getLeadDetailFail = error => ({
  type: GET_LEAD_DETAIL_FAIL,
  payload: error,
})



export const addLeadNoteTab = (notes, history) => {
  return {
    type: ADD_LEAD_NOTE,
    payload: { notes, history },
  }
}


export const addLeadNoteTabSuccess = notes => ({
  type: ADD_LEAD_NOTE_SUCCESS,
  payload: notes,
})

export const addLeadNoteTabFail = error => ({
  type: ADD_LEAD_NOTE_FAIL,
  payload: error,
})

export const getNoteLeadTab = BrokerLeadId => (
  {
    type: GET_LEAD_NOTE,
    BrokerLeadId,
  })

export const getNoteLeadTabSuccess = leadNotes => ({
  type: GET_LEAD_NOTE_SUCCESS,
  payload: leadNotes,
})

export const getNoteLeadTabFail = error => ({
  type: GET_LEAD_NOTE_FAIL,
  payload: error,
})



export const addFollowNoteTab = (follows, history) => {
  return {
    type: POST_LEAD_FOLLOW,
    payload: { follows, history },
  }
}


export const addLeadFollowTabSuccess = follows => ({
  type: POST_LEAD_FOLLOW_SUCCESS,
  payload: follows,
})

export const addLeadFollowTabFail = error => ({
  type: POST_LEAD_FOLLOW_FAIL,
  payload: error,
})

export const getFollowLeadTab = BrokerLeadId => (
  {
    type: GET_LEAD_FOLLOW,
    BrokerLeadId,
  })

export const getFollowLeadTabSuccess = leadFollows => ({
  type: GET_LEAD_FOLLOW_SUCCESS,
  payload: leadFollows,
})

export const getFollowLeadTabFail = error => ({
  type: GET_LEAD_FOLLOW_FAIL,
  payload: error,
})

export const postLeadTransferData = (lead, history) => {
  return {
    type: POST_LEAD_TRANSFER,
    payload: { lead, history },
  }
}


export const postLeadTransferDataSuccess = leads => ({
  type: POST_LEAD_TRANSFER_SUCCESS,
  payload: leads,
})

export const postLeadTransferDataFail = error => ({
  type: POST_LEAD_TRANSFER_FAIL,
  payload: error,
})