import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
import builderSaga from "./builder/saga"
import projectSaga from "./projects/saga"
import leadSaga from "./leads/saga"
import agentSaga from "./agent/saga"
import eventSaga from "./event/saga"
import messageSaga from "./message/saga"
import resourceSaga from "./resource/saga"
export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(builderSaga),
    fork(projectSaga),
    fork(leadSaga),
    fork(agentSaga),
    fork(eventSaga),
    fork(messageSaga),
    fork(resourceSaga)
  ])
}
