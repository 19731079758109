import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
// Login Redux States
import { ADD_BUILDER, ADD_BUILDER_DEPART, GET_BUILDER, GET_BUILDER_DETAIL } from "./actionTypes"
import { addSuccess,  builderapiError, getBuildersSuccess, getBuildersFail,getBuilderDetailFail, addDepartSuccess, apiDepartError, getBuilderDetailSuccess } from "./actions"

import { postBuilder, getBuilderdata, getBuilderDetail} from "../../helpers/backend_helper"

function* addBuilders({ payload: { builder, history } }) {
  try {
    
    //console.log(builder);
      const response = yield call(postBuilder, {
            builderName: builder.builderName,
            builderLogo: builder.builderLogo,
            fullAddress: builder.address,
            addressLine2: '',
            bAddressId: 0,
            city:'',
            province:0,
            postal:'',
            country:0,
            bIsActive:true,
            agentId:parseInt(builder.agentId),
            builderLogoImage:null,
            builderLogoImageContent:builder.builderLogoImageContent,
      })
        yield put(addSuccess(response))
        history.push("/pre-construction/builder")
    }
catch (error) {

  yield put(builderapiError('Some Error'))
}
}

function* fetchBuilders({agentId}) {
  try {
    const response = yield call(getBuilderdata,agentId)
    yield put(getBuildersSuccess(response))
    //console.warn(JSON.stringify(response));
  } catch (error) {
    yield put(getBuildersFail(error))
  }
}

function* fetchBuilderDetail({ builderId }) {
  try {
    const response = yield call(getBuilderDetail, builderId)
    //console.log(response);
    yield put(getBuilderDetailSuccess(response))
  } catch (error) {
    yield put(getBuilderDetailFail(error))
  }
}


function* addDeparts({ payload: { depart, history } }) {
  console.log(depart)
  try {
    toast.loading("Please wait...")
      const response = yield call(postDepart, {
        builderId: depart.builderId,
        bContactDepartment: depart.bContactDepartment,
        phone: depart.phone,
        phoneExt: depart.phoneext,
        email: depart.email,
        mobile: depart.mobile,
        fax: depart.fax
           
      })
    yield put(addDepartSuccess(response))
       
    }
catch (error) {
  yield put(apiDepartError('Some Error'))
}
}




function* builderSaga() {
  yield takeEvery(ADD_BUILDER, addBuilders)
  yield takeEvery(GET_BUILDER, fetchBuilders)
  yield takeEvery(GET_BUILDER_DETAIL, fetchBuilderDetail)
  yield takeEvery(ADD_BUILDER_DEPART, addDeparts)
}

export default builderSaga
