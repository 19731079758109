import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
} from "reactstrap"

class agentDashoard extends Component {
  constructor(props) {
    super(props)
    this.state = {

      agents:[]
      
    }
  }
  componentDidMount() {
    //console.log(localStorage.getItem("authUser"));
    const obj = JSON.parse(localStorage.getItem("authUser"));
    //console.log(obj.resultData.bearerToken);
    const token = obj.resultData.bearerToken;
    
    //console.log(token);
    const str = 'Bearer '+token;
   
    axios.get('https://rscoreapi.azurewebsites.net/api/BrokerAgentExt/allforbroker',{ params: {
      brokerId: parseInt(localStorage.getItem("userId"))
    }, headers: { Authorization: str } })
      .then(res => {
        const agents = res.data;
        this.setState({ agents });
      })
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Broker Agent | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Broker")}
              breadcrumbItem={this.props.t("Agent")}
            />
           
            <Row>
            <Col lg="12">
            <Card>
        <CardBody>
        <CardTitle className="mb-4 float-start"></CardTitle>
        <div className="row">
          <div className="col-sm-4"><Link to="/create-agent"><button type="button" className="btn btn-add btn-md">Add Agent</button></Link></div>

       
        <div className="col-sm-8">
        <Form className="row gy-2 gx-3 float-end mb15">
         
         <div className="col-sm-auto">
           <Label className="visually-hidden" htmlFor="autoSizingInput"></Label>
           <Input type="text" className="form-control" id="autoSizingInput" placeholder="Search By Name, Phone, Email" />
         </div>

        
         
         <div className="col-sm-auto">
           <button type="submit" className="btn btn-primary w-md">Search</button>
         </div>
        
       </Form>
       </div>
       </div>         
        
                    <div className="clearfix"></div>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                <th className="align-middle">Login Id</th>
                  <th className="align-middle">Name</th>
                
                  

                 
                  <th className="align-middle">View Details</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.agents
                  .map(agent => 
                  <tr  key={"_tr_" + agent.key}>
                    
                    <td>{agent.primaryEmail}</td>
                    <td>{agent.firstName + " "+ agent.lastName}</td>
                    
                    
                    
                   
                    <td>
                    <Link to={"/view-agent/" + agent.agentId} className="btn-primary waves-effect waves-light btn-sm">View Details</Link>
                    </td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
            </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
agentDashoard.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(agentDashoard))
