import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { isEmpty, map } from "lodash";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import 'react-rangeslider/lib/index.css'
import notes from "../../assets/images/rsicons/notes.png"
import follow from "../../assets/images/rsicons/follow-up.png"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import { getLeadDetail, addLeadNoteTab,  getNoteLeadTab, addFollowNoteTab, getFollowLeadTab  } from "../../store/leads/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Collapse,
  FormGroup,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import profileImg from "../../assets/images/profile-img.png"
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from "axios"
class leadDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      col9: false,
      col15: false,
      col10: false,
      col11: false,
      col12: true,
      selectedGroup: null,
      activeTab: "1",
      verticalActiveTab: "1",
      volume: 0,
      sources: [],
      leadStatus: [],
      leadTypes: [],
      groupDrops: [],
      maritalStat: [],
      prefMediums: [],
      likelihoods: [],
      
    }

    this.t_col9 = this.t_col9.bind(this)
    this.t_col10 = this.t_col10.bind(this)
    this.t_col11 = this.t_col11.bind(this)
    this.t_col12 = this.t_col12.bind(this)
    this.t_col15 = this.t_col15.bind(this)
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
    this.handleLeadNoteSubmit = this.handleLeadNoteSubmit.bind(this)
    this.handleLeadFollowSubmit = this.handleLeadFollowSubmit.bind(this)
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
  }
  toggleRightCanvas() {
    this.setState({ isRight: !this.state.isRight });
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  handleLeadNoteSubmit(value) {
    //console.log(value);
    this.props.addLeadNoteTab(value, this.props.history)
   
   }
   handleLeadFollowSubmit(value) {
    this.props.addFollowNoteTab(value, this.props.history)
    
}
  handleOnChange = (value) => {
    this.setState({
      volume: value
    })
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }
  t_col9() {
    this.setState({
      col9: !this.state.col9,
      col10: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col10() {
    this.setState({
      col10: !this.state.col10,
      col9: false,
      col11: false,
      col12: false,
      col15: false
    })
  }

  t_col11() {
    this.setState({
      col11: !this.state.col11,
      col9: false,
      col10: false,
      col12: false,
      col15: false
    })
  }
  t_col12() {
    this.setState({
      col12: !this.state.col12,
      col11: false,
      col9: false,
      col10: false,
      col15: false
    })
  }
  t_col15() {
    this.setState({
      col15: !this.state.col15,
      col11: false,
      col9: false,
      col10: false,
      col12: false
    })
  }
 
  componentDidMount() {
    const { match: { params }, onGetLeadDetail, onGetNoteLead, onGetFollowLead} = this.props;
    
    if (params &&  params.id  && params.leadId) {
      onGetLeadDetail(params.leadId);
      onGetNoteLead(params.id);
      onGetFollowLead(params.id);
      
    }
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;

    //console.log(token);
    const str = 'Bearer ' + token;
    axios.get('https://rscoreapi.azurewebsites.net/api/LeadSource/all', { headers: { Authorization: str } })
      .then(res => {
        const sources = res.data;
        this.setState({ sources });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/LeadStatus/all', { headers: { Authorization: str } })
        .then(res => {

          const leadStatus = res.data;
          this.setState({ leadStatus });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/LeadType/all', { headers: { Authorization: str } })
        .then(res => {
          const leadTypes = res.data;
          this.setState({ leadTypes });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Lead:Group',

        }, headers: { Authorization: str }
      })
        .then(res => {
          const groupDrops = res.data;
          this.setState({ groupDrops });

        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Lead:Marital Status',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const maritalStat = res.data;
          this.setState({ maritalStat });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Lead:Win Liklyhood',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const likelihoods = res.data;
          this.setState({ likelihoods });
        }),
      axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Lead:Preferred Medium',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const prefMediums = res.data;
          this.setState({ prefMediums });
        })
  }
  render() {
    
    const { leadData, leadNotes, leadFollows } = this.props;
   
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Lead Detail | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Lead Detail")}
            />
            <Row>
            <Col xl="4">
           
              <Card>
                <CardBody>
                <h4 className="card-title mb-1">Lead Details  <Link onClick={this.toggleRightCanvas} className="float-end" to="#">Edit</Link></h4>
                {!isEmpty(leadData) && (
                <div className="table-responsive">
                      <Table className="table mb-0">
                        
                        <tbody>
                        <tr>
                            <th scope="row">Marital status</th>
                            <td> {leadData.maritalStatus} </td>
                           
                          </tr>
                          <tr>
                            <th scope="row">First Name</th>
                            <td> {leadData.firstName} </td>
                           
                          </tr>
                          <tr>
                            <th scope="row">Last Name</th>
                            <td> {leadData.lastName} </td>
                           
                          </tr>
                          <tr>
                            <th scope="row"> Email</th>
                            <td>   {leadData.email}   </td>
                           
                          </tr>
                          
                          <tr>
                            <th scope="row"> Mobile</th>
                            <td>  {leadData.mobile}  </td>
                           
                          </tr>
                          <tr>
                            <th scope="row"> Phone</th>
                            <td>  {leadData.phone} </td>
                           
                          </tr>
                          <tr>
                            <th scope="row"> Date submitted</th>
                            <td>  </td>
                           
                          </tr>
                          <tr>
                            <th scope="row">Source of lead</th>
                            <td>{leadData.leadSourceId}</td>
                           
                          </tr>
                          <tr>
                            <th scope="row">Preferred medium</th>
                            <td>{leadData.preferedMedium}</td>
                           
                          </tr>
                          <tr>
                            <th scope="row">Type</th>
                            <td> {leadData.leadTypeId} </td>
                           
                          </tr>
                          <tr>
                            <th scope="row">Status</th>
                            <td>{leadData.leadStatusId} </td>
                           
                          </tr>
                         
                          
                         
                        </tbody>
                    </Table>
                    </div>
                )}
                </CardBody>

              </Card>
               
              </Col>
              <Col xl="8">
              <Card>
                  <CardBody>
                  <Nav tabs className="leadTab">
                  <NavItem>
                        {/* <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={notes} /><br />{this.props.t("Transfer")}</span>
                          <span className="d-block d-sm-none"><img src={notes} /></span>
                        </NavLink> */}
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "1",
                          })}
                          onClick={() => {
                            this.toggle("1")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={notes} /><br />{this.props.t("Notes")}</span>
                          <span className="d-block d-sm-none"><img src={notes} /></span>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTab === "4",
                          })}
                          onClick={() => {
                            this.toggle("4")
                          }}
                        >
                          
                          <span className="d-none d-sm-block"><img src={follow} /><br />{this.props.t("Follow-ups")}</span>
                          <span className="d-block d-sm-none"><img src={follow} /></span>
                        </NavLink>
                      </NavItem>

                      
            
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className="p-3 text-muted">
                    
                    <TabPane tabId="3">
                     <div className="mb-5 h4 card-title">Transfer</div>
                     
                        
                     </TabPane>
                     <TabPane tabId="1">
                     <div className="h4 card-title bt5">Notes</div>
                     <Formik
                        enableReinitialize={true}
                        initialValues={{
                          addNote: (this.state && this.state.addNote) || "",
                          addedDate: new Date(),
                          refId: this.props.match.params.id,
                          addedBy: localStorage.getItem('userId'),
                          createdFor:this.props.match.params.id
                       
                        
                        }}
                        validationSchema={Yup.object().shape({
                            addNote: Yup.string().required("This is Required")
                        })}

                        onSubmit={this.handleLeadNoteSubmit}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                          
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="addNote">
                                          {this.props.t("Add Note")}
                                        </Label>
                                        <textarea
                                          name="addNote"
                                          onChange={handleChange}
                                          type="textarea"
                                          className={
                                            "form-control" +
                                            (errors.addNote && touched.addNote
                                              ? " is-invalid"
                                              : "")
                                          }
                                          id="addNote"
                                        />
                                         <ErrorMessage
                                              name="addNote"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <div className="clearfix"><button type="submit"  className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                                  </Form>
                        )}
                      </Formik>
                        <div className="bt5 tt5 h4 card-title">Notes History</div>
                        <ul className="verti-timeline list-unstyled">

                          {map(leadNotes, (noteLog, notekey) => (

                            <li className="event-list eventlist" key={"_note_" + notekey}>
                              <div className="event-timeline-dot">
                                <i className="bx bx-right-arrow-circle font-size-18" />
                              </div>
                              <div className="d-flex">
                                <div className="flex-shrink-0 me-3">
                                  <h5 className="font-size-14">
                                    <Moment format="D MMM YY">{noteLog.addedDate}</Moment>


                                  </h5>
                                </div>
                                <div className="flex-grow-1">
                                  <h5 className="font-size-14"></h5>
                                </div>
                              </div>
                              <div className="exNote">{noteLog.activityRemarks}</div>
                            </li>
                          ))}

                          </ul>
                     </TabPane>

                     <TabPane tabId="4">
                     <div className="h4 card-title bt5">Set Next Follow-up</div>
                     <Formik
                    enableReinitialize={true}
                    initialValues={{
                        addNote: (this.state && this.state.addNote) || "",
                        nextFollowupDate: new Date(),
                        refId: this.props.match.params.id,
                        addedBy: localStorage.getItem('userId'),
                        createdFor:this.props.match.params.id

                    }}
                    validationSchema={Yup.object().shape({
                        addNote: Yup.string().required("This is Required")
                    })}

                    onSubmit={this.handleLeadFollowSubmit}
                >
                    {({ errors, touched, values, handleChange, setFieldValue }) => (
                        <Form
                            className="needs-validation"
                        >

                            <Row>
                                <Col md="12">
                                    <FormGroup className="mb-3">
                                        
                                        <textarea
                                            name="addNote"
                                            onChange={handleChange}
                                            type="textarea"
                                            className={
                                                "form-control" +
                                                (errors.addNote && touched.addNote
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            id="addNote"
                                        />
                                        <ErrorMessage
                                            name="addNote"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="12">
                                    <FormGroup className="mb15">

                                        <Label htmlFor="logDate">
                                            {this.props.t("Date")}
                                        </Label>
                                        <Flatpickr
                                            className={
                                                "form-control" +
                                                (errors.nextFollowupDate && touched.nextFollowupDate
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            placeholder=""
                                            value={values.nextFollowupDate}
                                            onChange={(value) => setFieldValue('nextFollowupDate', value[0])}
                                            options={{
                                                altInput: true,
                                                altFormat: "F j, Y",
                                                dateFormat: "Y-m-d"
                                            }}
                                        />
                                        <ErrorMessage
                                            name="logDate"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </FormGroup>

                                </Col>
                            </Row>

                            <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                        </Form>
                    )}
                </Formik>
                      
                     <div className="h4 card-title bt5">Next Follow-up History:</div>
                     <ul className="verti-timeline list-unstyled">

                        {map(leadFollows, (noteLog, notekey) => (

                        <li className="event-list eventlist" key={"_note_" + notekey}>
                            <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle font-size-18" />
                            </div>
                            <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                                <h5 className="font-size-14">
                                <Moment format="D MMM YY">{noteLog.activityRefStartDate}</Moment>


                                </h5>
                            </div>
                            <div className="flex-grow-1">
                                <h5 className="font-size-14"></h5>
                            </div>
                            </div>
                            <div className="exNote">{noteLog.activityRemarks}</div>
                        </li>
                        ))}

                        </ul>        
                     </TabPane>

                    </TabContent>
                    </CardBody>
                    </Card>

              </Col>
          </Row>
          </Container>
        </div>


        <Offcanvas
                      isOpen={this.state.isRight}
                      direction="end"
                      toggle={this.toggleRightCanvas}
                    >
                      <OffcanvasHeader toggle={this.toggleRightCanvas}>
                        Update Lead
                      </OffcanvasHeader>
                      <OffcanvasBody>
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          maritalStatus: leadData.maritalStatus,
                          firstName: leadData.firstName,
                          lastName: leadData.lastName,
                          email: leadData.email,
                          phone: leadData.phone,
                          mobile: leadData.mobile,
                          preferedMedium:leadData.preferedMedium,
                          dateSubmited: new Date(),
                          leadSourceId:leadData.leadSourceId,
                          leadType:leadData.leadTypeId,
                          leadStatus: leadData.leadStatusId
                        }}
                        validationSchema={Yup.object().shape({
                          firstName: Yup.string().required(
                            "This Field in required"
                          ),
                          lastName: Yup.string().required(
                            "This Field in required"
                          ),
                          email: Yup.string().email('Invalid email').required('This Field in required'),
                          mobile: Yup.string().matches(
                            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                            "Enter valid mobile number"
                          ),
                          phone: Yup.string().matches(
                            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                            "Enter valid phone number"
                          ),
                        })}

                        onSubmit={this.handleSubmitLead}
                      >
                        {({ errors,  touched, values, handleChange, setFieldValue }) => (
                          <Form
                            className="needs-validation"
                          >
                       <Row>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="maritalStatus">
                                    {this.props.t("Marital status")}
                                  </Label>
                                  <Field as="select" name="maritalStatus" className="form-control">
                                    <option value="">Select</option>
                                    {
                                      this.state.maritalStat
                                        .map(marital =>
                                          <option key={marital.id} value={marital.id}>{marital.fieldValueName}</option>
                                        )
                                    }
                                  </Field>
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="firstName">
                                    {this.props.t("First Name")}
                                  </Label>
                                  <input
                                    name="firstName"
                                    type="text"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.firstName && touched.firstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="firstName"
                                  />
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="lastName">
                                    {this.props.t("Last Name")}
                                  </Label>
                                  <input
                                    name="lastName"
                                    type="text"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.lastName && touched.lastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="lastName"
                                  />
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="email">
                                    {this.props.t("Email")}
                                  </Label>
                                  <input
                                    name="email"
                                    type="text"
                                    value={values.email}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="email"
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="mobile">
                                    {this.props.t("Mobile")}
                                  </Label>
                                  <input
                                    name="mobile"
                                    type="text"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.mobile && touched.mobile
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="mobile"
                                  />
                                  <ErrorMessage
                                    name="mobile"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="phone">
                                    {this.props.t("Phone")}
                                  </Label>
                                  <input
                                    name="phone"
                                    type="text"
                                    value={values.phone}
                                    onChange={handleChange}
                                    className={
                                      "form-control" +
                                      (errors.phone && touched.phone
                                        ? " is-invalid"
                                        : "")
                                    }
                                    id="phone"
                                  />
                                  <ErrorMessage
                                    name="phone"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              
                             

                            

                            </Row>
                            <Row>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="dateSubmited">
                                    {this.props.t("Date submitted")}
                                  </Label>
                                  <Flatpickr
                                    className={
                                      "form-control" +
                                      (errors.dateSubmited && touched.dateSubmited
                                        ? " is-invalid"
                                        : "")
                                    }
                                    placeholder=""
                                    value={values.dateSubmited}
                                    onChange={(value) => setFieldValue('dateSubmited', value[0])}
                                    options={{
                                      altInput: true,
                                      altFormat: "F j, Y",
                                      dateFormat: "Y-m-d"
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="leadSourceId">
                                    {this.props.t("Source of lead")}
                                  </Label>
                                  <Field as="select" name="leadSourceId" className={
                                    "form-control" +
                                    (errors.leadSourceId && touched.leadSourceId
                                      ? " is-invalid"
                                      : "")
                                  }>
                                    <option value="">Select</option>
                                    {
                                      this.state.sources
                                        .map(source =>
                                          <option key={source.id} value={source.id}>{source.leadSourceTitle}</option>
                                        )
                                    }
                                  </Field>
                                  <ErrorMessage
                                    name="leadSourceId"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="preferedMedium">
                                    {this.props.t("Preferred medium")}
                                  </Label>
                                  <Field as="select" name="preferedMedium" className="form-control">
                                    <option value="">Select</option>
                                    {
                                      this.state.prefMediums
                                        .map(prefMedium =>
                                          <option key={prefMedium.id} value={prefMedium.id}>{prefMedium.fieldValueName}</option>
                                        )
                                    }
                                  </Field>
                                </FormGroup>
                              </Col>     
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="leadType">
                                    {this.props.t("Type")}
                                  </Label>
                                  <Field as="select" name="leadType" className="form-control">
                                    <option value="">Select</option>
                                    {
                                      this.state.leadTypes
                                        .map(leadType =>
                                          <option key={leadType.id} value={leadType.id}>{leadType.leadTypeTitle}</option>
                                        )
                                    }
                                  </Field>

                                </FormGroup>
                              </Col>

                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="leadStatus">
                                    {this.props.t("Status")}
                                  </Label>
                                  <Field as="select" name="leadStatus" className="form-control">
                                    <option value="">Select</option>
                                    {
                                      this.state.leadStatus
                                        .map(leadStat =>
                                          <option key={leadStat.id} value={leadStat.id}>{leadStat.leadStatusTitle}</option>
                                        )
                                    }
                                  </Field>
                                </FormGroup>
                              </Col>
                            </Row>   


                     

                      

                      <div><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>
                      
                     </Form>
                        )}
                        </Formik>

                      </OffcanvasBody>
        </Offcanvas>

      </React.Fragment>
    )
  }
}
leadDetail.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  onGetLeadDetail: PropTypes.object,
  leadData: PropTypes.object,
  history: PropTypes.object,
  addLeadNoteTab:PropTypes.func,
  onGetNoteLead: PropTypes.func,
  leadNotes:PropTypes.array,
  addFollowNoteTab: PropTypes.func,
  onGetFollowLead: PropTypes.func,
  leadFollows: PropTypes.array

}
const mapStateToProps = ({ leads }) => (
  {
    leadData: leads.leadData,
    leadNotes: leads.leadNotes,
    leadFollows: leads.leadFollows
  })
const mapDispatchToProps = dispatch => ({
  onGetLeadDetail: leadId => dispatch(getLeadDetail(leadId)),
  addLeadNoteTab: (data) => dispatch(addLeadNoteTab(data)),
  onGetNoteLead: BrokerLeadId => dispatch(getNoteLeadTab(BrokerLeadId)),
  addFollowNoteTab: (data) => dispatch(addFollowNoteTab(data)),
  onGetFollowLead: BrokerLeadId => dispatch(getFollowLeadTab(BrokerLeadId)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(leadDetail))
