import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

import {
  Row,
  Col,
  CardBody,
  Card,
  CardText,
  Container,
 
  FormGroup,
  Label,
 
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {addBrokerAgent, addBrokerAgentError } from "../../store/agent/actions"
class CreateAgent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rows: [],
      titles: [],
      address: '',
      errorMessage: '',
      latitude: null,
      longitude: null,
      isGeocoding: false,
      
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }
  handleChange = address => {
    this.setState({
      address,
      latitude: null,
      longitude: null,
      errorMessage: '',
    });
  };

  handleSelect = selected => {
    this.setState({ isGeocoding: true, address: selected });
      geocodeByAddress(selected)
      .then(res => getLatLng(res[0]))
      .then(({ lat, lng }) => {
        this.setState({
          latitude: lat,
          longitude: lng,
          isGeocoding: false,
        });
      })
      .catch(error => {
        this.setState({ isGeocoding: false });
        console.log('error', error); // eslint-disable-line no-console
      });
  };

  handleCloseClick = () => {
    this.setState({
      address: '',
      latitude: null,
      longitude: null,
    });
  };

  handleError = (status, clearSuggestions) => {
    //console.log('Error from Google Maps API', status); // eslint-disable-line no-console
    this.setState({ errorMessage: status }, () => {
      clearSuggestions();
    });
  };
  handleAddRow = () => {
    const item = {
      name: "",
    };
    this.setState({
      rows: [...this.state.rows, item],
    });
  };

  handleAddRowNested = () => {
    const item1 = {
      name1: "",
    };
    this.setState({
      rows1: [...this.state.rows1, item1],
    });
  };
  handleRemoveRow = (e, idx) => {
    if (idx === "01") {
      document.getElementById("addr" + idx).style.display = "block";
    } else if (typeof idx != "undefined") {
      document.getElementById("addr" + idx).style.display = "none";
    }

  };
  handleRemoveRowNested = (e, idx) => {
    document.getElementById("nested" + idx).style.display = "none";
  };
  componentDidMount() {
    //console.log(localStorage.getItem("authUser"));
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const token = obj.resultData.bearerToken;

    //console.log(token);
    const str = 'Bearer ' + token;
      axios.get('https://rscoreapi.azurewebsites.net/api/FieldValue/allbyname', {
        params: {
          fieldName: 'Agent:Title',
        }, headers: { Authorization: str }
      })
        .then(res => {
          const titles = res.data;
          this.setState({ titles });
        })
  }
  handleValidSubmit(value,history) {

    this.props.addBrokerAgent(value, this.props.history)
    //console.log(value);

  }
  render() {
    const {
      address,
      errorMessage,
      latitude,
      longitude,
      isGeocoding,
    } = this.state;
      return (
      <React.Fragment>
        <div className="page-content">

          <MetaTags>
            <title>Create Agent | Realty Space</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={this.props.t("Agent")} breadcrumbItem={this.props.t("Create Agent")} />

            <Row className="">
                          <Col sm="12">
                          <Formik
                  enableReinitialize={true}
                  initialValues={{
                    title:'',
                    firstName:(this.state && this.state.firstName) || "",
                    lastName:(this.state && this.state.lastName) || "",
                    primaryEmail:(this.state && this.state.primaryEmail) || "",
                    mobile:'',
                    address:this.state.address,
                    longitude:this.state.longitude,
                    latitude:this.state.latitude,
                    importantDate: [
                      {
                        name: '',
                        relation: '',
                        type:'',
                        date:''
                      },
                    ],
                    



                  }}
                  validationSchema={Yup.object().shape({
                    title: Yup.string().required("This is Required"),
                    firstName: Yup.string().required("This is Required"),
                    lastName: Yup.string().required("This is Required"),
                    primaryEmail: Yup.string().email('Enter Valid Email').required('This is required'),
                    phone: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                    mobile: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid').required("This is Required"),
                    fax: Yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/, 'Enter number is not valid'),
                  })}

                  onSubmit={this.handleValidSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                            <Card>
                              <CardBody>
                            <CardText className="mb-0">
                          <Row className="mb15">
                              <Col sm="1">
                              <FormGroup className="mb-3">
                                      <Label htmlFor="title">
                                        {this.props.t("Title *")}
                                      </Label>
                                      <Field as="select" name="title"
                                        className={
                                          "form-control" +
                                          (errors.title && touched.title
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={handleChange}>
                                        <option value="">Select</option>
                                        {
                                      this.state.titles
                                        .map(title =>
                                          <option key={title.id} value={title.id}>{title.fieldValueName}</option>
                                        )
                                    }
                                      </Field>
                                      <ErrorMessage
                                      name="title"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                    </FormGroup>

                              </Col>

                              <Col sm="3">
                              <FormGroup className="mb-3">
                                      <Label htmlFor="firstName">
                                        {this.props.t("First Name*")}
                                      </Label>
                                      <Field
                                        name="firstName"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.firstName && touched.firstName
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="firstName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                              </Col>
                              <Col sm="3">
                              <FormGroup className="mb-3">
                                      <Label htmlFor="lastName">
                                        {this.props.t("Last Name*")}
                                      </Label>
                                      <Field
                                        name="lastName"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.lastName && touched.lastName
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="lastName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                              </Col>
                              <Col sm="3">
                              <FormGroup className="mb-3">
                                      <Label htmlFor="primaryEmail">
                                        {this.props.t("Email*")}
                                      </Label>
                                      <Field
                                        name="primaryEmail"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.primaryEmail && touched.primaryEmail
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="primaryEmail"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                              </Col>
                              <Col sm="2">
                              <FormGroup className="mb-3">
                                      <Label htmlFor="mobile">
                                        {this.props.t("Mobile *")}
                                      </Label>
                                      <Field
                                        name="mobile"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.mobile && touched.mobile
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="mobile"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                              </Col>          
                              {/* <Col sm="2">
                              <FormGroup className="mb-3">
                                      <Label htmlFor="agentCode">
                                        {this.props.t("Agent Code (optional)")}
                                      </Label>
                                      <Field
                                        name="agentCode"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.agentCode && touched.agentCode
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="agentCode"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                              </Col> */}
                          </Row>
                          <Row className="mb15">
                              <Col sm="2">
                              <FormGroup className="mb-3">
                                      <Label htmlFor="phone">
                                        {this.props.t("Phone")}
                                      </Label>
                                      <Field
                                        name="phone"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.phone && touched.phone
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="phone"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                              </Col>
                              <Col sm="1">
                              <FormGroup className="mb-3">
                                      <Label htmlFor="ext">
                                        {this.props.t("Ext")}
                                      </Label>
                                      <Field
                                        name="phoneext"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.phoneext && touched.phoneext
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="phoneext"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                              </Col>
                             
                              <Col sm="2">
                              <FormGroup className="mb-3">
                                      <Label htmlFor="fax">
                                        {this.props.t("Fax")}
                                      </Label>
                                      <Field
                                        name="fax"
                                        type="text"
                                        onChange={handleChange}
                                        className={
                                          "form-control" +
                                          (errors.fax && touched.fax
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="fax"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </FormGroup>

                              </Col> 

                        <Col md="5">
                          <FormGroup className="mb-3">
                            <Label htmlFor="addressLine1">
                              {this.props.t("Location")}
                            </Label>
                            <div>
                      <PlacesAutocomplete
                        onChange={this.handleChange}
                        value={address}
                        onSelect={this.handleSelect}
                        onError={this.handleError}
                        shouldFetchSuggestions={address.length > 2}
                      >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => {
            return (
              <div className="Demo__search-bar-container">
                <div className="Demo__search-input-container">
                  <input
                    {...getInputProps({
                      placeholder: '',
                      className: 'form-control',
                    })}
                  />
                  {this.state.address.length > 0 && (
                    <button
                      className="Demo__clear-button"
                      onClick={this.handleCloseClick}
                    >
                      x
                    </button>
                  )}
                </div>
                {suggestions.length > 0 && (
                  <div className="Demo__autocomplete-container">
                    {suggestions.map(suggestion => {
                      const className = classnames('Demo__suggestion-item', {
                        'Demo__suggestion-item--active': suggestion.active,
                      });

                      return (
                        /* eslint-disable react/jsx-key */
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                        >
                          <strong>
                            {suggestion.formattedSuggestion.mainText}
                          </strong>{' '}
                          <small>
                            {suggestion.formattedSuggestion.secondaryText}
                          </small>
                        </div>
                      );
                      /* eslint-enable react/jsx-key */
                    })}
                    
                  </div>
                )}
              </div>
            );
          }}
        </PlacesAutocomplete>
        {errorMessage.length > 0 && (
          <div className="Demo__error-message">{this.state.errorMessage}</div>
        )}
      </div>
                          </FormGroup>
                        </Col>
                          </Row>


                          <Row>
                            <Col sm="12"><h5>Add importnat dates</h5></Col>
                          </Row>
                          <FieldArray name="importantDate">
            {({ insert, remove, push }) => (
              <div>
                {values.importantDate.length > 0 &&
                  values.importantDate.map((importantDat, index) => (
                    <div className="row" key={index}>
                      <div className="col-sm-3">
                        <label htmlFor={`importantDate.${index}.name`}>Description</label>
                        <Field
                          name={`importantDate.${index}.name`}
                          className="form-control"
                          type="text"
                        />
                        <ErrorMessage
                          name={`importantDate.${index}.name`}
                          component="div"
                          className="field-error"
                        />
                      </div>
                      <Col sm="2">
                            <FormGroup className="mb-3">
                            <label htmlFor={`importantDate.${index}.month`}>Month</label>
                            <Field as="select"  name={`importantDate.${index}.month`} className="form-control">
                                    <option value="">Select</option>
                                    <option value="Jan">Jan</option>
                                    <option value="Feb">Feb</option>
                                    <option value="Mar">Mar</option>
                                    <option value="Apr">Apr</option>
                                    <option value="May">May</option>
                                    <option value="Jun">Jun</option>
                                    <option value="Jul">Jul</option>
                                    <option value="Aug">Aug</option>
                                    <option value="Sep">Sep</option>
                                    <option value="Oct">Oct</option>
                                    <option value="Nov">Nov</option>
                                    <option value="Dec">Dec</option>
                                  </Field>
                        <ErrorMessage
                          name={`importantDate.${index}.month`}
                          component="div"
                          className="field-error"
                        />
                          </FormGroup>
                            </Col>
                   
                            <Col sm="2">
                            <FormGroup className="mb-3">
                            <label htmlFor={`importantDate.${index}.year`}>Year</label>
                            <Field
                          name={`importantDate.${index}.year`}
                          className="form-control"
                          type="text"
                        />
                        <ErrorMessage
                          name={`importantDate.${index}.year`}
                          component="div"
                          className="field-error"
                        />
                          </FormGroup>
                            </Col>
                            <Col sm="2">
                                
                                <button
                                 type="button"
                               className="btn btn-primary m28"
                                onClick={() => remove(index)}
                              >
                                X
                               </button>   
                              <button
                                type="button"
                                className="btn btn-primary m28"
                                onClick={() => push({ name: '', month: '', year:'' })}
                              >
                                +
                              </button>
               
                            </Col>
                                    
                    </div>
                  ))}
                
              </div>
            )}
          </FieldArray>
                         
                          <div><button type="submit" className="btn btn-add w-md">{this.props.t("Create")}</button></div>                        
                      </CardText>
                      </CardBody>
                      </Card>
                    </Form>
                  )}
                  </Formik>
                      </Col>
                      </Row>
                      
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CreateAgent.propTypes = {
  t:PropTypes.any,
  history: PropTypes.object,
  addBrokerAgent: PropTypes.func,
}

const mapStateToProps = state => {
  const { error } = ''
  return { error }
}



export default connect(mapStateToProps, { addBrokerAgent })(withRouter(withTranslation()(CreateAgent)))