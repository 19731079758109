import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash";
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Moment from 'react-moment';
import { addBrokerMessage, getBrokerMessage  } from "../../store/message/actions"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"
class ClientMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
    this.handleMessageSubmit = this.handleMessageSubmit.bind(this)
  }
  handleMessageSubmit(value){
    this.props.addBrokerMessage(value)
  }
  componentDidMount() {
    const { onGetBrokerMessage } = this.props;
    onGetBrokerMessage(localStorage.getItem('userId'));
}
  render() {
    const { messages } = this.props;
    return (
      <React.Fragment>
         <ToastContainer autoClose={2000} />
        <div className="page-content">
          <MetaTags>
            <title>Broker Message | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Messages")}
              breadcrumbItem={this.props.t("Broker Messages")}
            />
            <Row>
             
              
              <Col xl="12">
              <Card>
                  <CardBody>
              <Formik
                  enableReinitialize={true}
                  initialValues={{
                    addMessage: (this.state && this.state.addMessage) || "",
                    messageFromId: localStorage.getItem('userId'),
                    messageToId: '',
                    messageFrom: "broker",
                    messageTo: "agent",
                    agentId: localStorage.getItem('userId')


                  }}
                  validationSchema={Yup.object().shape({
                    addMessage: Yup.string().max(200, "Message Text must not exceed 200").required("This is Required")
                  })}

                  onSubmit={this.handleMessageSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >

                      <Row>
                        <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="addNote">
                              {this.props.t("Add Message")}
                            </Label>
                            <textarea
                              name="addMessage"
                              onChange={handleChange}
                              type="textarea"
                              className={
                                "form-control" +
                                (errors.addMessage && touched.addMessage
                                  ? " is-invalid"
                                  : "")
                              }
                              id="addMessage"
                            />
                            <ErrorMessage
                              name="addMessage"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="clearfix"><button type="submit" className="btn btn-primary w-md">{this.props.t("Save")}</button></div>


                    </Form>
                  )}
                </Formik>
                
                  </CardBody>
                  </Card>
			 <Row>
              <Col lg="12">
              <Card>
                      <CardBody>
                        <CardTitle className="mb-5 h4">Messages History</CardTitle>
                        <div className="">
                    <div className="container py-5">
                      <div className="main-timeline">
                      {map(messages, (message, messagekey) => (
                        <div className={ messagekey % 2 == 0 ? "timeline left": "timeline right" } key={"_note_" + messagekey}>
                          <div className="card">
                            <div className="card-body p-4">
                              <h3><Moment format="D MMM YY">{message.createdDate}</Moment></h3>
                              <p className="mb-0">{message.messageText}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                      </div>
  </div>
                    </div> 
                      </CardBody>
                    </Card>
              </Col>
            </Row>
			 
            </Col>
            
            </Row>
           
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
ClientMessage.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  addBrokerMessage: PropTypes.func,
  onGetBrokerMessage:PropTypes.func,
  messages:PropTypes.array,
}
const mapStateToProps = ({ Messages }) => (
  {
    messages: Messages.messages

  })

  const mapDispatchToProps = dispatch => ({
   
    addBrokerMessage: (data) => dispatch(addBrokerMessage(data)),
    onGetBrokerMessage:(BrokerId) => dispatch(getBrokerMessage(BrokerId))
   
  });
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ClientMessage))
