import React, { Component } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import axios from "axios"
import Moment from 'react-moment';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postLeadTransferData } from "../../store/actions"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Label,
  FormGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Input,
  Row,
  Col
} from "reactstrap";

class LatestLeads extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leads: [],
      lead:'',
      agents:[]
     
    }
    this.toggleRightCanvas = this.toggleRightCanvas.bind(this);
    this.toggleBackdrop = this.toggleBackdrop.bind(this);
    this.handleTransferSubmit = this.handleTransferSubmit.bind(this)
    
  }
  handleTransferSubmit(value){
    //console.log(value)
    this.props.submitLeadTransfer(value, this.props.history);
    
  }
  toggle() {
    this.setState(prevState => ({
      isRight: !prevState.isRight
    }));
  }
  toggleBackdrop() {
    this.setState({ isBackdrop: !this.state.isBackdrop });
  }
  

  
  componentDidMount() {
    console.log(localStorage.getItem("authUser"));
    const obj = JSON.parse(localStorage.getItem("authUser"));
    //console.log(obj.resultData.bearerToken);
    const token = obj.resultData.bearerToken;
    
    //console.log(token);
    const str = 'Bearer '+token;
   
    axios.get('https://rscoreapi.azurewebsites.net/api/BrokerLead/allforbroker',{ params: {
      brokerId: parseInt(localStorage.getItem("userId"))
    }, headers: { Authorization: str } })
      .then(res => {
        const leads = res.data;
        this.setState({ leads });
      }),
      axios.get('https://rscoreapi.azurewebsites.net/api/BrokerAgentExt/allforbroker',{ params: {
      brokerId: parseInt(localStorage.getItem("userId"))
    }, headers: { Authorization: str } })
      .then(res => {
        const agents = res.data;
        this.setState({ agents });
      })
  }
  toggleRightCanvas() {
    this.setState({ market: "", isEdit: false, isRight: !this.state.isRight });
    this.toggle();
  }
  toggleRightCanvas = arg => {
    const lead = arg;
    this.setState({
      lead: {
        id:lead.id,
        leadId: lead.leadId,
        firstName:lead.firstName,
        lastName: lead.lastName
      },
      isEdit: true,
    });
    this.toggle();
  };

  
  render() {
    const lead = this.state.lead;
    
  return (
    <React.Fragment>
      <Card>
        <CardBody>
        <CardTitle className="mb-4 float-start">Active Leads</CardTitle>
          <div className="row  float-end">
         
                      <div className="col-md-6">
                        <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
                        <Input type="text" className="form-control" id="autoSizingInput" placeholder="Search By Name, Phone, Email" />
                      </div>

                     
                     
                      <div className="col-md-3">
                        <label className="visually-hidden" htmlFor="autoSizingSelect">Preference</label>
                        <select defaultValue="0" className="form-select">
                          <option value="0">Status...</option>
                          <option value="1">No contact</option>
                          <option value="2"> Waiting for response</option>
                          <option value="3"> Ready to meet</option>
                        </select>
                      </div>
                      
                      <div className="col-md-2">
                        <button type="submit" className="btn btn-primary w-md">Search</button>
                      </div>
                    </div>
                    <div className="clearfix"></div>
          <div className="table-responsive">
            <table className="table align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Phone</th>
                  <th className="align-middle">Email</th>
                  
                  <th className="align-middle">Last Contact</th>
                  <th className="align-middle">Source</th>
                  <th className="align-middle">Status</th>
                 
                  <th className="align-middle">View Details</th>
                </tr>
              </thead>
              <tbody>
              {
                this.state.leads
                  .map(lead => 
                <tr key={"_tr_" + lead.key}>
                    <td>{lead.firstName} {lead.lastName}</td>
                    <td>{lead.mobile}</td>
                    <td>{lead.email}</td>
                    <td> <Moment format="D MMM YY">{lead.lastContact}</Moment></td>
                    <td>{lead.leadSourceId}</td>
                    <td>{lead.leadStatusId}</td>
                    <td>
                    {lead.isTransfered == false ?
                      <Link to="#" onClick={() => this.toggleRightCanvas(lead)} className="btn-primary waves-effect waves-light btn-sm">Transfer</Link> 
                    : '' 
                    }   <Link to={"/lead/lead-detail/" + lead.id + "/" + lead.leadId} className="btn-primary waves-effect waves-light btn-sm">View Details</Link> <Link to="" className="btn-black waves-effect waves-light btn-sm">Delete</Link></td>

                </tr>
                  )
              }
              </tbody>
            </table>
          </div>

      
        </CardBody>
      </Card>

      <Offcanvas
              isOpen={this.state.isRight}
              direction="end"
              toggle={this.toggleRightCanvas}
            >
              <OffcanvasHeader toggle={this.toggleRightCanvas}>
                Update Marketing
              </OffcanvasHeader>
              <OffcanvasBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    agentId:  '',
                    id:lead.id,
                    leadId:lead.leadId,
                    brokerId:localStorage.getItem('userId'),
                    isTransfered:true
                   
                  }}
                  validationSchema={Yup.object().shape({
                   
                    agentId: Yup.string().required("This Field in required"),
                  })}

                  onSubmit={this.handleTransferSubmit}
                >
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form
                      className="needs-validation"
                    >
                     
                      <Row>
                      <Col md="12">
                          <FormGroup className="mb-3">
                            <Label htmlFor="agentId">
                              Select Agent
                            </Label>
                            
                            <Field as="select" name="agentId" className={
                                          "form-control" +
                                          (errors.agentId && touched.agentId
                                            ? " is-invalid"
                                            : "")
                                        } onChange={handleChange}>
                                <option value="">Select</option>
                                {
                                this.state.agents
                                  .map(agent => 
                                    <option key={agent.id} value={agent.id}>{agent.firstName+" "+agent.lastName}</option>
                                  )}
                            </Field>
                            <ErrorMessage
                              name="agentId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="12">
                        <FormGroup className="mb-3">
                            <Label htmlFor="transferNote">
                              Note
                            </Label>
                            <textarea
                                    name="transferNote"
                                    onChange={handleChange}
                                    type="textarea"

                                    className="form-control"

                                    id="transferNote"

                                  />
                             <ErrorMessage
                              name="transferNote"
                              component="div"
                              className="invalid-feedback"
                            />
                          </FormGroup>

                        </Col>
                       

                      </Row>
                      <div><button type="submit" className="btn btn-primary w-md">Transfer Now</button></div>

                    </Form>
                  )}
                </Formik>

              </OffcanvasBody>
            </Offcanvas>
    </React.Fragment>
  )
}
}
LatestLeads.propTypes = {
  t:PropTypes.any,
  history: PropTypes.object,
  submitLeadTransfer:PropTypes.func
  
 
}

const mapStateToProps = ({ leads }) => (
  {
    

  })
const mapDispatchToProps = dispatch => ({
  submitLeadTransfer: (data) => dispatch(postLeadTransferData(data)) 
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(LatestLeads)))
