import { ADD_BROKER_AGENT, ADD_BROKER_AGENT_ERROR, ADD_BROKER_AGENT_SUCCESS, GET_BROKER_AGENT, GET_BROKER_AGENT_DETAIL, GET_BROKER_AGENT_DETAIL_FAIL, GET_BROKER_AGENT_DETAIL_SUCCESS, GET_BROKER_AGENT_FAIL, GET_BROKER_AGENT_SUCCESS } from "./actionTypes"

export const addBrokerAgent = (agent, history) => {
  return {
    type: ADD_BROKER_AGENT,
    payload: { agent, history },
  }
}

export const addBrokerAgentSuccess = agent => {
  return {
    type: ADD_BROKER_AGENT_SUCCESS,
    payload: agent,
  }
}
export const addBrokerAgentError = error => {
  return {
    type: ADD_BROKER_AGENT_ERROR,
    payload: error,
  }
}
export const getBrokerAgent = (brokerId) => ({
  type: GET_BROKER_AGENT,
  brokerId
})

export const getBrokerAgentSuccess = agents => ({
  type: GET_BROKER_AGENT_SUCCESS,
  payload: agents,
})
export const getBrokerAgentFail = error => ({
  type: GET_BROKER_AGENT_FAIL,
  payload: error,
})
export const getBrokerAgentDetail = agentId => ({
  type: GET_BROKER_AGENT_DETAIL,
  agentId,
})
export const getBrokerAgentDetailSuccess = agentData => ({
  type: GET_BROKER_AGENT_DETAIL_SUCCESS,
  payload: agentData,
})
export const getBrokerAgentDetailFail = error => ({
  type: GET_BROKER_AGENT_DETAIL_FAIL,
  payload: error,
})


