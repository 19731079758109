import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"
import { toast } from "react-toastify";
import {
   postLoginAPI
} from "../../../helpers/backend_helper"


function* loginUser({ payload: { user, history } }) {
  try {
    //toast.dismiss();
      toast.loading("Please wait...")
      const response = yield call(postLoginAPI, {
        userName: user.userName,
        password: user.userPassword,
      })
       
        const data = JSON.stringify(response)
        const dparse = JSON.parse(data);
        localStorage.setItem("token",dparse.resultData.bearerToken);
        localStorage.setItem("userId",dparse.resultData.userId);
        localStorage.setItem("authUser", data)
        yield put(loginSuccess(response))  
        history.push("/dashboard")
    }
catch (error) {
  toast.dismiss();
  if(error.response.status==401){
    yield put(apiError('Invalid User'))
  }
  
}
}
function* logoutUser({ payload: { history } }) {
  try {
   
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    history.push("/login")
  } catch (error) {
    yield put(apiError('some error'))
  }
}



function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
