import React from "react"
import { Redirect } from "react-router-dom"


// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import DashboardNew from "../pages/Dashboard/index"
import OurResource from "../pages/Dashboard/Resource"



//Project

import Events from "../pages/Events/index"
import agentDashoard from "../pages/Agent/index"
import CreateAgent from "../pages/Agent/create"
import ViewAgent from "../pages/Agent/view-detail"
import ClientMessage from "../pages/Messages/client-message"


//Builder

import Dashboard from "../pages/Lead/index"
import LeadDetail from "../pages/Lead/Lead-detail"
import CreateLead from "../pages/Lead/lead-create"
import FormGen from "../pages/Lead/Form-gen"

import Project from "../pages/Pre-Construction/Projects"
import ProjectsCreate from "../pages/Pre-Construction/Projects/projects-create"
import ProjectsFeature from "../pages/Pre-Construction/Projects/projects-feature"
import ProjectsContact from "../pages/Pre-Construction/Projects/projects-contact"
import ProjectsMedia from "../pages/Pre-Construction/Projects/projects-media"
import ProjectsGallery from "../pages/Pre-Construction/Projects/projects-gallery"
import ProjectLink from "../pages/Pre-Construction/Projects/projects-link"
import ProjectsMarketing from "../pages/Pre-Construction/Projects/projects-marketing"


import Builder from "../pages/Pre-Construction/Builder"
import BuilderDetail from "../pages/Pre-Construction/Builder/Builder-detail"
import BuilderCreate from "../pages/Pre-Construction/Builder/Builder-create"



const authProtectedRoutes = [
 { path: "/dashboard", component: DashboardNew },
 { path: "/agent", component: agentDashoard },

 { path: "/view-agent/:agentId", component: ViewAgent },

 { path: "/create-agent", component: CreateAgent },
 { path: "/message", component: ClientMessage },
 { path: "/resource", component: OurResource },
 { path: "/events", component: Events },

 { path: "/lead/dashboard", component: Dashboard },
 { path: "/lead/lead-detail/:id/:leadId", component: LeadDetail },
 { path: "/lead/lead-create", component: CreateLead },
 { path: "/lead/form-create", component: FormGen },


 { path: "/pre-Construction/create-project/feature/:id/:projectId", component: ProjectsFeature },
 { path: "/pre-Construction/create-project/contact/:id/:projectId", component: ProjectsContact },
 { path: "/pre-Construction/create-project/media/:id/:projectId", component: ProjectsMedia },
 { path: "/pre-Construction/create-project/link/:id/:projectId", component: ProjectLink },
 { path: "/pre-Construction/create-project/gallery/:id/:projectId", component: ProjectsGallery },
 { path: "/pre-Construction/create-project/marketing/:id/:projectId", component: ProjectsMarketing },
 { path: "/Pre-Construction/create-project/:id/:projectId", component: ProjectsCreate },


 { path: "/pre-construction/builder/create", component: BuilderCreate },
 { path: "/Pre-Construction/my-project", component: Project },
 { path: "/Pre-Construction/create-project", component: ProjectsCreate },
  
 { path: "/pre-construction/builder/builder-detail/:id/:builderId", component: BuilderDetail },
 { path: "/pre-construction/builder", component: Builder },


  //Contatcs

  
 


  
  //ClientDashbord
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
]

const publicRoutes = [
  
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd }
]

export { authProtectedRoutes, publicRoutes }
