import React, { Component } from "react"
import ChartistGraph from "react-chartist"

class barchart extends Component {
  render() {
    const barChartData = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      series: [
        [5, 10, 15, 20, 25, 30, 40, 45, 50, 55, 60, 65],
        [3, 2, 9, 5, 4, 6, 4, 6, 7, 8, 7, 4]
      ]
    }
    const barChartOptions = {
      low: 0,
      showArea: true,
      seriesBarDistance: 10
    }

    return (
      <React.Fragment>
        <ChartistGraph
          style={{ height: "300px" }}
          data={barChartData}
          options={barChartOptions}
          type={"Bar"}
        />
      </React.Fragment>
    )
  }
}

export default barchart
