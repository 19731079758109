import React, { Component } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
import axios from "axios"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { createLeadDataFail, createLeadData } from "../../store/actions"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactFormBuilder } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';
import {
    Row,
    Col,
    CardBody,
    Card,
    CardText,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
    Label,
    Input,
    Button
  } from "reactstrap"
  import classnames from "classnames"
  
  //Import Breadcrumb
  import Breadcrumbs from "../../components/Common/Breadcrumb"
  class FormGen extends Component {
    constructor(props) {
      super(props)
      this.state = {
       
      }
  
     
  
    }  
    render() {
  
      return (
        <React.Fragment>
          <div className="page-content">
  
            <MetaTags>
              <title>Create Lead | Realty Space</title>
            </MetaTags>
            <Container fluid>
              {/* Render Breadcrumbs */}
              <Breadcrumbs title={this.props.t("Lead")} breadcrumbItem={this.props.t("Add New Lead")} />
              <ToastContainer autoClose={2000} />
              <ReactFormBuilder />
  
            </Container>
          </div>
        </React.Fragment>
      )
    }
  }

  FormGen.propTypes = {

    t: PropTypes.any
  }
  
  
  
  
  
  export default connect()(withRouter(withTranslation()(FormGen)))