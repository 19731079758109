export const ADD_PROJECT = "ADD_PROJECT"
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR"

export const GET_ALL_PROJECT = "GET_ALL_PROJECT"
export const GET_ALL_PROJECT_SUCCESS = "GET_ALL_PROJECT_SUCCESS"
export const GET_ALL_PROJECT_FAIL = "GET_ALL_PROJECT_FAIL"


export const DELETE_PROJECT = "DELETE_PROJECT"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL"

export const UPDATE_PROJECT = "UPDATE_PROJECT"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL"





export const GET_PROJECT_TYPE = "GET_PROJECT_TYPE"
export const GET_PROJECT_TYPE_SUCCESS = "GET_PROJECT_TYPE_SUCCESS"
export const GET_PROJECT_TYPE_FAIL = "GET_PROJECT_TYPE_FAIL"




export const ADD_PROJECT_FEATURE = "ADD_PROJECT_FEATURE"
export const ADD_PROJECT_FEATURE_SUCCESS = "ADD_PROJECT_FEATURE_SUCCESS"
export const ADD_PROJECT_FEATURE_ERROR = "ADD_PROJECT_FEATURE_ERROR"


export const GET_PROJECT_FEATURE = "GET_PROJECT_FEATURE"
export const GET_PROJECT_FEATURE_SUCCESS = "GET_PROJECT_FEATURE_SUCCESS"
export const GET_PROJECT_FEATURE_ERROR = "GET_PROJECT_FEATURE_ERROR"

export const UPDATE_PROJECT_FEATURE = "UPDATE_PROJECT_FEATURE"
export const UPDATE_PROJECT_FEATURE_SUCCESS = "UPDATE_PROJECT_FEATURE_SUCCESS"
export const UPDATE_PROJECT_FEATURE_ERROR = "UPDATE_PROJECT_FEATURE"


export const ADD_PROJECT_CONTACT = "ADD_PROJECT_CONTACT"
export const ADD_PROJECT_CONTACT_SUCCESS = "ADD_PROJECT_CONTACT_SUCCESS"
export const ADD_PROJECT_CONTACT_ERROR = "ADD_PROJECT_CONTACT_ERROR"

export const GET_PROJECT_CONTACT = "GET_PROJECT_CONTACT"
export const GET_PROJECT_CONTACT_SUCCESS = "GET_PROJECT_CONTACT_SUCCESS"
export const GET_PROJECT_CONTACT_FAIL = "GET_PROJECT_CONTACT_FAIL"

export const UPDATE_PROJECT_CONTACT = "UPDATE_PROJECT_CONTACT"
export const UPDATE_PROJECT_CONTACT_SUCCESS = "UPDATE_PROJECT_CONTACT_SUCCESS"
export const UPDATE_PROJECT_CONTACT_FAIL = "UPDATE_PROJECT_CONTACT_FAIL"

export const DELETE_PROJECT_CONTACT = "DELETE_PROJECT_CONTACT"
export const DELETE_PROJECT_CONTACT_SUCCESS = "DELETE_PROJECT_CONTACT_SUCCESS"
export const DELETE_PROJECT_CONTACT_FAIL = "DELETE_PROJECT_CONTACT_FAIL"





export const ADD_PROJECT_MEDIA = "ADD_PROJECT_MEDIA"
export const ADD_PROJECT_MEDIA_SUCCESS = "ADD_PROJECT_MEDIA_SUCCESS"
export const ADD_PROJECT_MEDIA_ERROR = "ADD_PROJECT_MEDIA_ERROR"


export const GET_PROJECT_MEDIA = "GET_PROJECT_MEDIA"
export const GET_PROJECT_MEDIA_SUCCESS = "GET_PROJECT_MEDIA_SUCCESS"
export const GET_PROJECT_MEDIA_ERROR = "GET_PROJECT_MEDIA_ERROR"



export const DELETE_PROJECT_MEDIA = "DELETE_PROJECT_MEDIA"
export const DELETE_PROJECT_MEDIA_SUCCESS = "DELETE_PROJECT_MEDIA_SUCCESS"
export const DELETE_PROJECT_MEDIA_FAIL = "DELETE_PROJECT_MEDIA_FAIL"



export const ADD_PROJECT_LINK = "ADD_PROJECT_LINK"
export const ADD_PROJECT_LINK_SUCCESS = "ADD_PROJECT_LINK_SUCCESS"
export const ADD_PROJECT_LINK_ERROR = "ADD_PROJECT_LINK_ERROR"


export const GET_PROJECT_LINK = "GET_PROJECT_LINK"
export const GET_PROJECT_LINK_SUCCESS = "GET_PROJECT_LINK_SUCCESS"
export const GET_PROJECT_LINK_ERROR = "GET_PROJECT_LINK_ERROR"



export const DELETE_PROJECT_LINK = "DELETE_PROJECT_LINK"
export const DELETE_PROJECT_LINK_SUCCESS = "DELETE_PROJECT_LINK_SUCCESS"
export const DELETE_PROJECT_LINK_FAIL = "DELETE_PROJECT_LINK_FAIL"


export const ADD_PROJECT_GALLERY = "ADD_PROJECT_GALLERY"
export const ADD_PROJECT_GALLERY_SUCCESS = "ADD_PROJECT_GALLERY_SUCCESS"
export const ADD_PROJECT_GALLERY_ERROR = "ADD_PROJECT_GALLERY_ERROR"


export const GET_PROJECT_GALLERY = "GET_PROJECT_GALLERY"
export const GET_PROJECT_GALLERY_SUCCESS = "GET_PROJECT_GALLERY_SUCCESS"
export const GET_PROJECT_GALLERY_ERROR = "GET_PROJECT_GALLERY_ERROR"



export const DELETE_PROJECT_GALLERY = "DELETE_PROJECT_LINK"
export const DELETE_PROJECT_GALLERY_SUCCESS = "DELETE_PROJECT_LINK_SUCCESS"
export const DELETE_PROJECT_GALLERY_FAIL = "DELETE_PROJECT_LINK_FAIL"



export const ADD_PROJECT_MARKET = "ADD_PROJECT_MARKET"
export const ADD_PROJECT_MARKET_SUCCESS = "ADD_PROJECT_MARKET_SUCCESS"
export const ADD_PROJECT_MARKET_ERROR = "ADD_PROJECT_MARKET_ERROR"

export const GET_PROJECT_MARKET = "GET_PROJECT_MARKET"
export const GET_PROJECT_MARKET_SUCCESS = "GET_PROJECT_MARKET_SUCCESS"
export const GET_PROJECT_MARKET_ERROR = "GET_PROJECT_MARKET_ERROR"

export const UPDATE_PROJECT_MARKET = "UPDATE_PROJECT_MARKET"
export const UPDATE_PROJECT_MARKET_SUCCESS = "UPDATE_PROJECT_MARKET_SUCCESS"
export const UPDATE_PROJECT_MARKET_ERROR = "UPDATE_PROJECT_MARKET_ERROR"

export const DELETE_PROJECT_MARKET = "DELETE_PROJECT_MARKET"
export const DELETE_PROJECT_MARKET_SUCCESS = "DELETE_PROJECT_MARKET_SUCCESS"
export const DELETE_PROJECT_MARKET_FAIL = "DELETE_PROJECT_MARKET_FAIL"


export const GET_ALL_PROJECT_UUID = "GET_ALL_PROJECT_UUID"
export const GET_ALL_PROJECT_UUID_SUCCESS = "GET_ALL_PROJECT_UUID_SUCCESS"
export const GET_ALL_PROJECT_UUID_FAIL = "GET_ALL_PROJECT_UUID_FAIL"






