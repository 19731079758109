import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
//import Lead from "./Lead";
import WelcomeComp from "./WelcomeComp"
import {
  Container,
  Row,
  Col,
} from "reactstrap"

import LatestLeads from "./LatestLeads"
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        { title: "Won vs lost %", iconClass: "bx-copy-alt", description: "5" },
       
        {
          title: "Hot leads",
          iconClass: "bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Total Leads",
          iconClass: "bx-purchase-tag-alt",
          description: "20",
        },
        {
          title: "Pipeline value",
          iconClass: "bx-purchase-tag-alt",
          description: "20",
        },
      ]
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Lead Dashboard | Realty Space</title>
          </MetaTags>
          <Container fluid>
             <Breadcrumbs
              title={this.props.t("Lead")}
              breadcrumbItem={this.props.t("Lead Dashboard")}
            />
            <Row>
            <Col xl="4">
               <WelcomeComp />
              </Col>
              
            </Row>
            <Row>
            <Col lg="12">
              <LatestLeads />
            </Col>
          </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
Dashboard.propTypes = {
  t: PropTypes.any

}
export default connect()(withTranslation()(Dashboard))
