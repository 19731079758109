import { takeEvery, put, call } from "redux-saga/effects"
import { toast } from "react-toastify";
// Login Redux States
import { ADD_BROKER_AGENT, GET_BROKER_AGENT_DETAIL } from "./actionTypes"
import { addBrokerAgentError, addBrokerAgentSuccess, getBrokerAgentDetailFail, getBrokerAgentDetailSuccess } from "./actions"

import { getBrokerAgentData, postBrokerAgentData } from "../../helpers/backend_helper"

function* submitBrokerAgent({ payload: { agent, history } }) {
  try {
      toast.loading("Please wait...")
      const response = yield call(postBrokerAgentData, {
            agentCode: '',
            primaryEmail: agent.primaryEmail,
            firstName: agent.firstName,
            lastName: agent.lastName,
            createdBy:parseInt(localStorage.getItem('userId')),
            fullAddress: agent.address,
            longitude:parseInt(agent.longitude),
            latitude:parseInt(agent.latitude),
            phone:agent.phone,
            phoneExt:agent.phoneExt,
            email:agent.primaryEmail,
            mobile:agent.mobile,
            fax:agent.fax,
            isActive:true
      })
        yield put(addBrokerAgentSuccess(response))
        history.push("/agent")
    }
catch (error) {

  yield put(addBrokerAgentError('Some Error'))
}
}
function* fetchAgentDetail({ agentId }) {
  try {
    const response = yield call(getBrokerAgentData, agentId)
    yield put(getBrokerAgentDetailSuccess(response))
  } catch (error) {
    yield put(getBrokerAgentDetailFail(error))
  }
}
function* agentSaga() {
  yield takeEvery(ADD_BROKER_AGENT, submitBrokerAgent)
  yield takeEvery(GET_BROKER_AGENT_DETAIL, fetchAgentDetail)
}

export default agentSaga
